import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { groupStore, type ObservableGroup } from "../stores/groups.store";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { ClientResponseError } from "pocketbase";
import { Page } from "../components/component";

const formSchema = z.object({
  section: z.string({
    required_error: "O nome é obrigatório",
  }),
});

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-create-section")
export class ModalNewSection extends Page {
  group?: ObservableGroup;

  @consume({ context: navigatorContext })
  private navigator!: NavigatorController;

  #form = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      if (!this.group) {
        return;
      }

      try {
        await groupStore.createSection(this.group, {
          name: values.section,
          group: this.group.id,
          position: this.group.sections.length,
        });

        this.navigator.close();
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.response.data.name.code === "validation_not_unique") {
            this.#form.setError("section", "Já existe uma secção com esse nome");
          } else {
            this.#form.setError("section", "Ocorreu um erro ao criar a secção");
          }
        } else {
          this.#form.setError("section", "Ocorreu um erro ao criar a secção");
        }
        console.error(error);
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea">
            <ion-buttons slot="start">
              <ion-button @click=${() => this.navigator.close()} class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                fechar</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">Criar Seção</ion-title>
          </ion-toolbar>
        </ion-header>
        <form
          id="form-section-create"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none">
            <div>
              <span class="font-semibold font-display">Nome</span>
              <ion-item class="mt-1">
                <ion-input autocapitalize="on" type="text" placeholder="Secção" enterkeyhint="send" ${register("section")}> </ion-input>
              </ion-item>
              ${renderError("section")}
            </div>
          </ion-list>

          <ion-button
            form="form-section-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Adicionar Secção</ion-button
          >
        </form>
      </div>
    `;
  }
}
