import { alertController, popoverController } from "@ionic/core";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { consume } from "@lit/context";
import { Task, TaskStatus } from "@lit/task";
import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { Page } from "../components/component";
import { authContext, type AuthContext } from "../context/auth.context";
import { routerContext, type RouterContext } from "../context/router.context";
import type { ModalNav } from "../modals/modal-nav";
import type { ModalSheetNav } from "../modals/modal-sheet-nav";
import { sleep } from "../pocketbase";
import { FilterStore } from "../stores/filter.store";
import { ObservableGroup, ObservableSection, groupStore } from "../stores/groups.store";
import { PaginationStore } from "../stores/pagination.store";
import { patientsStore, type ObservablePatient } from "../stores/patients.store";

@customElement("page-groups-group")
export class PageGroupsGroup extends Page {
  @state() private _id: string = "";
  @state() private _query: string = "";

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;

  #modalNewPatientRef: Ref<ModalNav> = createRef();
  #modalEditGroupRef: Ref<ModalNav> = createRef();
  #modalFilterGroupRef: Ref<ModalNav> = createRef();

  #modalNewSectionRef: Ref<ModalSheetNav> = createRef();
  #modalMovePatientRef: Ref<ModalSheetNav> = createRef();
  #modalEditSectionRef: Ref<ModalSheetNav> = createRef();

  #ionRefresher: Ref<IonRefresher> = createRef();

  @state() patientsFiltered: ObservablePatient[] = [];
  filterStore = new FilterStore();
  filterPagination = new PaginationStore(1, 20, 0);

  connectedCallback() {
    super.connectedCallback();
    this._id = this.router.getParam("groupId")!;
    this.#group.run([true]);
  }

  #group = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return await groupStore.loadGroup(this._id, { cache });
    },
  });

  #filterPatients = new Task(this, {
    task: async () => {
      if (!this.filterStore.hasFilters && this._query.length === 0) {
        return null;
      }

      const group = this.#group.value;
      if (!group) {
        return null;
      }

      // reset pagination
      this.filterPagination.reset();

      const categories = this.filterStore.filtersByKey("categories");
      const type = this.filterStore.filtersByKey("type")?.[0];
      const patients = await patientsStore.loadPatients(group, this.filterPagination, {
        query: this._query,
        categories: categories,
        type: type,
      });

      return patients;
    },
    onComplete: (patients) => {
      if (patients) {
        this.patientsFiltered = patients.items;
        this.filterPagination.setTotal(patients.totalItems);
      }
    },
    args: () => [this._query, this.filterStore.filters.length],
  });

  #archivePatient = new Task(this, {
    task: async ([patient]: [ObservablePatient]) => {
      await patientsStore.archivePatient(patient);
    },
    onError: (err: unknown) => {
      console.error(err);
      alertController
        .create({
          header: "Erro",
          message: "Ocorreu um erro ao arquivar o utente",
          buttons: ["OK"],
        })
        .then((alert) => alert.present());
    },
  });

  #activatePatient = new Task(this, {
    task: async ([patient]: [ObservablePatient]) => {
      await patientsStore.activatePatient(patient);
    },
    onError: (err: unknown) => {
      console.error(err);
      alertController
        .create({
          header: "Erro",
          message: "Ocorreu um erro ao ativar o utente",
          buttons: ["OK"],
        })
        .then((alert) => alert.present());
    },
  });

  async presentAlertRemoveSection(group: ObservableGroup, sectionId: string) {
    await popoverController.dismiss();

    const alert = await alertController.create({
      header: "Remover Secção",
      message: `Tem a certeza que pretende remover a secção?`,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Remover",
          role: "destructive",
          handler: async () => {
            await groupStore.deleteSection(group, sectionId);
          },
        },
      ],
    });

    await alert.present();
  }

  async presentModalEditSection(section: ObservableSection) {
    await popoverController.dismiss();
    this.#modalEditSectionRef.value?.open({
      section: section,
    });
  }

  async presentModalEditGroup() {
    this.#modalEditGroupRef.value?.open({
      group: groupStore.find(this._id),
    });
  }

  async presentModalNewPatient(section: ObservableSection) {
    const popover = await popoverController.getTop();
    if (popover) {
      await popover.dismiss();
    }

    this.#modalNewPatientRef.value?.open({
      group: groupStore.find(this._id),
      section: section,
    });
  }

  async presentSectionBottomSheet() {
    this.#modalNewSectionRef.value?.open({
      group: groupStore.find(this._id),
    });
  }

  async presentMovePatientModal(patient: ObservablePatient, _sectionId: string, group: ObservableGroup, e: Event) {
    e.stopPropagation();
    e.preventDefault();

    const itemSlidingParent = (e.target as HTMLElement).closest("ion-item-sliding");
    if (itemSlidingParent) {
      await (itemSlidingParent as HTMLIonItemSlidingElement).closeOpened();
    }

    this.#modalMovePatientRef.value?.open({
      group: group,
      patient: patient,
    });
  }

  async presentRemovePatientModal(patient: ObservablePatient, e: Event) {
    e.stopPropagation();
    e.preventDefault();

    const itemSlidingParent = (e.target as HTMLElement).closest("ion-item-sliding");
    if (itemSlidingParent) {
      await (itemSlidingParent as HTMLIonItemSlidingElement).closeOpened();
    }

    const alert = await alertController.create({
      header: "Remover Utente",
      message: `Tem a certeza que pretende remover o utente?`,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Remover",
          role: "destructive",
          handler: async () => {
            await patientsStore.deletePatient(patient);
          },
        },
      ],
    });

    await alert.present();
  }

  async toggleArchivePatient(e: Event, patient: ObservablePatient) {
    const itemSlidingParent = (e.target as HTMLElement).closest("ion-item-sliding");
    if (itemSlidingParent) {
      await (itemSlidingParent as HTMLIonItemSlidingElement).closeOpened();
    }

    if (patient.isArchived) {
      this.#activatePatient.run([patient]);
    } else {
      this.#archivePatient.run([patient]);
    }
  }

  hasFilters() {
    return this.filterStore.hasFilters || this._query.length > 0;
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-router-link href=${this.router.backUrl} router-direction="back" slot="start">
            <ion-button
              style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
              fill="clear"
              class="font-semibold">
              <span class="flex items-center -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              voltar
            </ion-button>
          </ion-router-link>

          <ion-title>${this.#group.value?.name || ""}</ion-title>

          ${when(
            this.#group.value?.canEdit(this.auth.user),
            () => html`
              <ion-button
                style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                color="secondary"
                shape="round"
                size="xsmall"
                slot="end"
                @click=${this.presentModalEditGroup}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class="mr-1">
                  <path
                    d="M8.00033 5.33341L2.66699 10.6667V13.3334L5.33366 13.3334L10.667 8.00007M8.00033 5.33341L9.91274 3.42098L9.91389 3.41984C10.1772 3.15659 10.309 3.02473 10.461 2.97534C10.5949 2.93183 10.7392 2.93183 10.873 2.97534C11.0249 3.02469 11.1567 3.1564 11.4195 3.41929L12.5794 4.57915C12.8434 4.84316 12.9755 4.97523 13.0249 5.12745C13.0684 5.26134 13.0684 5.40557 13.0249 5.53947C12.9755 5.69158 12.8436 5.82344 12.58 6.08708L12.5794 6.08764L10.667 8.00007M8.00033 5.33341L10.667 8.00007"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                Editar
              </ion-button>
            `,
          )}
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        ${when(
          !this.hasFilters(),
          () => html`
            <!-- Refresher -->
            <ion-refresher
              slot="fixed"
              @ionRefresh=${async () => {
                await this.#group.run([false]);
                await this.#ionRefresher.value?.complete();
              }}
              ${ref(this.#ionRefresher)}>
              <ion-refresher-content class="mt-4" pulling-text="Puxe para atualizar"></ion-refresher-content>
            </ion-refresher>
          `,
        )}

        <!-- HEADER -->
        <ion-header collapse="condense">
          <ion-toolbar>
            <div class="w-full flex justify-between items-start gap-x-2">
              <h3 class="w-full">
                ${this.#group.render({
                  pending: () => html`
                    <ion-skeleton-text animated="true" style="width: 50%; height: 1em;"></ion-skeleton-text>
                  `,
                  complete: (group) => group.name,
                })}
              </h3>
              <div class="flex items-center space-x-1 mt-2">
                <span class="font-semibold font-display mt-px">
                  ${when(
                    this.hasFilters(),
                    () => this.filterPagination.total,
                    () =>
                      this.#group.render({
                        pending: () => html`
                          <ion-skeleton-text animated="true" style="width: 51px; height: 1em;"></ion-skeleton-text>
                        `,
                        complete: (group) => group.totalPatients,
                      }),
                  )}
                </span>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M17.2166 19.3323C15.9349 17.9008 14.0727 17 12 17C9.92734 17 8.06492 17.9008 6.7832 19.3323M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </ion-toolbar>
        </ion-header>
        <!-- HEADER -->

        <!-- SEARCH BAR -->
        <ion-item class="mt-2.5">
          <span aria-hidden="true" slot="start" class="flex items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <ion-input
            type="search"
            debounce="150"
            @ionInput=${(e: Event) => {
              const query = (e.target as HTMLInputElement).value;
              this._query = query;
            }}
            clear-on-edit
            placeholder="Pesquisar utentes..."></ion-input>
          <ion-button
            slot="end"
            shape="round"
            size="chip"
            disabled=${this.#group.status !== TaskStatus.COMPLETE}
            @click=${() =>
              this.#modalFilterGroupRef.value?.open({
                filterStore: this.filterStore,
                group: this.#group.value,
              })}>
            <span class="flex items-center mr-0.5">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                  d="M11.6663 3.26672C11.6663 2.94002 11.6661 2.77655 11.6025 2.65177C11.5466 2.54201 11.4577 2.45283 11.348 2.39691C11.2232 2.33333 11.0595 2.33333 10.7328 2.33333H3.26611C2.93942 2.33333 2.77623 2.33333 2.65145 2.39691C2.54169 2.45283 2.45251 2.54201 2.39659 2.65177C2.33301 2.77655 2.33301 2.94002 2.33301 3.26672V3.69679C2.33301 3.83946 2.33301 3.91086 2.34913 3.97799C2.36342 4.03751 2.38704 4.09437 2.41903 4.14656C2.45509 4.20541 2.50562 4.25594 2.60645 4.35677L5.55971 7.31003C5.66059 7.41091 5.71076 7.46108 5.74684 7.51995C5.77882 7.57214 5.80277 7.62919 5.81706 7.68872C5.83301 7.75517 5.83301 7.82571 5.83301 7.9655V10.7397C5.83301 11.2398 5.83301 11.49 5.93832 11.6405C6.03029 11.772 6.17216 11.8597 6.33089 11.8832C6.51267 11.91 6.73642 11.7983 7.18368 11.5747L7.65034 11.3414C7.83763 11.2477 7.93104 11.2007 7.99946 11.1309C8.05996 11.0691 8.10625 10.9949 8.13444 10.9132C8.16632 10.8208 8.16634 10.7158 8.16634 10.5064V7.96984C8.16634 7.82717 8.16634 7.75585 8.18246 7.68872C8.19675 7.62919 8.22038 7.57214 8.25236 7.51995C8.28819 7.46148 8.33823 7.41144 8.43778 7.31189L8.43978 7.31003L11.393 4.35677C11.4939 4.25588 11.5441 4.20543 11.5802 4.14656C11.6122 4.09437 11.6361 4.03751 11.6504 3.97799C11.6663 3.91154 11.6663 3.84092 11.6663 3.70113V3.26672Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Filtrar
          </ion-button>
        </ion-item>
        <!-- SEARCH BAR -->

        <!-- FILTERS -->
        ${when(
          this.hasFilters(),
          () => html`
            <div class="flex items-center gap-x-1.5 gap-y-2 flex-wrap mt-2">
              ${repeat(
                this.filterStore.filters,
                (filter) => filter.id,
                (filter) =>
                  when(
                    filter.id !== "all",
                    () => html`
                      <ion-chip
                        color="primary"
                        class="px-2.5 py-1.5 text-sm font-medium"
                        @click=${() => this.filterStore.remove(filter)}>
                        ${filter.name}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          class="ml-1 mb-px">
                          <rect width="12" height="12" rx="6" fill="white" />
                          <path
                            d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </ion-chip>
                    `,
                  ),
              )}
            </div>
          `,
        )}
        <!-- FILTERS -->

        <!-- CONTENT -->
        ${when(
          this.hasFilters(),
          () =>
            this.#filterPatients.render({
              pending: () => {
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              },
              error: (err) => {
                console.log(err);
                return html`<span class="text-danger">Erro ao procurar pacientes</span>`;
              },
              complete: () =>
                when(
                  this.patientsFiltered.length === 0,
                  () => html`
                    <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                      <div
                        class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                        <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                          Não foram encontrados resultados
                        </span>
                      </div>
                    </ion-item>
                  `,
                  () => html`
                    <ion-list class="space-y-2 no-border mt-4">
                      ${this.patientsFiltered.map((patient) => {
                        return html`
                          <ion-item
                            button
                            .detail=${false}
                            @click=${() => {
                              if (patient.isArchived) {
                                this.router.push(`groups/${this._id}/patient-archived/${patient.id}`, "forward");
                              } else {
                                this.router.push(`groups/${this._id}/patient/${patient.id}`, "forward");
                              }
                            }}
                            class="flex space-x-2 items-center no-px no-border">
                            <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                            <div class="col w-full">
                              <p class="font-semibold">${patient.name}</p>
                              <div class="flex items-center space-x-1.5">
                                ${patient.categories
                                  .slice(0, 3)
                                  .map(
                                    (category) => html`
                                      <ion-chip
                                        class="flex-shrink-0 px-2 w-fit"
                                        style="--background: ${category.color};"
                                        >${category.category}</ion-chip
                                      >
                                    `,
                                  )}
                              </div>
                            </div>
                          </ion-item>
                        `;
                      })}
                    </ion-list>
                    ${when(
                      this.filterPagination.hasNext,
                      () => html`
                        <ion-infinite-scroll
                          threshold="0px"
                          @ionInfinite=${async (e: any) => {
                            const group = this.#group.value;
                            if (!group) {
                              return;
                            }
                            await sleep(500);
                            this.filterPagination.nextPage();

                            const patients = await patientsStore.loadPatients(group, this.filterPagination, {
                              query: this._query,
                              categories: this.filterStore.filtersByKey("categories"),
                              type: this.filterStore.filtersByKey("type")?.[0],
                            });
                            this.patientsFiltered = this.patientsFiltered.concat(patients.items);
                            e.target.complete();
                          }}>
                          <ion-infinite-scroll-content
                            loading-text="A carregar mais pacientes"></ion-infinite-scroll-content>
                        </ion-infinite-scroll>
                      `,
                    )}
                  `,
                ),
            }),
          () =>
            this.#group.render({
              pending: () => {
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              },
              error: () => {
                return html`<span class="text-danger">Erro ao carregar o grupo</span>`;
              },
              complete: (group) => {
                return html`
                  <ion-accordion-group class="mt-4 space-y-2" multiple>
                    <!-- SECTIONS -->
                    ${group?.sortedSections?.map(
                      (section, index) => html`
                        <ion-accordion
                          value="${section.id}"
                          toggle-icon-slot="start"
                          toggle-icon="/assets/icons/chevron-down.svg">
                          <ion-item lines="none" class="no-active-bg no-border no-p-start no-inner-p" slot="header">
                            <div class="flex justify-between items-center w-full">
                              <div class="flex items-center space-x-1 w-full">
                                <h6>${section.name}</h6>
                                <ion-chip style="--background: #EAEAEA; --color: #888888"
                                  >${section.totalActivePatients || 0} utentes</ion-chip
                                >
                              </div>
                              ${group.canEdit(this.auth.user)
                                ? html`
                                    <ion-button
                                      id="${index}-section-popover"
                                      fill="clear"
                                      size="chip"
                                      @click="${(e: Event) => {
                                        e.stopPropagation();
                                      }}">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none">
                                        <path
                                          d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                      </svg>
                                    </ion-button>
                                    <ion-popover
                                      arrow
                                      alignment="center"
                                      reference="trigger"
                                      side="bottom"
                                      style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                                      trigger="${index}-section-popover">
                                      <div class="p-0">
                                        <ion-list>
                                          <ion-item
                                            button
                                            .detail=${false}
                                            class="no-p popover text-sm"
                                            @click=${() => this.presentModalNewPatient(section)}>
                                            <ion-label class="font-semibold pl-3">Adicionar Utente</ion-label>
                                          </ion-item>
                                          <ion-item
                                            button
                                            class="no-p no-detail text-sm"
                                            @click=${() => this.presentModalEditSection(section)}>
                                            <ion-label class="font-semibold pl-3">Editar</ion-label>
                                          </ion-item>
                                          <ion-item
                                            button
                                            @click=${() => this.presentAlertRemoveSection(group, section.id)}
                                            class="no-p no-detail text-sm">
                                            <ion-label class="font-semibold pl-3">Remover Secção</ion-label>
                                          </ion-item>
                                        </ion-list>
                                      </div>
                                    </ion-popover>
                                  `
                                : nothing}
                            </div>
                          </ion-item>
                          <div slot="content" class="mt-1.5">
                            ${when(
                              Array.isArray(section.patients) && section.patients.length > 0,
                              () => html`
                                <!-- THIS IS THE BOTTLENECK WE NEED TO CONDITIONALLY RENDER THE PATIENTS ON OPEN -->
                                <ion-list class="space-y-2 no-border">
                                  ${repeat(
                                    section.patients,
                                    (patient) => patient.id,
                                    (patient) =>
                                      when(
                                        !patient.isArchived,
                                        () => html`
                                          <ion-item-sliding>
                                            <ion-item
                                              button
                                              .detail=${false}
                                              @click=${() =>
                                                this.router.goto(`groups/${this._id}/patient/${patient.id}`)}
                                              class="flex space-x-2 items-center no-px no-border">
                                              <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                                              <div class="col w-full">
                                                <p class="font-semibold">${patient.name}</p>
                                                <div class="flex items-center space-x-1.5">
                                                  ${patient.categories
                                                    .slice(0, 3)
                                                    .map(
                                                      (category) => html`
                                                        <ion-chip
                                                          class="flex-shrink-0 px-2 w-fit"
                                                          style="--background: ${category.color};"
                                                          >${category.category}</ion-chip
                                                        >
                                                      `,
                                                    )}
                                                </div>
                                              </div>
                                            </ion-item>
                                            <ion-item-options>
                                              <!-- TODO CHECK CLOSE ANIMATION -->
                                              <ion-item-option>
                                                <ion-button
                                                  @click="${(e: Event) =>
                                                    this.presentMovePatientModal(patient, section.id, group, e)}"
                                                  size="chip"
                                                  color="secondary"
                                                  shape="round"
                                                  class="open-move-patient-modal"
                                                  style="--padding-start: 8px; --padding-end: 8px;"
                                                  >Mover</ion-button
                                                >
                                              </ion-item-option>
                                              <ion-item-option>
                                                <ion-button
                                                  @click="${(e: Event) => this.toggleArchivePatient(e, patient)}"
                                                  disabled=${this.#archivePatient.status === TaskStatus.PENDING}
                                                  size="chip"
                                                  shape="round"
                                                  style="--padding-start: 8px; --padding-end: 8px;"
                                                  >Arquivar</ion-button
                                                >
                                              </ion-item-option>
                                            </ion-item-options>
                                          </ion-item-sliding>
                                        `,
                                      ),
                                  )}
                                </ion-list>
                              `,
                            )}
                            ${when(
                              group.canEdit(this.auth.user),
                              () => html`
                                <ion-button
                                  color="secondary"
                                  shape="round"
                                  expand="block"
                                  size="small"
                                  class="font-bold mt-1.5"
                                  @click=${() => this.presentModalNewPatient(section)}
                                  >Adicionar Utente
                                  <span class="ml-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none">
                                      <path
                                        d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </svg> </span
                                ></ion-button>
                              `,
                            )}
                          </div>
                        </ion-accordion>
                      `,
                    )}

                    <!-- ARCHIVED ACCORDION -->
                    <ion-accordion
                      value="archived"
                      toggle-icon-slot="start"
                      toggle-icon="/assets/icons/chevron-down.svg">
                      <ion-item class="no-active-bg no-p-start no-inner-p no-border" slot="header">
                        <div class="flex items-center space-x-1 w-full">
                          <h6>Arquivados</h6>
                          <ion-chip style="--background: #EAEAEA; --color: #888888"
                            >${group.totalArchivedPatients} utentes</ion-chip
                          >
                        </div>
                      </ion-item>
                      <div
                        slot="content"
                        class=${classMap({
                          "mt-1.5": group.archivedPatients.length > 0,
                        })}>
                        ${group.archivedPatients.length > 0
                          ? html`
                              <ion-list class="space-y-2 no-border">
                                ${group.archivedPatients.map(
                                  (patient) => html`
                                    <ion-item-sliding>
                                      <ion-item
                                        button
                                        .detail=${false}
                                        @click=${() =>
                                          this.router.push(
                                            `groups/${this._id}/patient-archived/${patient.id}`,
                                            "forward",
                                          )}
                                        class="flex space-x-2 items-center no-px no-border">
                                        <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                                        <div class="col w-full">
                                          <p class="font-semibold">${patient.name}</p>
                                          <ion-chip
                                            class="flex-shrink-0 px-2 w-fit"
                                            style="--background: ${patient.categories?.[0]?.color};"
                                            >${patient.categories?.[0]?.category}</ion-chip
                                          >
                                        </div>
                                      </ion-item>
                                      <ion-item-options>
                                        <!-- TODO CHECK CLOSE ANIMATION -->
                                        <ion-item-option>
                                          <ion-button
                                            @click="${(e: Event) => this.presentRemovePatientModal(patient, e)}"
                                            size="chip"
                                            color="danger"
                                            shape="round"
                                            class="open-move-patient-modal"
                                            style="--padding-start: 8px; --padding-end: 8px;"
                                            >Remover</ion-button
                                          >
                                        </ion-item-option>
                                        <ion-item-option>
                                          <ion-button
                                            @click="${(e: Event) => this.toggleArchivePatient(e, patient)}"
                                            disabled=${this.#activatePatient.status === TaskStatus.PENDING}
                                            size="chip"
                                            shape="round"
                                            style="--padding-start: 8px; --padding-end: 8px;"
                                            >Reativar</ion-button
                                          >
                                        </ion-item-option>
                                      </ion-item-options>
                                    </ion-item-sliding>
                                  `,
                                )}
                              </ion-list>
                            `
                          : nothing}
                      </div>
                    </ion-accordion>

                    <!-- SECTIONS -->
                  </ion-accordion-group>

                  ${when(
                    group.canEdit(this.auth.user),
                    () => html`
                      <div class="relative w-full flex mt-3">
                        <ion-button
                          @click=${this.presentSectionBottomSheet}
                          size="chip"
                          shape="round"
                          class="mx-auto z-10">
                          Criar Secção
                          <span class="flex items-center ml-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none">
                              <path
                                d="M4 8H8M8 8H12M8 8V12M8 8V4"
                                stroke="currentColor"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                          </span>
                        </ion-button>
                        <div class="h-px w-full absolute inset-y-center bg-[#e5e5e5]"></div>
                      </div>
                    `,
                  )}
                `;
              },
            }),
        )}
        <!-- CONTENT -->
      </ion-content>

      <modal-nav root="modal-create-patient" ${ref(this.#modalNewPatientRef)}></modal-nav>
      <modal-nav root="modal-edit-group" ${ref(this.#modalEditGroupRef)}></modal-nav>
      <modal-nav root="modal-filter-groups" ${ref(this.#modalFilterGroupRef)}></modal-nav>

      <modal-sheet-nav auto-height root="modal-move-patient" ${ref(this.#modalMovePatientRef)}></modal-sheet-nav>
      <modal-sheet-nav auto-height root="modal-create-section" ${ref(this.#modalNewSectionRef)}></modal-sheet-nav>
      <modal-sheet-nav auto-height root="modal-edit-section" ${ref(this.#modalEditSectionRef)}></modal-sheet-nav>
    `;
  }
}
