import { customElement } from "lit/decorators.js";
import { LightElement } from "../components/component";
import { html } from "lit";
import type { IonDatetime } from "@ionic/core/components/ion-datetime";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { add, formatISO } from "date-fns";

@customElement("modal-date-picker")
export class ModalDatePicker extends LightElement {
  public date!: string;
  public setDate!: (date: string) => void;

  public ionDateTime: Ref<IonDatetime> = createRef();

  connectedCallback() {
    super.connectedCallback();
    if (!this.setDate) {
      throw new Error("<modal-date-picker>: setDate is required");
    } else if (!this.date) {
      this.date = formatISO(new Date());
    }
  }

  async confirm() {
    await this.ionDateTime.value?.confirm(true);
    const date = this.ionDateTime.value?.value!;
    if (Array.isArray(date)) return;
    else {
      this.setDate?.(date);
    }
  }

  async cancel() {
    this.ionDateTime.value?.cancel(true);
  }

  getMaxDate() {
    const result = add(new Date(), { years: 2 });
    return formatISO(result);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click=${this.cancel}>Cancelar</ion-button>
          </ion-buttons>
          <ion-title>Dia</ion-title>
          <ion-buttons slot="end">
            <ion-button @click=${this.confirm}>Confirmar</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="inner-content">
        <div class="flex justify-center w-full">
          <ion-datetime
            min="1900-01-01T10:21:52.978-08:00"
            max=${this.getMaxDate()}
            value=${this.date}
            class="w-full"
            locale="pt-PT"
            prefer-wheel="true"
            presentation="date"
            ${ref(this.ionDateTime)}></ion-datetime>
        </div>
      </div>
    `;
  }
}
