import { observable, makeObservable, type IObservableArray, action } from "mobx";
import type { Category, Difficulty, Equipment, Exercise, Muscle } from "../repository/library/exercises";
import { pb } from "../pocketbase";

export class ObservableExercise {
  id: string;
  @observable name: string;
  @observable content: Array<string>;
  // @observable thumbnail: string;
  @observable categories: IObservableArray<Category>;
  @observable muscles: IObservableArray<Muscle>;
  @observable difficulties: IObservableArray<Difficulty>;
  @observable equipments: IObservableArray<Equipment>;
  private _record: Exercise;

  constructor(exercise: Exercise) {
    this.id = exercise.id;
    this.name = exercise.name;
    this.content = exercise.content;
    // this.thumbnail = exercise.thumbnail;
    this.categories = observable.array(exercise.categories);
    this.muscles = observable.array(exercise.muscles);
    this.difficulties = observable.array(exercise.difficulties);
    this.equipments = observable.array(exercise.equipments);
    this._record = exercise;
    makeObservable(this);
  }

  @action merge(exercise: Exercise) {
    this.name = exercise.name;
    this.content = exercise.content;
    // this.thumbnail = exercise.thumbnail;
    this.categories.replace(exercise.categories);
    this.muscles.replace(exercise.muscles);
    this.difficulties.replace(exercise.difficulties);
    this.equipments.replace(exercise.equipments);
    this._record = exercise;
    return this;
  }

  thumbnail( object?: {width: number, height: number} ) {
    if(object) {
      return pb.files.getUrl(this._record, this._record.thumbnail, { thumb: `${object.width}x${object.height}` });
    } else {
      return pb.files.getUrl(this._record, this._record.thumbnail);
    }
  }
}

class ExercisesStore {
  @observable exercises = observable.map<string, ObservableExercise>();

  constructor() {
    makeObservable(this);
  }

  @action save(exercise: Exercise) {
    const observable = new ObservableExercise(exercise);
    this.exercises.set(exercise.id, observable);
    return observable;
  }

  @action delete(exercise: ObservableExercise) {
    return this.exercises.delete(exercise.id);
  }

  find(id: string) {
    return this.exercises.get(id);
  }

  exists(exercise: ObservableExercise) {
    return this.exercises.has(exercise.id);
  }

  @action clearStore() {
    this.exercises.clear();
  }

  @action subscribe(exercise: Exercise) {
    const observable = this.find(exercise.id);
    if (observable) {
      return observable.merge(exercise);
    } else {
      return this.save(exercise);
    }
  }
}

export const exercisesStore = new ExercisesStore();
