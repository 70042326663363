import { html } from "lit";
import { customElement, property } from "lit/decorators.js";
import type { IonInput } from "@ionic/core/components/ion-input";
import { Maskito } from "@maskito/core";
import { z } from "zod";
import { zodValidator, FinalFormController } from "../controllers/final-form.controller";
import { when } from "lit/directives/when.js";
import { ClientResponseError } from "pocketbase";
import { consume } from "@lit/context";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { authContext, type AuthContext } from "../context/auth.context";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";

const fileSchema = z.instanceof(File).refine(
  (file) => {
    // Check file size (e.g., less than 5MB)
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
    if (file.size > MAX_FILE_SIZE) {
      return false;
    }

    // Check file type (e.g., only allow images)
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/webp", "image/heic", "image/heif"];
    if (!allowedFileTypes.includes(file.type)) {
      return false;
    }

    return true;
  },
  {
    message: "O avatar deve ser uma imagem com menos de 5MB",
  },
);

const usernameSchema = z.preprocess(
  (input) => {
    if (typeof input === "string" && input.startsWith("@")) {
      return input.slice(1);
    }
    return input;
  },
  z
    .string({
      required_error: "O nome de utilizador é obrigatório",
    })
    .min(3, "Nome de utilizador deve ter pelo menos 3 caracteres")
    .max(32, "Nome de utilizador deve ter no máximo 32 caracteres")
    .regex(/^[A-Za-z0-9._-]+$/, "Nome de utilizador deve conter apenas letras, números e os caracteres . _ -"),
);

const formSchema = z.object({
  avatar: fileSchema.optional(),
  username: usernameSchema,
  name: z
    .string({
      required_error: "O nome é obrigatório",
    })
    .min(3, "Nome deve ter pelo menos 3 caracteres")
    .max(32, "Nome deve ter no máximo 32 caracteres"),
  specialty: z
    .string({
      required_error: "A especialidade é obrigatória",
    })
    .min(3, "Especialidade deve ter pelo menos 3 caracteres")
    .max(32, "Especialidade deve ter no máximo 32 caracteres"),
});

type FormValues = z.infer<typeof formSchema>;

@customElement("page-create-account")
export class PageCreateAccount extends Page {
  @property({ type: Boolean }) hasAvatar = false;

  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;

  #modalCropPhotoRef: Ref<ModalNav> = createRef();

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        if (values.avatar) {
          formData.append("avatar", values.avatar);
        }

        // Remove the '@' from the beginning of the username if it exists
        const username = values.username.startsWith("@") ? values.username.slice(1) : values.username;

        formData.append("username", username);
        formData.append("name", values.name);
        formData.append("role", values.specialty);
        formData.append("isProfileComplete", "true");

        await this.auth.createProfile(formData);

        // Redirect to home page
        await this.router.push("/home", "root");
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.status === 400) {
            if (error.response.data.username) {
              this.#controller.setError("username", "Nome de utilizador inválido, ou já existe");
            } else if (error.response.data.name) {
              this.#controller.setError("name", "Nome inválido");
            } else if (error.response.data.role) {
              this.#controller.setError("specialty", "Especialidade inválida");
            } else if (error.response.data.avatar) {
              this.#controller.setError("avatar", "Avatar inválido");
            }
          }
        }
      }
    },
  });

  async firstUpdated(_changedProperties: Map<string, any>) {
    super.firstUpdated(_changedProperties);

    const usernameInput = this.renderRoot.querySelector("#input-username") as IonInput;
    const nativeEl = await usernameInput?.getInputElement();

    if (nativeEl) {
      new Maskito(nativeEl, {
        mask: ({ value }) => {
          const chars = Array.from(value).length;
          return ["@", ...new Array(chars || 1).fill(/[A-Za-z0-9._-]/)];
        },
      });
    }

    const fileInput = this.renderRoot.querySelector("#avatar-file") as HTMLInputElement;
    fileInput.addEventListener("change", (event) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
        const file = input.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target?.result as string;
          this.#modalCropPhotoRef.value?.open({ image: imageUrl, updateImage: this.updateCroppedImage.bind(this) });
        };
        reader.readAsDataURL(file);
      }
    });
  }

  updateCroppedImage(canvas: HTMLCanvasElement) {
    const image = canvas.toDataURL("image/jpeg");
    const button = document.querySelector("#avatar-button") as HTMLButtonElement;
    button.style.backgroundImage = `url(${image})`;
    button.style.backgroundSize = "cover";
    this.hasAvatar = true;
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const fileName = `cropped-image-${Date.now()}.jpeg`;
          const file = new File([blob], fileName, { type: "image/jpeg" });
          this.#controller.form.change("avatar", file);
        } else {
          console.error("Canvas to Blob conversion failed");
        }
      },
      "image/jpeg",
      0.8,
    );
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div>
                <ion-router-link href="/register" router-direction="back">
                  <ion-button
                    style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                    fill="clear"
                    class="font-semibold">
                    <span class="mt-1 -ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M14 16L10 12L14 8"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </span>
                    voltar
                  </ion-button>
                </ion-router-link>
              </div>
            </ion-buttons>
            <ion-title class="mt-0.5 font-display text-lg">Nova Conta</ion-title>
          </ion-toolbar>
        </ion-header>
        <div class="mt-4">
          <form
            id="form-create-account"
            class="flex col"
            @submit=${(e: Event) => {
              e.preventDefault();
              form.submit();
            }}>
            <div class="mx-auto">
              <button
                id="avatar-button"
                type="button"
                class="w-36 h-36 rounded-full bg-accent-8 border border-solid border-accent-7"
                aria-label="avatar"
                @click=${() => {
                  const avatarFile = this.renderRoot.querySelector("#avatar-file") as HTMLInputElement;
                  avatarFile.click();
                }}>
                ${when(
                  !this.hasAvatar,
                  () => html`
                    <div slot="icon-only">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path
                          d="M8.5 12H12.5M12.5 12H16.5M12.5 12V16M12.5 12V8M12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21Z"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </div>
                  `,
                )}
              </button>
              ${renderError("avatar")}
              <input id="avatar-file" class="hidden" accept="image/*" type="file" ${register("avatar")} />
            </div>
            <ion-list lines="none" class="space-y-4 mt-4 flex flex-col">
              <div>
                <span class="text-sm font-semibold font-display">Nome Utilizador</span>
                <ion-item class="mt-1">
                  <ion-input id="input-username" type="text" placeholder="@andrade.joao" ${register("username")}>
                  </ion-input>
                </ion-item>
                ${renderError("username")}
              </div>
              <div>
                <span class="text-sm font-semibold font-display">Nome</span>
                <ion-item class="mt-1">
                  <ion-input type="text" placeholder="João Andrade" ${register("name")}> </ion-input>
                </ion-item>
                ${renderError("name")}
              </div>
              <div>
                <span class="text-sm font-semibold font-display">Especialidade</span>
                <ion-item class="mt-1">
                  <ion-input type="text" placeholder="Fisioterapeuta" ${register("specialty")}> </ion-input>
                </ion-item>
                ${renderError("specialty")}
              </div>
            </ion-list>
          </form>
        </div>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            form="form-create-account"
            type="submit"
            ?disabled=${formState.submitting}
            >Criar conta</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-nav root="modal-crop-photo" ${ref(this.#modalCropPhotoRef)}></modal-nav>
    `;
  }
}
