import { z } from "zod";
import { unsafeParse } from "../shared/unsafeParse";
import { record } from "../shared/record";

export const CategoryRecordSchema = record({
  group: z.string(),
  category: z.string(),
  color: z.string(),
  count: z.number().optional(),
});
export interface GroupCategoryRecord extends z.infer<typeof CategoryRecordSchema> {}

export const CategorySchema = CategoryRecordSchema.omit({ expand: true });
export interface Category extends z.infer<typeof CategorySchema> {}
export function createCategory(data: unknown) {
  return unsafeParse(CategorySchema, data);
}

export const CreateCategorySchema = z.object({
  group: z.string(),
  category: z.string(),
  color: z.string(),
});
export interface CreateCategory extends z.infer<typeof CreateCategorySchema> {}
