import { html, nothing } from "lit";
import { customElement } from "lit/decorators.js";
import { alertController, modalController, toastController } from "@ionic/core";
import { choose } from "lit/directives/choose.js";
import { notificationsStore } from "../stores/notifications.store";
import { Task } from "@lit/task";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { Page } from "../components/component";
import { ZodError } from "zod";

@customElement("modal-notifications")
export class ModalNotifications extends Page {
  @consume({ context: authContext }) auth!: AuthContext;

  #load = new Task(this, {
    task: () => {
      return notificationsStore.load(this.auth.user.id);
    },
    args: () => [],
  });

  async clearAll() {
    const alert = await alertController.create({
      header: "Limpar Avisos",
      message: "Tem certeza que deseja limpar todos os avisos? Esta ação não pode ser desfeita.",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Limpar",
          role: "destructive",
          handler: () => {
            notificationsStore.clearAll();
          },
        },
      ],
    });

    await alert.present();
  }

  async acceptGroupInvite(id: string) {
    await notificationsStore.acceptGroupNotification(id);
    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: "Convite aceite com sucesso",
      duration: 2000,
    });
    await toast.present();
  }

  async acceptLibraryInvite(id: string) {
    await notificationsStore.acceptLibraryNotification(id);
    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: "Convite aceite com sucesso",
      duration: 2000,
    });
    await toast.present();
  }

  async acceptTemplateInvite(id: string) {
    await notificationsStore.acceptTemplateExercisePlanNotification(id);
    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: "Convite aceite com sucesso",
      duration: 2000,
    });
    await toast.present();
  }

  async acceptReportInvite(id: string) {
    await notificationsStore.acceptReportNotification(id);
    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: "Convite aceite com sucesso",
      duration: 2000,
    });
    await toast.present();
  }

  isFromCurrentUser(userId: string) {
    return this.auth.user?.id === userId;
  }

  render() {
    return html`
      <ion-content fullscreen>
        <!-- HEADER -->
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  @click=${() => modalController.dismiss()}
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  voltar
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">Avisos</ion-title>
            <ion-buttons slot="end">
              <ion-button @click=${() => this.clearAll()} color="danger" class="font-semibold text-sm">
                <span class="mr-1 mt-px">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                Limpar
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        ${this.#load.render({
          pending: () => html`
            <div class="space-y-4 mt-3">
              <ion-skeleton-text class="w-full h-36" animated></ion-skeleton-text>
              <ion-skeleton-text class="w-full h-36" animated></ion-skeleton-text>
              <ion-skeleton-text class="w-full h-36" animated></ion-skeleton-text>
            </div>
          `,
          error: (err) => {
            if (err instanceof ZodError) {
              console.error(err.message, err.errors, err.stack);
            }
            return html`
              <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                <div
                  class="w-full h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-dangerLight">
                  <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                    Ocorreu um erro ao carregar as notificações...
                  </span>
                </div>
              </ion-item>
            `;
          },
          complete: () => html`
            <div class="space-y-4 mt-3">
              ${!notificationsStore.hasNotifications
                ? html`
                    <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                      <div
                        class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                        <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                          Não existem atualmente notificações
                        </span>
                      </div>
                    </ion-item>
                  `
                : nothing}

              <!-- ---------------------- GROUPS ---------------------- -->
              ${notificationsStore.hasGroupNotifications
                ? notificationsStore.groupNotifications.map((notification) => {
                    return html`
                      ${this.isFromCurrentUser(notification.userReceiver.id)
                        ? choose(notification.type, [
                            [
                              "invite",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Juntar-se a '${notification.group.name}'</p>
                                      <div
                                        class="bg-successLight rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Grupos</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi adicionado ao grupo <b>${notification.group.name}</b>, deseja aceitar o
                                      convite?
                                    </p>
                                  </div>
                                  <div class="flex space-x-1 h-9 w-full">
                                    <ion-button
                                      @click=${() => this.acceptGroupInvite(notification.id)}
                                      shape="round"
                                      expand="block"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Aceitar</ion-button
                                    >
                                    <ion-button
                                      @click=${() => notificationsStore.rejectGroupNotification(notification.id)}
                                      shape="round"
                                      expand="block"
                                      color="secondary"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Rejeitar</ion-button
                                    >
                                  </div>
                                </div>
                              `,
                            ],
                            [
                              "removal",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Removido de '${notification.group.name}'</p>
                                      <div
                                        class="bg-successLight rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Grupos</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi removido do grupo <b>${notification.group.name}</b>.
                                    </p>
                                  </div>
                                  <ion-button
                                    @click=${() => notificationsStore.markGroupNotificationAsSeen(notification.id)}
                                    color="secondary"
                                    shape="round"
                                    expand="block"
                                    class="h-9 min-h-[36px] text-sm w-full"
                                    >OK</ion-button
                                  >
                                </div>
                              `,
                            ],
                          ])
                        : nothing}
                      ${this.isFromCurrentUser(notification.userSender.id)
                        ? notification.accepted
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite aceite</p>
                                    <div
                                      class="bg-successLight rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Grupos</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para o grupo <b>${notification.group.name}</b>, foi aceite por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() => notificationsStore.markGroupNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : notification.rejected
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite rejeitado</p>
                                    <div
                                      class="bg-successLight rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Grupos</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para o grupo <b>${notification.group.name}</b>, foi rejeitado por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() => notificationsStore.markGroupNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : nothing
                        : nothing}
                    `;
                  })
                : nothing}

              <!-- ---------------------- LIBRARIES ---------------------- -->
              ${notificationsStore.hasLibraryNotifications
                ? notificationsStore.libraryNotifications.map((notification) => {
                    return html`
                      ${this.isFromCurrentUser(notification.userReceiver.id)
                        ? choose(notification.type, [
                            [
                              "invite",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Juntar-se a '${notification.library.name}'</p>
                                      <div
                                        class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Bibliotecas</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi adicionado à biblioteca <b>${notification.library.name}</b>, deseja aceitar o
                                      convite?
                                    </p>
                                  </div>
                                  <div class="flex space-x-1 h-9 w-full">
                                    <ion-button
                                      @click=${() => this.acceptLibraryInvite(notification.id)}
                                      shape="round"
                                      expand="block"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Aceitar</ion-button
                                    >
                                    <ion-button
                                      @click=${() => notificationsStore.rejectLibraryNotification(notification.id)}
                                      shape="round"
                                      expand="block"
                                      color="secondary"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Rejeitar</ion-button
                                    >
                                  </div>
                                </div>
                              `,
                            ],
                            [
                              "removal",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Removido de '${notification.library.name}'</p>
                                      <div
                                        class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Bibliotecas</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi removido da biblioteca <b>${notification.library.name}</b>.
                                    </p>
                                  </div>
                                  <ion-button
                                    @click=${() => notificationsStore.markLibraryNotificationAsSeen(notification.id)}
                                    color="secondary"
                                    shape="round"
                                    expand="block"
                                    class="h-9 min-h-[36px] text-sm w-full"
                                    >OK</ion-button
                                  >
                                </div>
                              `,
                            ],
                          ])
                        : nothing}
                      ${this.isFromCurrentUser(notification.userSender.id)
                        ? notification.accepted
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite aceite</p>
                                    <div class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Bibliotecas</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para a biblioteca <b>${notification.library.name}</b>, foi aceite por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() => notificationsStore.markLibraryNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : notification.rejected
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite rejeitado</p>
                                    <div class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Bibliotecas</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para a biblioteca <b>${notification.library.name}</b>, foi rejeitado por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() => notificationsStore.markLibraryNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : nothing
                        : nothing}
                    `;
                  })
                : nothing}

              <!-- ---------------------- TEMPLATES ---------------------- -->
              ${notificationsStore.hasTemplateExercisePlanNotifications
                ? notificationsStore.templateExercisePlanNotifications.map((notification) => {
                    return html`
                      ${this.isFromCurrentUser(notification.userReceiver.id)
                        ? choose(notification.type, [
                            [
                              "invite",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Juntar-se a '${notification.template.name}'</p>
                                      <div
                                        class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Templates</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi adicionado ao template <b>${notification.template.name}</b>, deseja aceitar o
                                      convite?
                                    </p>
                                  </div>
                                  <div class="flex space-x-1 h-9 w-full">
                                    <ion-button
                                      @click=${() => this.acceptTemplateInvite(notification.id)}
                                      shape="round"
                                      expand="block"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Aceitar</ion-button
                                    >
                                    <ion-button
                                      @click=${() =>
                                        notificationsStore.rejectTemplateExercisePlanNotification(notification.id)}
                                      shape="round"
                                      expand="block"
                                      color="secondary"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Rejeitar</ion-button
                                    >
                                  </div>
                                </div>
                              `,
                            ],
                            [
                              "removal",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Removido de '${notification.template.name}'</p>
                                      <div
                                        class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Templates</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi removido do template <b>${notification.template.name}</b>.
                                    </p>
                                  </div>
                                  <ion-button
                                    @click=${() =>
                                      notificationsStore.markTemplateExerciseNotificationAsSeen(notification.id)}
                                    color="secondary"
                                    shape="round"
                                    expand="block"
                                    class="h-9 min-h-[36px] text-sm w-full"
                                    >OK</ion-button
                                  >
                                </div>
                              `,
                            ],
                          ])
                        : nothing}
                      ${this.isFromCurrentUser(notification.userSender.id)
                        ? notification.accepted
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite aceite</p>
                                    <div class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Templates</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para o template <b>${notification.template.name}</b>, foi aceite por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() =>
                                    notificationsStore.markTemplateExerciseNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : notification.rejected
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite rejeitado</p>
                                    <div class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Templates</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para o template <b>${notification.template.name}</b>, foi rejeitado por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() =>
                                    notificationsStore.markTemplateExerciseNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : nothing
                        : nothing}
                    `;
                  })
                : nothing}

              <!-- ---------------------- REPORTS ---------------------- -->
              ${notificationsStore.hasReportNotifications
                ? notificationsStore.reportNotifications.map((notification) => {
                    return html`
                      ${this.isFromCurrentUser(notification.userReceiver.id)
                        ? choose(notification.type, [
                            [
                              "invite",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Juntar-se a '${notification.report.name}'</p>
                                      <div
                                        class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Avaliações</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi adicionado ao template <b>${notification.report.name}</b>, deseja aceitar o
                                      convite?
                                    </p>
                                  </div>
                                  <div class="flex space-x-1 h-9 w-full">
                                    <ion-button
                                      @click=${() => this.acceptReportInvite(notification.id)}
                                      shape="round"
                                      expand="block"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Aceitar</ion-button
                                    >
                                    <ion-button
                                      @click=${() => notificationsStore.rejectReportNotification(notification.id)}
                                      shape="round"
                                      expand="block"
                                      color="secondary"
                                      class="h-9 min-h-[36px] text-sm w-full"
                                      >Rejeitar</ion-button
                                    >
                                  </div>
                                </div>
                              `,
                            ],
                            [
                              "removal",
                              () => html`
                                <div
                                  class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                  <div class="space-y-0.5">
                                    <div class="flex justify-between items-center">
                                      <p class="font-semibold">Removido de '${notification.report.name}'</p>
                                      <div
                                        class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                        <span>Avaliações</span>
                                      </div>
                                    </div>
                                    <p class="text-sm text-accent-6">
                                      Foi removido do template <b>${notification.report.name}</b>.
                                    </p>
                                  </div>
                                  <ion-button
                                    @click=${() => notificationsStore.markReportNotificationAsSeen(notification.id)}
                                    color="secondary"
                                    shape="round"
                                    expand="block"
                                    class="h-9 min-h-[36px] text-sm w-full"
                                    >OK</ion-button
                                  >
                                </div>
                              `,
                            ],
                          ])
                        : nothing}
                      ${this.isFromCurrentUser(notification.userSender.id)
                        ? notification.accepted
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite aceite</p>
                                    <div class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Avaliações</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para as avaliações <b>${notification.report.name}</b>, foi aceite por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() => notificationsStore.markReportNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : notification.rejected
                          ? html`
                              <div
                                class="w-full px-3.5 pt-3 pb-4 border border-solid border-accent-7 rounded-lg space-y-3">
                                <div class="space-y-0.5">
                                  <div class="flex justify-between items-center">
                                    <p class="font-semibold">Convite rejeitado</p>
                                    <div class="bg-warning rounded-full px-1.5 py-1 text-accent-1 text-xs font-medium">
                                      <span>Avaliações</span>
                                    </div>
                                  </div>
                                  <p class="text-sm text-accent-6">
                                    O convite para as avaliações <b>${notification.report.name}</b>, foi rejeitado por
                                    <b>${notification.userReceiver.name}</b>
                                  </p>
                                </div>
                                <ion-button
                                  @click=${() => notificationsStore.markReportNotificationAsSeen(notification.id)}
                                  shape="round"
                                  expand="block"
                                  color="secondary"
                                  class="h-9 min-h-[36px] text-sm w-full"
                                  >OK</ion-button
                                >
                              </div>
                            `
                          : nothing
                        : nothing}
                    `;
                  })
                : nothing}
            </div>
          `,
        })}
      </ion-content>
    `;
  }
}
