import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { html } from "lit";
import { Page } from "../components/component";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { when } from "lit/directives/when.js";
import type { ExercisePlan } from "../repository/patient/diagnostic";

interface Content {
  file: File;
  content: string;
  ratio?: number;
}

@customElement("modal-public-exercise-plan")
export class ModalPublicExercisePlan extends Page {
  exercisePlan!: ExercisePlan;
  @state() _content: Content[] = [];
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  connectedCallback(): void {
    super.connectedCallback();

    const content = this.exercisePlan.exercise?.content || this.exercisePlan.exercisePublic?.content;

    if (content) {
      const fetchPromises = content.map((c, index) => this.fetchAndSetContent(c, index));

      Promise.all(fetchPromises).then((results) => {
        // Sort the results based on the original indices
        results.sort((a, b) => a.index - b.index);
        // Extract the content objects
        this._content = results.map(result => result.content);
        console.log(this._content);
        this.requestUpdate();
      }).catch(error => {
        console.error("Error fetching content:", error);
      });
    }
  }

  async fetchAndSetContent(url: string, index: number): Promise<{ index: number; content: any }> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split("/").pop()?.split("?")[0] || "file";
      const file = new File([blob], fileName, { type: blob.type });

      let content = { file: file, content: URL.createObjectURL(file) };

      if (!file.type.startsWith("video/")) {
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const ratio = img.naturalWidth / img.naturalHeight;
        content = { ...content, ratio: ratio } as Content;
      }

      return { index, content };
    } catch (error) {
      console.error("Error fetching content:", error);
      return { index, content: null };  // Return null content in case of error
    }
  }

  getRestAndDuration(totalSeconds: number) {
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);
    if (minutes > 0) return `${minutes} Minutos`;
    if (seconds > 0) return `${seconds} Segundos`;
  }

  render() {
    return html`
      <ion-content class="no-px" fullscreen>
        <ion-header>
          <ion-toolbar style="--padding-start:16px; --padding-end:16px; --border-color: #eaeaea">
            <ion-buttons slot="start">
              <ion-button
                @click=${() => this.navigator.goBack()}
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </ion-buttons>
            <ion-title>${this.exercisePlan.exercise?.name || this.exercisePlan.exercisePublic?.name}</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- -------------- CONTENT --------------- -->
        <ion-list class="px-4">
          ${when(
            this.exercisePlan.series,
            () => html` <ion-item class="no-p no-inner-p text-sm">
              <div class="py-3.5 flex items-center justify-between w-full gap-x-3">
                <div class="flex items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M6.66699 11.3337H13.3337M2.66699 10.4572V10.3337C2.66699 9.78137 3.11471 9.33366 3.66699 9.33366H3.69402C4.23138 9.33366 4.66713 9.76928 4.66713 10.3066C4.66713 10.5406 4.59112 10.7683 4.45078 10.9554L2.66699 13.3338L4.66699 13.3337M6.66699 8.00033H13.3337M6.66699 4.66699H13.3337M2.66699 3.33366L4.00033 2.66699V6.66699"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <ion-label class="font-semibold">Séries</ion-label>
                </div>
                <ion-note class="text-right">${this.exercisePlan.series}</ion-note>
              </div>
            </ion-item>`,
          )}
          ${when(
            this.exercisePlan.repetitions,
            () => html` <ion-item class="no-p no-inner-p text-sm">
              <div class="py-3.5 flex items-center justify-between w-full gap-x-3">
                <div class="flex items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M6.66699 10.6667H3.33366V14M9.33366 5.33333H12.667V2M3.05566 6.00227C3.42945 5.07711 4.05528 4.27536 4.86203 3.68815C5.66877 3.10094 6.6249 2.75168 7.62016 2.68034C8.61543 2.609 9.61015 2.81827 10.4924 3.28441C11.3746 3.75055 12.1078 4.45503 12.6097 5.31742M12.9454 9.99805C12.5716 10.9232 11.9457 11.725 11.139 12.3122C10.3323 12.8994 9.3771 13.2482 8.38184 13.3195C7.38657 13.3909 6.39103 13.1816 5.50879 12.7155C4.62654 12.2494 3.89287 11.545 3.39095 10.6826"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <ion-label class="font-semibold">Repetições</ion-label>
                </div>
                <ion-note class="text-right">${this.exercisePlan.repetitions}</ion-note>
              </div>
            </ion-item>`,
          )}
          ${when(
            this.exercisePlan.duration,
            () => html` <ion-item class="no-p no-inner-p text-sm">
              <div class="py-3.5 flex items-center justify-between w-full gap-x-3">
                <div class="flex items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M7.99984 10.6663V8.66634M7.99984 8.66634V6.66634M7.99984 8.66634H5.99984M7.99984 8.66634H9.99984M13.9998 3.99967L12.6665 2.66634M6.6665 1.33301H9.33317M7.99984 13.9997C5.05432 13.9997 2.6665 11.6119 2.6665 8.66634C2.6665 5.72082 5.05432 3.33301 7.99984 3.33301C10.9454 3.33301 13.3332 5.72082 13.3332 8.66634C13.3332 11.6119 10.9454 13.9997 7.99984 13.9997Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <ion-label class="font-semibold">Duração</ion-label>
                </div>
                <ion-note class="text-right">${this.getRestAndDuration(this.exercisePlan.duration!)}</ion-note>
              </div>
            </ion-item>`,
          )}
          ${when(
            this.exercisePlan.weight,
            () => html` <ion-item class="no-p no-inner-p text-sm">
              <div class="py-3.5 flex items-center justify-between w-full gap-x-3">
                <div class="flex items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_1367_3014)">
                      <path
                        d="M4.3335 4.33301L11.6668 11.6663"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M14.0002 13.9997L13.3335 13.333"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M2 2L2.66667 2.66667"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M12 14.6667L14.6667 12"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M1.3335 3.99967L4.00016 1.33301"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M2 6.66667L6.66667 2"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M9.3335 13.9997L14.0002 9.33301"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1367_3014">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <ion-label class="font-semibold">Peso</ion-label>
                </div>
                <ion-note class="text-right">${this.exercisePlan.weight} ${this.exercisePlan.weightUnit}</ion-note>
              </div>
            </ion-item>`,
          )}
          ${when(
            this.exercisePlan.rest,
            () => html` <ion-item class="no-p no-inner-p text-sm">
              <div class="py-3.5 flex items-center justify-between w-full gap-x-3">
                <div class="flex items-center space-x-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M9.33333 6V10M6.66667 6V10M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                      stroke="#111111"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <ion-label class="font-semibold">Repouso</ion-label>
                </div>
                <ion-note class="text-right">${this.getRestAndDuration(this.exercisePlan.rest!)}</ion-note>
              </div>
            </ion-item>`,
          )}
          ${when(
            this.exercisePlan.description,
            () => html`
              <ion-item lines="none" class="no-p no-inner-p text-sm">
                <div class="pt-3.5 w-full">
                  <div class="flex items-center space-x-1 mb-2.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M10 9.16667V13.3333M10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5ZM10.0415 6.66667V6.75L9.9585 6.75016V6.66667H10.0415Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <ion-label class="font-semibold">Descrição</ion-label>
                  </div>
                  <ion-note>${this.exercisePlan.description}</ion-note>
                </div>
              </ion-item>
            `,
          )}
        </ion-list>
        <div class="h-[1px] w-full bg-accent-7 mb-4"></div>
        <div class="flex flex-col space-y-2.5 px-4">
          ${this._content.map((c) => {
            if (c.file.type.startsWith("video/")) {
              return html`
                <div class="w-full relative">
                  <video controls class="w-full max-w-lg h-[180px] rounded-lg border border-solid border-accent-7">
                    <source src=${c.content} type=${c.file.type} />
                  </video>
                </div>
              `;
            } else {
              if (c.ratio && c.ratio > 1.25) {
                return html`
                  <div class="w-full h-[180px] relative">
                    <img
                      loading="lazy"
                      class="w-full max-w-lg h-[180px] aspect-video rounded-lg border border-accent-7 border-solid object-cover object-center"
                      src=${c.content} />
                  </div>
                `;
              } else {
                return html`
                  <div class="w-full relative">
                    <img
                      loading="lazy"
                      class="w-full max-w-lg aspect-square rounded-lg border border-accent-7 border-solid object-cover object-center"
                      src=${c.content} />
                  </div>
                `;
              }
            }
          })}
        </div>
      </ion-content>
    `;
  }
}
