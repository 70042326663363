import { expand, expandAt, pb } from "../../pocketbase";
import type { UserShareAction } from "../../stores/userShare.store";
import { createAccess } from "../group/access";
import { createExercise, createPublicExercise } from "../library/exercises";
import { createUser } from "../user/user";
import {
  createTemplateExercise,
  createTemplateExercisePlan,
  createTemplateExerciseShare,
  type CreateTemplateExercisePlan,
  type TemplateExercisePlan,
  type TemplateExercisePlanRecord,
  type CreateTemplateExercisePlanExercise,
  type TemplateExercisePlanExercise,
  type UpdateTemplateExercisePlan,
  type UpdateTemplateExercisePlanExercise,
} from "./templates";

class TemplatesRepository {
  async getOne(id: string): Promise<TemplateExercisePlan> {
    const result = await pb.collection<TemplateExercisePlanRecord>("template_exercise_plan").getOne(id, {
      expand: expand(
        "owner",
        "template_exercise_plan_share_via_template.user",
        "template_exercise_plan_share_via_template.access",
        "template_exercise_plan_exercises_via_template",
        "template_exercise_plan_exercises_via_template.exercise",
        "template_exercise_plan_exercises_via_template.exercisePublic",
      ),
    });

    const shared = expandAt(result, "template_exercise_plan_share_via_template").map((share) =>
      createTemplateExerciseShare({
        ...share,
        user: createUser(share.expand?.user),
        access: createAccess(share.expand?.access),
      }),
    );

    const exercises = expandAt(result, "template_exercise_plan_exercises_via_template").map((exerciseTemplate) => {
      // we need to do this because zod schema will fail if we have categories, difficulties,
      // equipments or muscles as string[] (we don't need this here)
      if (exerciseTemplate.expand?.exercisePublic) {
        exerciseTemplate.expand.exercisePublic.categories = [];
        exerciseTemplate.expand.exercisePublic.difficulties = [];
        exerciseTemplate.expand.exercisePublic.equipments = [];
        exerciseTemplate.expand.exercisePublic.muscles = [];
      }
      return createTemplateExercise({
        ...exerciseTemplate,
        exercise: exerciseTemplate.expand?.exercise ? createExercise(exerciseTemplate.expand?.exercise) : undefined,
        exercisePublic: exerciseTemplate.expand?.exercisePublic
          ? createPublicExercise(exerciseTemplate.expand?.exercisePublic)
          : undefined,
      });
    });

    return createTemplateExercisePlan({
      ...result,
      owner: createUser(result.expand?.owner),
      shared,
      exercises,
      totalExercises: exercises.length,
    });
  }

  async listTemplates(): Promise<TemplateExercisePlan[]> {
    const results = await pb.send<TemplateExercisePlanRecord[]>("/api/v1/template_exercise_plan", {
      method: "GET",
    });
    return results.map((result) => {
      const shared = expandAt(result, "template_exercise_plan_share_via_template").map((share) =>
        createTemplateExerciseShare({
          ...share,
          user: createUser(share.expand?.user),
          access: createAccess(share.expand?.access),
        }),
      );
      const owner = createUser(result.expand?.owner);
      return createTemplateExercisePlan({
        ...result,
        owner,
        shared,
      });
    });
  }

  async createTemplate(data: CreateTemplateExercisePlan): Promise<TemplateExercisePlan> {
    const form = new FormData();
    form.append("name", data.name);
    if(data.thumbnail) form.append("thumbnail", data.thumbnail);
    form.append("owner", data.owner);
    const result = await pb.collection("template_exercise_plan").create(form, {
      expand: expand("owner"),
    });
    const owner = createUser(result.expand?.owner);
    return createTemplateExercisePlan({
      ...result,
      owner,
      totalExercises: 0,
    });
  }

  async updateTemplate(id: string, data: UpdateTemplateExercisePlan): Promise<TemplateExercisePlan> {
    const form = new FormData();
    if (data.name) {
      form.append("name", data.name);
    }
    if (data.owner) {
      form.append("owner", data.owner);
    }
    if (data.thumbnail) {
      form.append("thumbnail", data.thumbnail);
    }
    const result = await pb.collection("template_exercise_plan").update(id, form, {
      expand: expand("owner"),
    });

    return createTemplateExercisePlan({
      ...result,
      owner: createUser(result.expand?.owner),
    });
  }

  async deleteTemplate(id: string) {
    return await pb.collection("template_exercise_plan").delete(id);
  }

  async shareTemplateWithUsers(templateId: string, users: UserShareAction[]): Promise<void> {
    await pb.send(`/api/v1/template_exercise_plan/${templateId}/share`, {
      method: "POST",
      body: JSON.stringify({ users }),
    });
  }

  async createExercise(
    templateId: string,
    data: CreateTemplateExercisePlanExercise,
  ): Promise<TemplateExercisePlanExercise> {
    const result = await pb.collection("template_exercise_plan_exercises").create(
      {
        ...data,
        template: templateId,
      },
      {
        expand: expand("exercise", "exercisePublic"),
      },
    );

    // we need to do this because zod schema will fail if we have categories, difficulties,
    // equipments or muscles as string[] (we don't need this here)
    if (result.expand?.exercisePublic) {
      result.expand.exercisePublic.categories = [];
      result.expand.exercisePublic.difficulties = [];
      result.expand.exercisePublic.equipments = [];
      result.expand.exercisePublic.muscles = [];
    }

    return createTemplateExercise({
      ...result,
      exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
      exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
    });
  }

  async updateExercise(id: string, data: UpdateTemplateExercisePlanExercise): Promise<TemplateExercisePlanExercise> {
    const result = await pb.collection("template_exercise_plan_exercises").update(id, data, {
      expand: expand("exercise", "exercisePublic"),
    });

    // we need to do this because zod schema will fail if we have categories, difficulties,
    // equipments or muscles as string[] (we don't need this here)
    if (result.expand?.exercisePublic) {
      result.expand.exercisePublic.categories = [];
      result.expand.exercisePublic.difficulties = [];
      result.expand.exercisePublic.equipments = [];
      result.expand.exercisePublic.muscles = [];
    }

    return createTemplateExercise({
      ...result,
      exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
      exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
    });
  }

  async deleteExercise(id: string) {
    return await pb.collection("template_exercise_plan_exercises").delete(id);
  }
}

export const templatesRepository = new TemplatesRepository();
