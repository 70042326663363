import { expand, expandAt, pb, type WithPage } from "../../pocketbase";
import type { Filter } from "../../stores/filter.store";
import type { Pagination } from "../../stores/pagination.store";
import { type GroupCategoryRecord, createCategory as createGroupCategory, createCategory } from "../group/category";
import { createExercise, createPublicExercise } from "../library/exercises";
import {
  type PatientEvaluationRecord,
  type EvaluationRecord,
  createEvaluationSummary,
  createReportCategory as createReportCategory,
} from "../report/report";
import { createUser, type User, type UserRecord } from "../user/user";
import {
  createExercisePlan,
  createSession,
  type DiagnosticRecord,
  type ExercisePlanRecord,
  type SessionRecord,
} from "./diagnostic";
import {
  type CreatePatient,
  type Patient,
  type PatientRecord,
  type UpdatePatient,
  createPatient,
  createPatientPublic,
  type PatientPublic,
} from "./patient";

export type PatientFilters = {
  query?: string;
  type?: Filter;
  categories?: Filter[];
};

export class PatientRepository {
  async getPatient(id: string): Promise<Patient> {
    const result = await pb.collection<PatientRecord>("patients").getOne(id, {
      expand: expand(
        "patients_category_via_patient.category",
        "diagnostics_via_patient",
        "diagnostics_via_patient.professionals",
      ),
    });

    const categories = expandAt<GroupCategoryRecord>(result, "patients_category_via_patient").map((c) => {
      return createGroupCategory(c.expand?.category);
    });

    const diagnostics = expandAt<DiagnosticRecord>(result, "diagnostics_via_patient").map((diagnostic) => {
      const professionals = expandAt<UserRecord>(diagnostic, "professionals").map(createUser);
      return {
        ...diagnostic,
        professionals,
      };
    });

    return createPatient({
      ...result,
      categories,
      diagnostics,
    });
  }

  async getPatientRecord(id: string): Promise<PatientPublic> {
    const result = await pb.send(`/api/v1/public/patient/${id}`, {});
    let professionals: Map<string, User> = new Map();

    if (result.sessions.length > 0) {
      result.sessions.forEach((session: SessionRecord) => {
        const prof = expandAt<UserRecord>(session, "profissional")[0];
        if (prof) {
          professionals.set(prof.id, createUser(prof));
        }
      });
    }

    return createPatientPublic({
      patient: createPatient(result.patient),
      sessions: result.sessions.map((s: SessionRecord) => {
        const exercisesPlan = expandAt<ExercisePlanRecord>(s, "exercises_plan_via_session").map((e) => {
          // we need to do this because zod schema will fail if we have categories, difficulties,
          // equipments or muscles as string[] (we don't need this here)
          if (e.expand?.exercisePublic) {
            e.expand.exercisePublic.categories = [];
            e.expand.exercisePublic.difficulties = [];
            e.expand.exercisePublic.equipments = [];
            e.expand.exercisePublic.muscles = [];
          }
          return createExercisePlan({
            ...e,
            exercise: e.expand?.exercise ? createExercise(e.expand?.exercise) : undefined,
            exercisePublic: e.expand?.exercisePublic ? createPublicExercise(e.expand?.exercisePublic) : undefined,
          });
        });
        const evaluations = expandAt<PatientEvaluationRecord>(s, "patient_evaluations_via_session").map((e) => {
          const evaluationArray = expandAt<EvaluationRecord>(e, "evaluation");

          if (!evaluationArray || evaluationArray.length === 0) return [];

          const evaluation = evaluationArray[0];
          return {
            ...e,
            evaluation: createEvaluationSummary({
              ...evaluation,
              categories: expandAt(evaluation, "evaluations_category_via_evaluation").map((c) =>
                createReportCategory(c.expand.category),
              ),
            }),
          };
        });
        return createSession({
          ...s,
          exercisesPlan,
          evaluations,
        });
      }),

      professionals: Array.from(professionals.values()),
    });
  }

  async createPatient(patient: CreatePatient): Promise<Patient> {
    const formData = new FormData();
    formData.append("section", patient.section);
    formData.append("group", patient.group);
    formData.append("name", patient.name);
    if (patient.email) formData.append("email", patient.email);
    formData.append("phone", patient.phone);
    formData.append("gender", patient.gender);
    formData.append("birthdate", patient.birthdate);
    if (patient.address) formData.append("address", patient.address);
    if (patient.clinicInfo) formData.append("clinicInfo", patient.clinicInfo);
    if (patient.avatar) formData.append("avatar", patient.avatar);
    if (patient.categories) {
      patient.categories.forEach((c) => {
        formData.append("categories", c);
      });
    }

    const result = await pb.send<PatientRecord>(`/api/v1/patient`, {
      method: "POST",
      body: formData,
    });

    return createPatient({
      ...result,
      categories: expandAt<GroupCategoryRecord>(result, "patients_category_via_patient").map((c) => {
        return createCategory(c.expand?.category);
      }),
    });
  }

  async updatePatient(id: string, data: UpdatePatient): Promise<Patient> {
    const formData = new FormData();
    if (data.name) formData.append("name", data.name);
    if (data.email) formData.append("email", data.email);
    if (data.phone) formData.append("phone", data.phone);
    if (data.gender) formData.append("gender", data.gender);
    if (data.birthdate) formData.append("birthdate", data.birthdate);
    if (data.address) formData.append("address", data.address);
    if (data.clinicInfo) formData.append("clinicInfo", data.clinicInfo);
    if (data.avatar) formData.append("avatar", data.avatar);
    if (data.isArchived !== undefined) formData.append("isArchived", data.isArchived.toString());
    if (data.categories) {
      data.categories.forEach((c) => {
        formData.append("categories", c);
      });
    }
    if (data["categories-"]) {
      data["categories-"].forEach((c) => {
        formData.append("categories-", c);
      });
    }
    const result = await pb.send<PatientRecord>(`/api/v1/patient/${id}`, {
      method: "PATCH",
      body: formData,
    });
    return createPatient({
      ...result,
      categories: expandAt<GroupCategoryRecord>(result, "patients_category_via_patient").map((c) => {
        return createCategory(c.expand?.category);
      }),
      diagnostics: expandAt<DiagnosticRecord>(result, "diagnostics_via_patient").map((diagnostic) => {
        return {
          ...diagnostic,
          professionals: expandAt<UserRecord>(diagnostic, "professionals").map(createUser),
        };
      }),
    });
  }

  async moveToSection(patientId: string, sectionId: string, groupId: string): Promise<Patient> {
    const result = await pb.send<PatientRecord>(`/api/v1/patient/${patientId}/move`, {
      method: "PATCH",
      body: { section: sectionId, group: groupId },
    });
    return createPatient(result);
  }

  async listPatients(
    groupId: string | string[],
    pagination: Pagination,
    filters: PatientFilters = {},
  ): Promise<WithPage<Patient[]>> {
    const conditions: string[] = [];

    if (filters?.categories && filters.categories.length > 0) {
      const categoryConditions = filters.categories.map((c) => `patients_category_via_patient.category ?= '${c.id}'`);
      conditions.push(`(${categoryConditions.join(" || ")})`);
    }

    if (filters?.type) {
      if (filters.type.id === "archived") {
        conditions.push(`isArchived = true`);
      } else {
        conditions.push(`isArchived = false`);
      }
    }

    if (filters?.query && filters.query.length > 0) {
      conditions.push(`(name ~ '${filters.query}')`);
    }

    let filter: string;
    if (conditions.length === 0) {
      if (Array.isArray(groupId)) {
        filter = groupId.map((g) => `group = '${g}'`).join(" || ");
      } else {
        filter = `group = '${groupId}'`;
      }
    } else {
      if (Array.isArray(groupId)) {
        filter = `(${groupId.map((g) => `group = '${g}'`).join(" || ")}) && (${conditions.join(" && ")})`;
      } else {
        filter = `group = '${groupId}' && (${conditions.join(" && ")})`;
      }
    }

    console.log("filter", filter);

    const results = await pb.collection<PatientRecord>("patients").getList(pagination.page, pagination.pageSize, {
      sort: "-created",
      expand: expand(
        "patients_category_via_patient.category",
        "diagnostics_via_patient",
        "diagnostics_via_patient.professionals",
      ),
      filter,
    });

    return {
      items: results.items.map((r) => {
        const categories = expandAt<GroupCategoryRecord>(r, "patients_category_via_patient").map((c) => {
          return createCategory(c.expand?.category);
        });

        const diagnostics = expandAt<DiagnosticRecord>(r, "diagnostics_via_patient").map((diagnostic) => {
          const professionals = expandAt<UserRecord>(diagnostic, "professionals").map(createUser);
          return {
            ...diagnostic,
            professionals,
          };
        });

        return createPatient({
          ...r,
          categories,
          diagnostics,
        });
      }),
      perPage: results.perPage,
      page: results.page,
      totalPages: results.totalPages,
      totalItems: results.totalItems,
    };
  }

  async removePatient(patientId: string): Promise<boolean> {
    return await pb.collection<PatientRecord>("patients").delete(patientId);
  }
}
export const patientRepository = new PatientRepository();
