import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { Page } from "../../components/component";
import { navigatorContext, NavigatorController } from "../../controllers/navigator.controller";
import { reportRepository } from "../../repository/report/report.repository";
import { Task } from "@lit/task";
import { routerContext, RouterContext } from "../../context/router.context";
import type { ScaleSummary } from "../../repository/report/report";
import { when } from "lit/directives/when.js";

@customElement("modal-choose-scale")
export class ModalChooseScale extends Page {
  registerScale!: (scale: ScaleSummary) => void;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;
  @consume({ context: routerContext }) router!: RouterContext;

  #scales = new Task(this, {
    task: async () => {
      return await reportRepository.getPrivateScales();
    },
    args: () => [],
  });

  sendEvent(scale: ScaleSummary) {
    this.dispatchEvent(new CustomEvent("choose-scale", { bubbles: true, composed: true, detail: scale }));
    this.navigator.goBack();
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
              <span class="mt-[3px] -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              voltar</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">Escalas</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        ${this.#scales.render({
          pending: () => {},
          error: (error) => {
            console.log(error);
            return html`<span class="text-danger">Erro ao carregar o diagnóstico</span>`;
          },
          complete: (scales) => {
            return html`
              ${when(
                scales.length > 0,
                () => html`
                  <ion-list class="space-y-4 mt-3">
                    ${scales.map(
                      (scale) => html`
                        <ion-item button .detail=${false} class="no-px" @click=${() => this.sendEvent(scale)}>
                          <ion-label>${scale.name}</ion-label>
                        </ion-item>
                      `,
                    )}
                  </ion-list>
                `,
                () => html`
                  <div
                    class="mt-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                      Ainda não existem escalas registadas
                    </span>
                  </div>
                `,
              )}
            `;
          },
        })}
      </ion-content>
    `;
  }
}
