import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { modalController } from "@ionic/core";
import { Page } from "../components/component";

@customElement("modal-show-exercise-content")
export class ModalShowExerciseContent extends Page {
  content!: string;

  #close() {
    modalController.dismiss();
  }

  isContentVideo() {
    const fileType = this.content.split(".").pop();
    return fileType === "mp4" || fileType === "webm" || fileType === "ogg";
  }

  render() {
    return html`
      <ion-content fullscreen style="--ion-background-color: #000000;" class="no-px overflow-hidden">
        <div class="w-full h-full relative flex items-center justify-center">
          ${this.isContentVideo()
            ? html`
                <video class="portrait:w-full landscape:h-full aspect-auto" controls>
                  <source src=${this.content} type="video/mp4" />
                  <source src=${this.content} type="video/webm" />
                  <source src=${this.content} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              `
            : html` <img loading="lazy" class="portrait:w-full landscape:h-full aspect-auto" src=${this.content} /> `}
          <ion-button
            @click=${this.#close}
            class="absolute bottom-4 backdrop-blur-xl text-white"
            size="small"
            shape="round"
            style="--ion-color-primary: #EAEAEA30; --padding-start: 10px; --padding-end: 10px">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-2">
              <path
                d="M3.3335 9.33301H6.66683V12.6663M12.6668 6.66634H9.3335V3.33301"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            Minimizar
          </ion-button>
        </div>
      </ion-content>
    `;
  }
}
