import type { RecordModel } from "pocketbase";
import { GroupRecordSchema } from "../group/group";
import { z } from "zod";
import { record } from "../shared/record";
import { unsafeParse } from "../shared/unsafeParse";
import { UserSchema } from "../user/user";
import { AccessSchema } from "../group/access";
import { ExerciseSchema } from "./exercises";

/* *************** LIBRARIES ****************** */

export const LibrarySharedGroupRecordSchema = record({
  exercisesLibrary: z.string(),
  group: z.string(),
});
export interface LibrarySharedGroupRecord extends z.infer<typeof LibrarySharedGroupRecordSchema> {}

export const LibrarySharedGroupSchema = LibrarySharedGroupRecordSchema.extend({
  group: GroupRecordSchema,
}).omit({ expand: true });
export interface LibrarySharedGroup extends z.infer<typeof LibrarySharedGroupSchema> {}
export function createLibrarySharedGroup(data: unknown) {
  return unsafeParse(LibrarySharedGroupSchema, data);
}

export const LibrarySharedPersonalRecordSchema = record({
  exercisesLibrary: z.string(),
  user: z.string(),
  access: z.string(),
});
export interface LibrarySharedPersonalRecord extends z.infer<typeof LibrarySharedPersonalRecordSchema> {}

export const LibrarySharedPersonalSchema = LibrarySharedPersonalRecordSchema.extend({
  user: UserSchema,
  access: AccessSchema,
}).omit({ expand: true });
export interface LibrarySharedPersonal extends z.infer<typeof LibrarySharedPersonalSchema> {}
export function createLibrarySharedPersonal(data: unknown) {
  return unsafeParse(LibrarySharedPersonalSchema, data);
}

export const LibraryRecordSchema = record({
  name: z.string(),
  owner: z.string(),
});
export interface LibraryRecord extends z.infer<typeof LibraryRecordSchema> {}

const LibrarySchema = LibraryRecordSchema.extend({
  owner: UserSchema,
  sharedPersonal: z.array(LibrarySharedPersonalSchema).default([]).optional(),
  sharedGroup: z.array(LibrarySharedGroupSchema).default([]).optional(),
  popularExercises: z.array(ExerciseSchema).default([]),
  totalExercises: z.number().optional().default(0),
}).omit({ expand: true });
export interface Library extends z.infer<typeof LibrarySchema> {}
export function createLibrary(data: unknown) {
  return unsafeParse(LibrarySchema, data);
}

const UserAccessSchema = z.object({
  userId: z.string(),
  accessId: z.string(),
});

export const CreateLibrarySchema = z.object({
  name: z.string({ required_error: "O nome é obrigatório" }),
  group: z.string().optional(),
  users: z.array(UserAccessSchema).optional(),
});
export interface CreateLibrary extends z.infer<typeof CreateLibrarySchema> {}

const CreateLibrarySimpleSchema = z.object({
  name: z.string(),
  owner: z.string(),
});
export interface CreateLibrarySimple extends z.infer<typeof CreateLibrarySimpleSchema> {}

export const UpdateLibrarySchema = z.object({
  name: z.string(),
});
export interface UpdateLibrary extends z.infer<typeof UpdateLibrarySchema> {}

/* *************** VIEWS ****************** */

export interface LibraryByGroupSharedRecord extends RecordModel {
  id: string;
  userId: string;
  owner: string;
  exerciseLibraryId: string;
  exerciseLibraryName: string;
  groupId: string;
  groupName: string;
  totalExercises: number;
}

export interface LibraryByOwnerRecord extends RecordModel {
  id: string;
  owner: string;
  exerciseLibraryId: string;
  exerciseLibraryName: string;
  totalExercises: number;
}

export interface LibraryByPrivateShareRecord extends RecordModel {
  id: string;
  userId: string;
  owner: string;
  exerciseLibraryId: string;
  exerciseLibraryName: string;
  totalExercises: number;
}

export interface AllLibrariesRecord extends RecordModel {
  id: string;
  owner: string;
  exerciseLibraryId: string;
  exerciseLibraryName: string;
  totalExercises: number;
}

const LibraryByGroupSharedViewSchema = z.object({
  id: z.string(),
  userId: z.string(),
  owner: z.string(),
  exerciseLibraryId: z.string(),
  exerciseLibraryName: z.string(),
  groupId: z.string(),
  groupName: z.string(),
  totalExercises: z.number(),
});

const LibraryByOwnerViewSchema = z.object({
  id: z.string(),
  owner: z.string(),
  exerciseLibraryId: z.string(),
  exerciseLibraryName: z.string(),
  totalExercises: z.number(),
});

const LibraryByPrivateShareViewSchema = z.object({
  id: z.string(),
  userId: z.string(),
  owner: z.string(),
  exerciseLibraryId: z.string(),
  exerciseLibraryName: z.string(),
  totalExercises: z.number(),
});

export interface LibraryByGroupShared extends z.infer<typeof LibraryByGroupSharedViewSchema> {}
export interface LibraryByOwner extends z.infer<typeof LibraryByOwnerViewSchema> {}
export interface LibraryByPrivateShare extends z.infer<typeof LibraryByPrivateShareViewSchema> {}

/* *************** MISC ****************** */

export const LibraryUserSchema = z.object({
  id: z.string().optional(),
  userId: z.string(),
  name: z.string(),
  username: z.string(),
  role: z.string({ required_error: "O acesso é obrigatório" }),
  avatar: z.string().optional(),
});

export const CreateLibrarySharedSchema = z.object({
  library: z.string(),
  user: z.string(),
  access: z.string(),
});

export const UpdateLibrarySharedSchema = z.object({
  access: z.string(),
});

export interface LibraryUser extends z.infer<typeof LibraryUserSchema> {}
export interface CreateLibraryShared extends z.infer<typeof CreateLibrarySharedSchema> {}
export interface UpdateLibraryShared extends z.infer<typeof UpdateLibrarySharedSchema> {}

export function createUpdateLibraryShared(data: unknown) {
  return unsafeParse(UpdateLibrarySharedSchema, data);
}
