import { html, nothing } from "lit";
import { customElement } from "lit/decorators.js";
import { Page } from "../components/component";
import { Task } from "@lit/task";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import type { ModalNav } from "../modals/modal-nav";
import { routerContext, RouterContext } from "../context/router.context";
import { UserShareStore } from "../stores/userShare.store";
import { ObservableReport, reportsStore } from "../stores/reports.store";
import { when } from "lit/directives/when.js";
import { createPopover } from "../components/popover.controller";
import { alertController, toastController } from "@ionic/core";

@customElement("page-reports")
export class PageReports extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;

  shareStore = new UserShareStore();

  #ionRefresher: Ref<IonRefresher> = createRef();
  #modalNewReportLibraryRef: Ref<ModalNav> = createRef();
  #modalEditReportLibraryRef: Ref<ModalNav> = createRef();

  connectedCallback(): void {
    super.connectedCallback();
    this.#reports.run([false]);
  }

  #reports = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return await reportsStore.loadReports({ cache });
    },
  });

  openPopover(e: Event, index: number) {
    const popover = document.querySelector(`#report-library-popover-${index}`) as HTMLIonPopoverElement;
    popover.event = e;
    popover.isOpen = true;
    popover.addEventListener("didDismiss", () => (popover.isOpen = false));
  }

  async presentPopover(e: Event, report: ObservableReport) {
    if (report.isShared(this.auth.user)) {
      const popover = await createPopover(
        [
          {
            text: "Sair",
            handler: async () => {
              const leaveAlert = await alertController.create({
                header: "Sair da biblioteca",
                message: "Tem a certeza que deseja sair desta biblioteca?",
                buttons: [
                  {
                    text: "Cancelar",
                    role: "cancel",
                  },
                  {
                    text: "Sair",
                    role: "destructive",
                    handler: async () => {
                      try {
                        await reportsStore.leaveReport(report, this.auth.user);
                      } catch (error) {
                        // close the previous alert
                        await leaveAlert.dismiss();

                        const errorAlert = await alertController.create({
                          header: "Erro",
                          message: "Erro ao sair da biblioteca. Por favor, tente novamente mais tarde.",
                          buttons: ["OK"],
                        });
                        await errorAlert.present();
                        console.error(error);
                      }
                    },
                  },
                ],
              });
              await leaveAlert.present();
            },
          },
        ],
        e,
      );
      await popover.present();
    } else if (report.isOwner(this.auth.user)) {
      const popover = await createPopover(
        [
          {
            text: "Editar",
            handler: () => {
              this.#modalEditReportLibraryRef.value?.open({ report: report });
            },
          },
          {
            text: "Apagar",
            handler: async () => {
              const alert = await alertController.create({
                header: "Apagar biblioteca",
                message: "Tem a certeza que deseja apagar esta biblioteca? A ação não é reversível.",
                buttons: [
                  {
                    text: "Cancelar",
                    role: "cancel",
                  },
                  {
                    text: "Apagar",
                    role: "destructive",
                    handler: async () => {
                      await reportsStore.deleteReport(report).then(async () => {
                        const toast = await toastController.create({
                          message: "Biblioteca apagada com sucesso",
                          color: "primary",
                          icon: "/assets/icons/info.svg",
                          duration: 2000,
                        });
                        await toast.present();
                      });
                    },
                  },
                ],
              });
              await alert.present();
            },
          },
        ],
        e,
      );
      await popover.present();
    }
  }

  render() {
    return html`
      <ion-content fullscreen style="--padding-bottom: 24px">
        <!-- ----------- HEADER --------------- -->
        <div class="w-full mt-4 h-10 flex items-center">
          <h3>Avaliações</h3>
        </div>

        <!-- --------------- REFRESHER ---------------- -->
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            if (!this.auth.user) return;
            await this.#reports.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text="Puxe para atualizar"></ion-refresher-content>
        </ion-refresher>

        ${this.#reports.render({
          pending: () => {
            return html`
              <div class="space-y-4">
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
              </div>
            `;
          },
          error: (error) => {
            console.error(error);
            return html` <span class="text-danger">Erro ao carregar as avaliações</span> `;
          },
          complete: (reports) => {
            return html`
              <ion-list lines="none" class="space-y-4 mt-2.5">
                ${reports.map((report) => {
                  return html`
                    <ion-item
                      button
                      .detail=${false}
                      style="--border-width: 1px; --border-color: #EAEAEA; --border-radius: 6px"
                      class="no-p no-inner-p"
                      @click=${() => this.router.push(`/reports/${report.id}`)}>
                      <div
                        class="w-full h-[140px] bg-accent-8 flex col justify-center items-center space-y-0.5 relative">
                        ${when(
                          report.id === "public" || !report.canEdit(this.auth.user),
                          () => nothing,
                          () => html`
                            <ion-button
                              @click=${(e: Event) => {
                                e.stopImmediatePropagation();
                                this.presentPopover(e, report);
                              }}
                              class="absolute top-2.5 right-1"
                              fill="clear"
                              size="chip">
                              <ion-icon slot="icon-only" icon="/assets/icons/dots.svg"></ion-icon>
                            </ion-button>
                          `,
                        )}
                        <p class="font-display font-semibold text-accent-1">${report.name}</p>
                        <ion-badge class="px-1.5 py-0.5 text-xs font-semibold"
                          >${report.totalTemplates} avaliações</ion-badge
                        >
                      </div>
                    </ion-item>
                  `;
                })}
              </ion-list>
              <!-- ------------FAB------------ -->
              <ion-fab
                slot="fixed"
                vertical="bottom"
                horizontal="end"
                @click=${() =>
                  this.#modalNewReportLibraryRef.value?.open({
                    shareStore: this.shareStore,
                  })}>
                <ion-fab-button id="open-modal-create-report" label="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6 12H12M12 12H18M12 12V18M12 12V6"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </ion-fab-button>
              </ion-fab>
            `;
          },
        })}
      </ion-content>

      <modal-nav root="modal-create-report-library" ${ref(this.#modalNewReportLibraryRef)}></modal-nav>
      <modal-nav root="modal-edit-report-library" ${ref(this.#modalEditReportLibraryRef)}></modal-nav>
    `;
  }
}
