import { expandAt, pb, expand } from "../../pocketbase";
import { createUser } from "../user/user";
import {
  createPatient,
  type PatientRecord,
} from "../patient/patient";
import {
  createGroup,
  type Group,
  type GroupRecord,
  type CreateGroup,
  type UpdateGroup,
  type GroupSummary,
  createGroupShared,
  type GroupSharedRecord,
} from "./group";
import type { UserShareAction } from "../../stores/userShare.store";
import { createSection, type CreateSection, type Section, type UpdateSection, type SectionRecord, type UpdateSectionWithId } from "./section";
import { createAccess } from "./access";
import { type GroupCategoryRecord, createCategory, type Category, type CreateCategory } from "./category";

export class GroupRepository {
  async get(id: string): Promise<Group> {
    const result = await pb.collection<GroupRecord>("groups").getFirstListItem(`id = "${id}"`, {
      expand: expand(
        "owner",
        "group_shared_via_group.user",
        "group_shared_via_group.access",
        "sections_via_group",
        "sections_via_group.patients_via_section",
        // get the categories for each patient
        "sections_via_group.patients_via_section.patients_category_via_patient.category",
      ),
    });

    const owner = createUser(result.expand?.owner);

    const shared = expandAt<GroupSharedRecord>(result, "group_shared_via_group").map((item) => {
      return createGroupShared({
        ...item,
        user: createUser(item.expand?.user),
        access: createAccess(item.expand?.access),
      });
    });

    const sections = expandAt<SectionRecord>(result, "sections_via_group").map((section) => {
      return createSection({
        ...section,
        patients: expandAt<PatientRecord>(section, "patients_via_section").map((patient) => {
          return createPatient({
            ...patient,
            categories: expandAt<GroupCategoryRecord>(patient, "patients_category_via_patient").map((c) =>
              createCategory(c.expand?.category),
            ),
          });
        }),
      });
    });

    return createGroup({
      ...result,
      shared,
      sections,
      owner,
    });
  }

  async groupsSummary(): Promise<GroupSummary[]> {
    const result = await pb.send<
      Array<{
        group: GroupRecord;
        popularPatients: PatientRecord[];
        totalPatients: number;
        archivedPatients: number;
      }>
    >(`/api/v1/group/summary`, {
      method: "GET",
    });

    if(!result) {
      return [];
    }

    const groups = result.map(({ group, popularPatients, totalPatients, archivedPatients }) => {
      const owner = createUser(group.expand?.owner);

      const shared = expandAt<GroupSharedRecord>(group, "group_shared_via_group").map((item) => {
        const user = createUser(item.expand?.user);
        const access = createAccess(item.expand?.access);
        return createGroupShared({
          ...item,
          user,
          access,
        });
      });

      const sections = expandAt<SectionRecord>(group, "sections_via_group").map((sectionRecord) => {
        return createSection(sectionRecord);
      });

      const patients = popularPatients.map((patient) => {
        return createPatient({
          ...patient,
          categories: expandAt<GroupCategoryRecord>(patient, "patients_category_via_patient").map((c) =>
            createCategory(c.expand?.category),
          ),
        });
      });

      return {
        ...group,
        shared,
        sections,
        owner,
        popularPatients: patients,
        totalPatients,
        archivedPatients,
      };
    });

    return groups;
  }

  async listGroupsByOwner(owner: string): Promise<Group[]> {
    const result = await pb.collection<GroupRecord>("groups").getFullList({
      filter: `owner = "${owner}" && isArchived = false`,
      expand: "owner",
    });

    return result.map((g) => createGroup({ ...g, owner: createUser(g.expand?.owner) }));
  }

  async createGroup(group: CreateGroup): Promise<Group> {
    const result = await pb.collection<GroupRecord>("groups").create(
      {
        name: group.name,
        owner: group.owner,
        isArchived: false,
      },
      {
        expand: "owner",
      },
    );

    return createGroup({
      ...result,
      owner: createUser(result.expand?.owner),
    });
  }

  async updateGroup(groupId: string, data: UpdateGroup): Promise<Group> {
    const result = await pb.collection<GroupRecord>("groups").update(
      groupId,
      {
        isArchived: data.isArchived,
        name: data.name,
      },
      {
        expand: "owner",
      },
    );

    return createGroup({
      ...result,
      owner: createUser(result.expand?.owner),
      isArchived: result.isArchived,
      name: result.name,
    });
  }

  async shareGroupWithUsers(groupId: string, users: UserShareAction[]): Promise<void> {
    await pb.send(`/api/v1/group/${groupId}/share`, {
      method: "POST",
      body: JSON.stringify({ users }),
    });
  }

  async updateSections(groupId: string, sections: UpdateSectionWithId[]) {
    await pb.send(`/api/v1/group/${groupId}/section`, {
      method: "PATCH",
      body: JSON.stringify({ sections }),
    });
  }

  async getGroupCategories(groupId: string): Promise<Category[]> {
    const records = await pb.collection<GroupCategoryRecord>("group_category").getFullList({
      filter: `group = "${groupId}"`,
      sort: "+category",
      expand: "patients_category_via_category",
    });

    const categoriesFiltered = records
      .map((r) => {
        const amount = expandAt<GroupCategoryRecord>(r, "patients_category_via_category").length;
        return createCategory({
          ...r,
          count: amount,
        });
      })
      .toSorted((a, b) => (b.count || 0) - (a.count || 0));

    return categoriesFiltered;
  }

  async createCategory(data: CreateCategory): Promise<Category> {
    const result = await pb.collection<GroupCategoryRecord>("group_category").create(data);
    return createCategory(result);
  }

  async removeCategory(categoryId: string) {
    return pb.collection("group_category").delete(categoryId);
  }

  async createSection(section: CreateSection): Promise<Section> {
    const result = await pb.collection<SectionRecord>("sections").create({
      name: section.name,
      group: section.group,
      position: section.position,
    });

    return createSection({
      ...result,
      patients: [],
    });
  }

  async updateSection(sectionId: string, data: UpdateSection): Promise<Section> {
    const result = await pb.collection<SectionRecord>("sections").update(sectionId, {
      name: data.name,
      position: data.position,
    });
    return createSection(result);
  }

  async deleteSection(id: string): Promise<boolean> {
    return pb.collection("sections").delete(id);
  }

  async deleteGroup(id: string): Promise<boolean> {
    return pb.collection("groups").delete(id);
  }

  async leaveGroup(id: string) {
    return await pb.collection<GroupSharedRecord>("group_shared").delete(id);
  }
}

export const groupRepository = new GroupRepository();
