import { html } from "lit";
import { queryAsync, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { repeat } from "lit/directives/repeat.js";
import { Task, TaskStatus } from "@lit/task";
import { ObservableLibrary, librariesStore } from "../stores/libraries.store";
import { RouterContext, routerContext } from "../context/router.context";
import { Page, required } from "../components/component";
import type { ObservableExercise } from "../stores/exercises.store";
import { customElement } from "../element";
import type { IonList } from "@ionic/core/components/ion-list";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { classMap } from "lit/directives/class-map.js";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import { sleep } from "../pocketbase";
import { FilterStore } from "../stores/filter.store";
import { PaginationStore } from "../stores/pagination.store";
import type { SelectStore } from "../stores/select.store";
import { when } from "lit/directives/when.js";
import { ModalExerciseFilters } from "./modal-library-filters";
import type { Category, PublicExercise } from "../repository/library/exercises";

@customElement("modal-choose-exercise-pool")
export class ModalChooseExercisePool extends Page {
  @state() private _exercises: ObservableExercise[] = [];
  @state() private _exercisesFiltered: ObservableExercise[] = [];
  @state() private query: string = "";

  @required() library!: ObservableLibrary;
  @required() exercises!: SelectStore<PublicExercise | ObservableExercise>;

  private pagination = new PaginationStore(1, 20, 0);
  private filterPagination = new PaginationStore(1, 20, 0);
  private filterStore = new FilterStore();

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  #ionRefresher: Ref<IonRefresher> = createRef();

  @queryAsync("ion-list") list!: Promise<IonList>;

  async connectedCallback() {
    super.connectedCallback();

    // run this sequentially because we need the library to be loaded first
    await this.#loadLibrary.run([true]);
    await this.#loadExercises.run([]);
  }

  #loadLibrary = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return await librariesStore.loadLibrary(this.library.id, { cache });
    },
  });

  #loadExercises = new Task(this, {
    task: async () => {
      return await librariesStore.loadExercises(this.library.id, this.pagination);
    },
    onComplete: (exercises) => {
      this._exercises = exercises.items;
      this.pagination.setTotal(exercises.totalItems);
    },
  });

  #loadFilterExercises = new Task(this, {
    task: async () => {
      if (this.hasFilters()) {
        return await librariesStore.loadExercises(this.library.id, this.filterPagination, {
          query: this.query,
          muscles: this.filterStore.filtersByKey("muscles"),
          categories: this.filterStore.filtersByKey("categories"),
          equipments: this.filterStore.filtersByKey("equipments"),
          difficulties: this.filterStore.filtersByKey("difficulties"),
        });
      }
      return null;
    },
    onComplete: (exercises) => {
      if (exercises) {
        this._exercisesFiltered = exercises.items;
        this.filterPagination.setTotal(exercises.totalItems);
      }
    },
    args: () => [this.query, this.filterStore.filters.length] as const,
  });

  hasFilters() {
    return this.filterStore.hasFilters || this.query.length > 0;
  }

  resetState() {
    this.query = "";
    this.filterStore.clear();
    this.pagination.setPage(1);
    this.filterPagination.setPage(1);
    this.#loadExercises.run([]);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                @click="${() => {
                  this.navigator.goBack();
                }}"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="w-[80%] pr-4">${this.#loadLibrary.value?.name}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content fullscreen style="--padding-bottom: 0px">
        ${when(
          !this.hasFilters(),
          () => html`
            <ion-refresher
              slot="fixed"
              @ionRefresh=${async () => {
                await this.#loadLibrary.run([false]);
                this.pagination.setPage(1);
                await this.#loadExercises.run();
                await this.#ionRefresher.value?.complete();
              }}
              ${ref(this.#ionRefresher)}>
              <ion-refresher-content class="mt-4" pulling-text="Puxe para atualizar"></ion-refresher-content>
            </ion-refresher>
          `,
        )}

        <ion-header collapse="condense">
          <ion-toolbar>
            ${this.#loadLibrary.render({
              pending: () => {
                return html`
                  <div class="flex justify-between items-start">
                    <h2>
                      <ion-skeleton-text animated style="width: 100px; height: 1em;"></ion-skeleton-text>
                    </h2>
                    <div class="flex items-center space-x-1 mt-2">
                      <ion-skeleton-text animated style="width: 52px; height: 24px;"></ion-skeleton-text>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M3.98691 2.28614C2.78603 6.0889 1.70525 15.2556 6.98909 21.5001C8.79038 20.2992 14.1943 21.5001 17.1965 20.2992C19.5983 19.3385 21.3996 17.4972 22 16.6966M9.99125 2.88657C9.19066 4.28759 8.06985 7.32981 9.99125 8.29051C11.9126 9.25121 11.9927 11.4928 11.7926 12.4936C12.393 10.292 15.2751 6.4892 22 8.89094M14.5714 13.6944C14.9024 12.5936 16.4913 10.6922 20.1987 11.8931"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                `;
              },
              error: (error) => {
                console.error(error);
                return html`
                  <ion-alert
                    header="Erro"
                    message="Ocorreu um erro ao carregar a biblioteca"
                    .isOpen=${true}
                    .buttons=${["OK"]}></ion-alert>
                  <div class="flex justify-between items-start">
                    <h2>
                      <ion-skeleton-text animated style="width: 52px; height: 1em;"></ion-skeleton-text>
                    </h2>
                    <div class="flex items-center space-x-1 mt-2">
                      <ion-skeleton-text animated style="width: 52px; height: 24px;"></ion-skeleton-text>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M3.98691 2.28614C2.78603 6.0889 1.70525 15.2556 6.98909 21.5001C8.79038 20.2992 14.1943 21.5001 17.1965 20.2992C19.5983 19.3385 21.3996 17.4972 22 16.6966M9.99125 2.88657C9.19066 4.28759 8.06985 7.32981 9.99125 8.29051C11.9126 9.25121 11.9927 11.4928 11.7926 12.4936C12.393 10.292 15.2751 6.4892 22 8.89094M14.5714 13.6944C14.9024 12.5936 16.4913 10.6922 20.1987 11.8931"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                `;
              },
              complete: (library) => {
                return html`
                  <div class="flex justify-between items-start gap-x-3">
                    <h2>${library?.name}</h2>
                    <div class="flex items-center space-x-1 mt-2">
                      ${when(
                        this.#loadExercises.status === TaskStatus.PENDING ||
                          this.#loadFilterExercises.status === TaskStatus.PENDING,
                        () => html`<ion-skeleton-text animated style="width: 52px; height: 24px;"></ion-skeleton-text>`,
                        () =>
                          html`<span class="font-display font-semibold"
                            >${this.hasFilters() ? this.filterPagination.total : this.pagination.total}</span
                          >`,
                      )}
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M3.98691 2.28614C2.78603 6.0889 1.70525 15.2556 6.98909 21.5001C8.79038 20.2992 14.1943 21.5001 17.1965 20.2992C19.5983 19.3385 21.3996 17.4972 22 16.6966M9.99125 2.88657C9.19066 4.28759 8.06985 7.32981 9.99125 8.29051C11.9126 9.25121 11.9927 11.4928 11.7926 12.4936C12.393 10.292 15.2751 6.4892 22 8.89094M14.5714 13.6944C14.9024 12.5936 16.4913 10.6922 20.1987 11.8931"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                `;
              },
            })}
          </ion-toolbar>
        </ion-header>

        <!-- --------- SEARCHBAR ------------ -->
        <ion-item class="mt-2.5">
          <span aria-hidden="true" slot="start" class="flex items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <ion-input
            debounce=${150}
            @ionInput="${(e: InputEvent) => (this.query = (e.target as HTMLInputElement).value)}"
            .value=${this.query}
            clear-on-edit
            type="search"
            placeholder="Pesquisar exercícios"></ion-input>
          <ion-button
            shape="round"
            size="chip"
            slot="end"
            @click=${() =>
              this.navigator.push(ModalExerciseFilters, {
                filterStore: this.filterStore,
                library: this.library,
              })}>
            <span class="flex items-center mr-0.5">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                  d="M11.6663 3.26672C11.6663 2.94002 11.6661 2.77655 11.6025 2.65177C11.5466 2.54201 11.4577 2.45283 11.348 2.39691C11.2232 2.33333 11.0595 2.33333 10.7328 2.33333H3.26611C2.93942 2.33333 2.77623 2.33333 2.65145 2.39691C2.54169 2.45283 2.45251 2.54201 2.39659 2.65177C2.33301 2.77655 2.33301 2.94002 2.33301 3.26672V3.69679C2.33301 3.83946 2.33301 3.91086 2.34913 3.97799C2.36342 4.03751 2.38704 4.09437 2.41903 4.14656C2.45509 4.20541 2.50562 4.25594 2.60645 4.35677L5.55971 7.31003C5.66059 7.41091 5.71076 7.46108 5.74684 7.51995C5.77882 7.57214 5.80277 7.62919 5.81706 7.68872C5.83301 7.75517 5.83301 7.82571 5.83301 7.9655V10.7397C5.83301 11.2398 5.83301 11.49 5.93832 11.6405C6.03029 11.772 6.17216 11.8597 6.33089 11.8832C6.51267 11.91 6.73642 11.7983 7.18368 11.5747L7.65034 11.3414C7.83763 11.2477 7.93104 11.2007 7.99946 11.1309C8.05996 11.0691 8.10625 10.9949 8.13444 10.9132C8.16632 10.8208 8.16634 10.7158 8.16634 10.5064V7.96984C8.16634 7.82717 8.16634 7.75585 8.18246 7.68872C8.19675 7.62919 8.22038 7.57214 8.25236 7.51995C8.28819 7.46148 8.33823 7.41144 8.43778 7.31189L8.43978 7.31003L11.393 4.35677C11.4939 4.25588 11.5441 4.20543 11.5802 4.14656C11.6122 4.09437 11.6361 4.03751 11.6504 3.97799C11.6663 3.91154 11.6663 3.84092 11.6663 3.70113V3.26672Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Filtrar
          </ion-button>
        </ion-item>
        <!-- --------- SEARCHBAR ------------ -->

        <!-- FILTERS -->
        ${when(
          this.hasFilters(),
          () => html`
            <div
              ?hidden=${!this.filterStore.hasFilters}
              class=${classMap({
                "flex items-center gap-x-1.5 gap-y-2 flex-wrap": true,
                "mt-2": this.filterStore.hasFilters,
              })}>
              ${repeat(
                this.filterStore.filters,
                (filter) => filter.id,
                (filter) => html`
                  <ion-chip
                    color="primary"
                    class="px-2.5 py-1.5 text-sm font-medium"
                    @click=${() => this.filterStore.remove(filter)}>
                    ${filter.name}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      class="ml-1 mb-px">
                      <rect width="12" height="12" rx="6" fill="white" />
                      <path
                        d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </ion-chip>
                `,
              )}
            </div>
          `,
        )}
        <!-- FILTERS -->

        <!-- --------------- CONTENT ----------------- -->
        ${when(
          this.hasFilters(),
          () =>
            this.#loadFilterExercises.render({
              pending: () => {
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              },
              error: (error) => {
                console.error(error);
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                  <ion-alert
                    header="Erro"
                    message="Ocorreu um erro ao carregar os exercícios"
                    .isOpen=${true}
                    .buttons=${["OK"]}></ion-alert>
                `;
              },
              complete: (_) => {
                return html`
                  <ion-list lines="none" class="mt-4 space-y-2.5 flex flex-col">
                    ${repeat(
                      this._exercisesFiltered,
                      (exercise: ObservableExercise) => exercise.id,
                      (exercise: ObservableExercise) => {
                        return html`
                          <ion-item
                            button
                            lines="none"
                            detail=${false}
                            class="no-p flex space-x-2 items-center w-full last:pb-4"
                            @click=${() => {
                              this.exercises?.stage(exercise);
                              this.exercises.commit();
                              this.navigator.goToRoot();
                            }}
                            style="--border-color: transparent;">
                            <img
                              src="${exercise.thumbnail({ width: 128, height: 128 })}"
                              loading="lazy"
                              class="w-[52px] h-[52px] object-cover rounded-md" />
                            <div class="col max-w-[calc(100%-60px)]">
                              <p class="font-semibold line-clamp-1">${exercise.name}</p>
                              <div class="flex items-center space-x-1.5">
                                ${exercise.categories.map(
                                  (tag: Category) => html`
                                    <span class="text-sm">${tag.category}</span>
                                    <span class="w-1 h-1 bg-accent-6 rounded last:hidden"></span>
                                  `,
                                )}
                              </div>
                            </div>
                          </ion-item>
                        `;
                      },
                    )}
                  </ion-list>
                  ${when(
                    this.filterPagination.hasNext,
                    () => html`
                      <ion-infinite-scroll
                        threshold="0px"
                        @ionInfinite=${async (e: any) => {
                          await sleep(500);
                          this.filterPagination.nextPage();
                          const exercises = await librariesStore.loadExercises(this.library.id, this.filterPagination, {
                            query: this.query,
                            muscles: this.filterStore.filtersByKey("muscles"),
                            categories: this.filterStore.filtersByKey("categories"),
                            equipments: this.filterStore.filtersByKey("equipments"),
                            difficulties: this.filterStore.filtersByKey("difficulties"),
                          });
                          this._exercisesFiltered = this._exercisesFiltered.concat(exercises.items);
                          e.target.complete();
                        }}>
                        <ion-infinite-scroll-content
                          loading-text="A carregar mais exercícios"></ion-infinite-scroll-content>
                      </ion-infinite-scroll>
                    `,
                  )}
                `;
              },
            }),
          () =>
            this.#loadExercises.render({
              pending: () => {
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              },
              error: (error) => {
                console.error(error);
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                  <ion-alert
                    header="Erro"
                    message="Ocorreu um erro ao carregar os exercícios"
                    .isOpen=${true}
                    .buttons=${["OK"]}></ion-alert>
                `;
              },
              complete: (exercises) => {
                return html`
                  ${when(
                    exercises.items.length > 0,
                    () => {
                      return html`
                        <ion-list lines="none" class="mt-4 space-y-2.5">
                          ${repeat(
                            this._exercises,
                            (exercise: ObservableExercise) => exercise.id,
                            (exercise: ObservableExercise) => {
                              return html`
                                <ion-item
                                  button
                                  lines="none"
                                  detail=${false}
                                  class="no-p flex space-x-2 items-center w-full last:pb-4"
                                  @click=${() => {
                                    this.exercises?.stage(exercise);
                                    this.exercises.commit();
                                    this.navigator.goToRoot();
                                  }}
                                  style="--border-color: transparent;">
                                  <img
                                    src="${exercise.thumbnail({ width: 128, height: 128 })}"
                                    loading="lazy"
                                    class="w-[52px] h-[52px] object-cover rounded-md" />
                                  <div class="col max-w-[calc(100%-60px)]">
                                    <p class="font-semibold line-clamp-1">${exercise.name}</p>
                                    <div class="flex items-center space-x-1.5">
                                      ${exercise.categories.map(
                                        (tag: Category) => html`
                                          <span class="text-sm">${tag.category}</span>
                                          <span class="w-1 h-1 bg-accent-6 rounded last:hidden"></span>
                                        `,
                                      )}
                                    </div>
                                  </div>
                                </ion-item>
                              `;
                            },
                          )}
                        </ion-list>
                        ${when(
                          this.pagination.hasNext,
                          () => html`
                            <ion-infinite-scroll
                              threshold="0px"
                              @ionInfinite=${async (e: any) => {
                                await sleep(500);
                                this.pagination.nextPage();
                                const exercises = await librariesStore.loadExercises(this.library.id, this.pagination);
                                this._exercises = this._exercises.concat(exercises.items);
                                e.target.complete();
                              }}>
                              <ion-infinite-scroll-content
                                loading-text="A carregar mais exercícios"></ion-infinite-scroll-content>
                            </ion-infinite-scroll>
                          `,
                        )}
                      `;
                    },
                    () => {
                      return html`
                        <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                          <div
                            class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                            <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                              Não existem exercícios nesta biblioteca
                            </span>
                          </div>
                        </ion-item>
                      `;
                    },
                  )}
                `;
              },
            }),
        )}
      </ion-content>

      <!-- <ion-footer>
        <ion-toolbar style="--background: transparent">
          <ion-button
            shape="round"
            expand="block"
            class="font-semibold"
            @click=${() => {
              this.exercises.commit();
              this.navigator.goToRoot();
            }}
            >Guardar Exercícios</ion-button
          >
        </ion-toolbar>
      </ion-footer> -->
    `;
  }
}
