import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { html } from "lit";
import { when } from "lit/directives/when.js";
import { toastController } from "@ionic/core";
import type { ExercisePlan, Session, UpdateSession } from "../repository/patient/diagnostic";
import { repeat } from "lit/directives/repeat.js";
import { Page } from "../components/component";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { diagnosticRepository } from "../repository/patient/diagnostic.repository";
import { pb } from "../pocketbase";

@customElement("modal-public-session")
export class ModalPublicSession extends Page {
  session!: Session;
  @state() private _sortedExercises: ExercisePlan[] = [];
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  connectedCallback(): void {
    super.connectedCallback();
    this._sortedExercises = this.session.exercisesPlan;
    this._sortedExercises.sort((a, b) => {
      return a.position - b.position;
    });
  }

  getDateChip(date: string, startTime: string, endTime: string) {
    const today = new Date();
    const time = today.getHours() + ":" + today.getMinutes();
    const formattedToday = [
      String(today.getDate()).padStart(2, "0"),
      String(today.getMonth() + 1).padStart(2, "0"),
      today.getFullYear(),
    ].join("-");
    if (date === formattedToday && startTime < time && time < endTime) {
      return html`<ion-badge style="--background: #79FFE1;" class="text-sm font-medium text-black px-2 py-0.5"
        >A decorrer</ion-badge
      > `;
    }
    return html` <ion-chip class="text-sm font-medium px-2 py-0.5" style="--background: #EAEAEA; --color: #111111"
      >Fechado</ion-chip
    >`;
  }

  formatDate(isoDateString: string) {
    const date = format(new Date(isoDateString), "P", { locale: pt });
    return date.replace(/\//g, "-");
  }

  getDayMonth(isoDateString: string) {
    return format(new Date(isoDateString), "dd MMMM", { locale: pt });
  }

  getTime(isoDateString: string) {
    return format(new Date(isoDateString), "HH:mm");
  }

  async editSessionNotes(notes?: string) {
    const data: UpdateSession = {
      patientNotes: notes,
    };
    try {
      await diagnosticRepository.updateSession(this.session.id, data);
      const toast = await toastController.create({
        message: "Notas atualizadas com sucesso",
        duration: 2000,
        color: "primary",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    } catch (error) {
      const toast = await toastController.create({
        message: "Não foi possível guardar as alterações",
        duration: 2000,
        color: "danger",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    }
  }

  getSpecs(e: ExercisePlan) {
    let specs = [];

    if (e.series) {
      specs.push(html`<span class="text-sm">${e.series} Séries</span>`);
    }
    if (e.repetitions) {
      specs.push(html`<span class="text-sm">${e.repetitions} Repetições</span>`);
    }
    if (e.weight) {
      specs.push(html`<span class="text-sm">${e.weight} ${e.weightUnit}</span>`);
    }
    if (e.rest) {
      const totalSeconds = e.rest;
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      if (minutes > 0) specs.push(html`<span class="text-sm">${minutes} Minutos</span>`);
      if (seconds > 0) specs.push(html`<span class="text-sm">${seconds} Segundos</span>`);
    }
    if (e.duration) {
      const totalSeconds = e.duration;
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      if (minutes > 0) specs.push(html`<span class="text-sm">${minutes} Minutos</span>`);
      if (seconds > 0) specs.push(html`<span class="text-sm">${seconds} Segundos</span>`);
    }

    return specs.slice(0, 2);
  }

  render() {
    return html`
      <ion-content class="no-px" fullscreen>
        <ion-header>
          <ion-toolbar style="--padding-start:16px; --padding-end:16px">
            <ion-buttons slot="start">
              <ion-button
                @click=${() => this.navigator.close()}
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <!-- -------------- CONTENT --------------- -->

        <div class="space-y-2.5 px-4 pb-5">
          <div class="space-x-0.5">
            <h5>${this.session.name}</h5>
            <div class="flex items-center space-x-1 font-medium">
              <span class="capitalize"
                >${this.getDayMonth(this.session.day)}, ${this.getTime(this.session.startTime)} -
                ${this.getTime(this.session.endTime)}</span
              >
              <span class="text-accent-6">•</span>
              ${this.getDateChip(this.session.day, this.session.startTime, this.session.endTime)}
            </div>
          </div>
          <p>${this.session.notes}</p>
        </div>

        <div class="w-full h-1 bg-accent-7 mb-4"></div>

        <div class="space-y-2.5 px-4">
          <span class="font-display font-semibold text-sm">Plano de Exercícios</span>
          ${when(
            this.session.exercisesPlan.length > 0,
            () => html`
              <!-- -------------------- EXERCISES PLAN ------------------- -->
              <ion-list lines="none" class="pb-1 space-y-2.5">
                ${repeat(
                  this._sortedExercises,
                  (ep) => ep.id,
                  (ep) => {
                    const exercise = ep.exercise || ep.exercisePublic;
                    let thumbnail = "";
                    if (exercise?.thumbnail) {
                      thumbnail = pb.files.getUrl(exercise, exercise.thumbnail, {
                        thumb: "128x128",
                      });
                    }
                    return html`
                      <ion-item
                        @click=${() => this.navigator.push("modal-public-exercise-plan", { exercisePlan: ep })}
                        button
                        detail-icon="/assets/icons/chevron-right.svg"
                        class="no-p no-inner-p space-x-2 items-center w-full">
                        <div class="flex space-x-2 items-center">
                          <xt-avatar
                            style="--size: 52px; --border-width: 6px;"
                            src=${thumbnail}
                            name=${exercise?.name}></xt-avatar>
                          <div class="col max-w-[calc(100%-60px)]">
                            <p class="font-semibold line-clamp-1">${exercise?.name}</p>
                            <div class="flex items-center space-x-[3px]">
                              ${this.getSpecs(ep).map((s) => {
                                return html`${s}
                                  <div class=" w-1 h-1 rounded-full bg-accent-6 last:hidden"></div>`;
                              })}
                            </div>
                          </div>
                        </div>
                        <ion-reorder slot="end">
                          <ion-icon icon="/assets/icons/reorder.svg" class="w-6 h-6 text-accent-1"></ion-icon>
                        </ion-reorder>
                      </ion-item>
                    `;
                  },
                )}
              </ion-list>
            `,
            () => html`
              <div class="h-[139px] w-full bg-accent-7 rounded-md col justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                    stroke="#111111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <span class="font-display font-semibold w-2/3 text-center">Não existem exercícios de momento</span>
              </div>
            `,
          )}
        </div>

        <div class="w-full h-1 bg-accent-7 my-4"></div>

        <!-- ------------------------ EVALUATIONS -------------------------- -->

        <div class="space-y-1.5 px-4">
          <span class="font-display font-semibold text-sm">Avaliações</span>
          ${when(
            this.session.evaluations.length > 0,
            () => html`
              <ion-list lines="none" class="space-y-2.5">
                ${repeat(
                  this.session.evaluations,
                  (evaluation) => evaluation.id,
                  (evaluation) => {
                    return html`
                      <ion-item
                        button
                        detail-icon="/assets/icons/chevron-right.svg"
                        class="no-p no-inner-p space-x-2 items-center w-full"
                        @click=${() => this.navigator.push("modal-public-evaluation", { evaluation: evaluation })}>
                        <div class="w-full">
                          <p class="font-semibold">${evaluation.evaluation.name}</p>

                          ${evaluation.evaluation.categories.slice(0, 2).map(
                            (category) => html`
                              <span class="text-sm">${category.category}</span>
                              <span class="text-sm last:hidden"> & </span>
                            `,
                          )}
                        </div>
                      </ion-item>
                    `;
                  },
                )}
              </ion-list>
            `,
            () => {
              return html`
                <div class="h-[139px] w-full bg-accent-7 rounded-md col justify-center items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                      stroke="#111111"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <span class="font-display font-semibold w-2/3 text-center">Não existem avaliações de momento</span>
                </div>
              `;
            },
          )}
        </div>

        <div class="w-full h-1 bg-accent-7 my-4"></div>

        <div class="space-y-1.5 px-4">
          <span class="font-display font-semibold text-sm">Observação</span>
          <ion-item>
            <ion-textarea
              debounce=${2000}
              @ionInput=${(e: CustomEvent) => {
                this.editSessionNotes(e.detail.value as string);
              }}
              auto-grow
              rows="4"
              value=${this.session.patientNotes}
              placeholder="Anote qualquer observação que tenha"></ion-textarea>
          </ion-item>
        </div>
      </ion-content>
    `;
  }
}
