import { html, nothing } from "lit";
import { customElement } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { appointmentStore } from "../stores/appointment.store";
import { diagnosticsStore } from "../stores/diagnostics.store";
import { librariesStore } from "../stores/libraries.store";
import { groupStore } from "../stores/groups.store";
import { notificationsStore } from "../stores/notifications.store";
import { patientsStore } from "../stores/patients.store";
import { templatesStore } from "../stores/templateExercisePlan.store";
import { reportsStore } from "../stores/reports.store";
import { evaluationsStore } from "../stores/evaluation.store";
import { scalesStore } from "../stores/scale.store";

@customElement("page-settings")
export class PageSettings extends Page {
  @consume({ context: authContext })
  auth!: AuthContext;

  @consume({ context: routerContext }) router!: RouterContext;

  connectedCallback(): void {
    super.connectedCallback();
    document.addEventListener("ionModalWillDismiss", () => {
      this.requestUpdate();
    });
  }

  #modalPrivacyPolicyRef: Ref<ModalNav> = createRef();
  #modalProfileRef: Ref<ModalNav> = createRef();
  #modalTermsAndConditionsRef: Ref<ModalNav> = createRef();

  logout() {
    this.auth.logout();
    appointmentStore.clearStore();
    diagnosticsStore.clearStore();
    librariesStore.clearStore();
    groupStore.clearStore();
    notificationsStore.clearStore();
    patientsStore.clearStore();
    templatesStore.clearStore();
    reportsStore.clearStore();
    evaluationsStore.clearStore();
    scalesStore.clearStore();
    this.router.push("/login");
  }

  render() {
    return html`
      <ion-content fullscreen class="no-px">
        ${this.auth.user
          ? html`
              <div class="flex justify-between items-center px-4 mt-4">
                <h3 class="w-1/2">${this.auth.user.name}</h3>
                <div class="relative w-[72px] h-[72px] flex">
                  <div class="w-[72px] h-[72px] rounded-full bg-accent-7 inset-abs-center -ml-[36px] -mt-[36px]"></div>
                  <xt-avatar
                    class="z-10 mx-auto"
                    src=${this.auth.user.avatar}
                    name=${this.auth.user.name}
                    style="--size: 70px; --border-radius: 99px;"></xt-avatar>
                </div>
              </div>
              <div class="bg-accent-7 w-full h-1 mt-6 mb-2"></div>
              <ion-list class="px-4">
                <ion-item
                  button
                  @click=${() => this.#modalProfileRef.value?.open({ user: this.auth.user })}
                  class="no-p-start no-inner-p items-center"
                  style="--inner-padding-bottom: 20px; --inner-padding-top: 20px">
                  <div class="flex items-center gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M19 21C19 17.134 15.866 14 12 14C8.13401 14 5 17.134 5 21M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span class="font-medium">Perfil</span>
                  </div>
                </ion-item>
                <ion-item
                  @click=${() => this.#modalTermsAndConditionsRef.value?.open()}
                  button
                  class="no-p-start no-inner-p items-center"
                  style="--inner-padding-bottom: 20px; --inner-padding-top: 20px">
                  <div class="flex items-center gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M9 17H15M9 14H15M13.0004 3.00087C12.9048 3 12.7974 3 12.6747 3H8.2002C7.08009 3 6.51962 3 6.0918 3.21799C5.71547 3.40973 5.40973 3.71547 5.21799 4.0918C5 4.51962 5 5.08009 5 6.2002V17.8002C5 18.9203 5 19.4801 5.21799 19.9079C5.40973 20.2842 5.71547 20.5905 6.0918 20.7822C6.51921 21 7.079 21 8.19694 21L15.8031 21C16.921 21 17.48 21 17.9074 20.7822C18.2837 20.5905 18.5905 20.2842 18.7822 19.9079C19 19.4805 19 18.9215 19 17.8036V9.32568C19 9.20302 18.9999 9.09553 18.999 9M13.0004 3.00087C13.2858 3.00348 13.4657 3.01407 13.6382 3.05547C13.8423 3.10446 14.0379 3.18526 14.2168 3.29492C14.4186 3.41857 14.5918 3.59181 14.9375 3.9375L18.063 7.06298C18.4089 7.40889 18.5809 7.58136 18.7046 7.78319C18.8142 7.96214 18.8953 8.15726 18.9443 8.36133C18.9857 8.53379 18.9964 8.71454 18.999 9M13.0004 3.00087L13 5.80021C13 6.92031 13 7.48015 13.218 7.90797C13.4097 8.2843 13.7155 8.59048 14.0918 8.78223C14.5192 9 15.079 9 16.1969 9H18.999"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span class="font-medium">Termos e Condições</span>
                  </div>
                </ion-item>
                <ion-item
                  @click=${() => this.#modalPrivacyPolicyRef.value?.open()}
                  button
                  class="no-p-start no-inner-p items-center"
                  style="--inner-padding-bottom: 20px; --inner-padding-top: 20px">
                  <div class="flex items-center gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M9.23047 9H7.2002C6.08009 9 5.51962 9 5.0918 9.21799C4.71547 9.40973 4.40973 9.71547 4.21799 10.0918C4 10.5196 4 11.0801 4 12.2002V17.8002C4 18.9203 4 19.4801 4.21799 19.9079C4.40973 20.2842 4.71547 20.5905 5.0918 20.7822C5.5192 21 6.07902 21 7.19694 21H16.8031C17.921 21 18.48 21 18.9074 20.7822C19.2837 20.5905 19.5905 20.2842 19.7822 19.9079C20 19.4805 20 18.9215 20 17.8036V12.1969C20 11.079 20 10.5192 19.7822 10.0918C19.5905 9.71547 19.2837 9.40973 18.9074 9.21799C18.4796 9 17.9203 9 16.8002 9H14.7689M9.23047 9H14.7689M9.23047 9C9.10302 9 9 8.89668 9 8.76923V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V8.76923C15 8.89668 14.8964 9 14.7689 9"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span class="font-medium">Política de Privacidade</span>
                  </div>
                </ion-item>
                <ion-item
                  @click=${() => this.logout()}
                  button
                  .detail=${false}
                  class="no-p-start no-inner-p items-center"
                  style="--inner-padding-bottom: 20px; --inner-padding-top: 20px">
                  <div class="flex items-center gap-x-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M12 15L15 12M15 12L12 9M15 12H4M9 7.24859V7.2002C9 6.08009 9 5.51962 9.21799 5.0918C9.40973 4.71547 9.71547 4.40973 10.0918 4.21799C10.5196 4 11.0801 4 12.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H12.1969C11.079 20 10.5192 20 10.0918 19.7822C9.71547 19.5905 9.40973 19.2839 9.21799 18.9076C9 18.4798 9 17.9201 9 16.8V16.75"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span class="font-medium">Terminar Sessão</span>
                  </div>
                </ion-item>
              </ion-list>
            `
          : nothing}
      </ion-content>

      <modal-nav root="modal-privacy-policy" ${ref(this.#modalPrivacyPolicyRef)}></modal-nav>
      <modal-nav root="modal-profile" ${ref(this.#modalProfileRef)}></modal-nav>
      <modal-nav root="modal-terms-and-conditions" ${ref(this.#modalTermsAndConditionsRef)}></modal-nav>
    `;
  }
}
