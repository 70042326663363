import { customElement, state } from "lit/decorators.js";
import { html } from "lit";
import { type ItemReorderCustomEvent, alertController } from "@ionic/core";
import { groupStore, type ObservableGroup } from "../stores/groups.store";
import { consume } from "@lit/context";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { authContext, type AuthContext } from "../context/auth.context";
import { choose } from "lit/directives/choose.js";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { ERROR_NOT_UNIQUE } from "../pocketbase";
import { ClientResponseError } from "pocketbase";
import { when } from "lit/directives/when.js";
import { Page, required } from "../components/component";
import { RouterContext, routerContext } from "../context/router.context";
import { UserShareStore } from "../stores/userShare.store";
import type { UpdateSectionWithId } from "../repository/group/section";

const formSchema = z.object({
  name: z
    .string({
      required_error: "O nome é obrigatório",
    })
    .min(3, "O nome deve ter pelo menos 3 caracteres")
    .max(50, "O nome deve ter no máximo 50 caracteres"),
});

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-edit-group")
export class ModalEditGroup extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: routerContext }) router!: RouterContext;

  @state() sections: UpdateSectionWithId[] = [];
  @required() group!: ObservableGroup;

  shareStore = new UserShareStore();

  connectedCallback(): void {
    super.connectedCallback();

    this.shareStore.set(
      this.group.sharedPersonal.map((share) => ({
        user: share.user,
        access: share.access,
        action: "update",
      })),
    );

    this.sections = this.group.sortedSections.map((section) => ({
      id: section.id,
      name: section.name,
      position: section.position,
    }));
  }

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      try {
        await groupStore.updateGroup(this.group, {
          name: values.name,
        });
        await groupStore.shareWithUsers(this.group, this.shareStore.users);
        await groupStore.updateSections(this.group, this.sections);

        this.navigator.goBack();
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.response.data?.name) {
            if (error.response.data.name.code === ERROR_NOT_UNIQUE) {
              this.#controller.setError("name", "O nome do grupo já existe");
            } else {
              this.#controller.setError("name", "O nome do grupo é inválido");
            }
          }
        }

        const alert = await alertController.create({
          header: "Erro",
          message: "Ocorreu um erro ao editar o grupo",
          buttons: ["OK"],
        });

        await alert.present();
      }
    },
  });

  onReorder = async (e: Event) => {
    const detail = (e as ItemReorderCustomEvent).detail;

    const fromIdx = detail.from;
    const toIdx = detail.to;

    if (fromIdx === toIdx) {
      detail.complete();
      return;
    }

    this.sections = this.sections.map((section, idx) => {
      if (idx === fromIdx) {
        return { ...section, position: toIdx };
      } else if (fromIdx < toIdx) {
        if (idx > fromIdx && idx <= toIdx) {
          return { ...section, position: idx - 1 };
        }
      } else {
        if (idx < fromIdx && idx >= toIdx) {
          return { ...section, position: idx + 1 };
        }
      }
      return section;
    });

    detail.complete();
  };

  async #arquiveGroup() {
    const alert = await alertController.create({
      header: "Arquivar Grupo",
      message: "Tem a certeza que deseja arquivar este grupo?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Arquivar",
          role: "destructive",
          handler: async () => {
            await groupStore.updateGroup(this.group, { isArchived: true });
            await this.navigator.goBack();
            const backUrl = this.router.backUrl;
            if (backUrl === "/groups") this.router.back();
          },
        },
      ],
    });

    await alert.present();
  }

  render() {
    const { form, register, renderError, submit } = this.#controller;
    const formState = form.getState();

    return html`
      <!-- HEADER -->

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div>
              <ion-button
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold"
                @click=${this.navigator.goBack}>
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${this.group?.name}</ion-title>
          <ion-buttons slot="end">
            ${when(
              this.group.isOwner(this.auth.user),
              () => html`
                <ion-button color="danger" class="font-semibold text-sm" @click=${this.#arquiveGroup}>
                  <span class="mr-1 mt-px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  Arquivar
                </ion-button>
              `,
            )}
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <!-- HEADER -->

      <ion-content fullscreen class="space-y-6">
        <form id="form-group-edit" @submit=${submit} class="mt-6">
          <div>
            <span class="font-semibold font-display">Nome</span>
            <ion-item class="mt-1">
              <ion-input
                autocapitalize="on"
                type="text"
                ?disabled=${!this.group.isOwner(this.auth.user)}
                placeholder="Nome do grupo"
                @input=${() => {}}
                ${register("name", {
                  initialValue: this.group.name,
                })}>
              </ion-input>
            </ion-item>
            ${renderError("name")}
          </div>

          <div class="mt-6">
            <span class="font-semibold font-display">Profissionais</span>
            <ion-list lines="none" class="space-y-2.5 mt-2 flex flex-col">
              <ion-item class="no-p">
                <div class="flex items-center space-x-2">
                  <xt-avatar src=${this.group.owner.avatar} name=${this.group.owner.name}></xt-avatar>
                  <div class="col items-start">
                    <p class="font-semibold">${this.group.owner.name}</p>
                    <span class="text-danger">Criador</span>
                  </div>
                </div>
              </ion-item>
              ${this.shareStore.availableUsers?.map(
                (share) => html`
                  <ion-item
                    class="no-p"
                    @click=${() => {
                      if (this.group.isOwner(this.auth.user))
                        this.navigator.push("modal-share-edit", {
                          share: share,
                          shareStore: this.shareStore,
                        });
                    }}>
                    <div class="flex items-center space-x-2">
                      <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                      <div class="col items-start">
                        <p class="font-semibold">${share.user.name}</p>
                        ${choose(share.access.role, [
                          ["editor", () => html` <span class="text-warning">Editor</span> `],
                          ["viewer", () => html` <span class="text-accent-2">Visualizador</span> `],
                        ])}
                      </div>
                    </div>
                  </ion-item>
                `,
              )}
            </ion-list>
          </div>
          ${when(
            this.group.isOwner(this.auth.user),
            () => html`
              <div class="mt-3">
                <ion-button
                  size="small"
                  color="secondary"
                  expand="block"
                  shape="round"
                  @click=${() => {
                    this.navigator.push("modal-share-add", {
                      shareStore: this.shareStore,
                    });
                  }}
                  >Adicionar Profissional
                  <span class="mt-0.5 ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                      <path
                        d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                        stroke="currentColor"
                        !
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                </ion-button>
              </div>
            `,
          )}
        </form>

        <div class="space-y-1.5">
          <span class="font-semibold font-display">Secções</span>
          <ion-list>
            <ion-reorder-group .disabled=${!this.group.isOwner(this.auth.user)} @ionItemReorder=${this.onReorder}>
              ${when(
                this.sections.length === 0,
                () => {
                  return html`
                    <ion-button
                      color="secondary"
                      class="h-36 w-full whitespace-normal"
                      style="--border-radius: 6px;"
                    >
                      <span class="col space-y-1 items-center justify-center">
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <span class="text-sm font-semibold font-display text-center"
                        style="max-inline-size: 20ch;"
                          >
                          Este grupo ainda não tem secções criadas
                          </span>
                    </ion-button>
                  `;
                },
                () =>
                  this.sections.map(
                    (section) => html`
                      <ion-item class="no-p no-inner-p text-accent-5">
                        <ion-label class="my-3">${section.name}</ion-label>
                        <ion-reorder slot="end">
                          <ion-icon icon="/assets/icons/reorder.svg" class="w-6 h-6 text-accent-1"></ion-icon>
                        </ion-reorder>
                      </ion-item>
                    `,
                  ),
              )}
            </ion-reorder-group>
          </ion-list>
        </div>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          ${when(
            this.group.isOwner(this.auth.user),
            () => html`
              <ion-button
                form="form-group-edit"
                type="submit"
                color="primary"
                expand="block"
                shape="round"
                class="font-semibold"
                ?disabled=${formState.submitting}
                >Guardar Alterações</ion-button
              >
            `,
          )}
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
