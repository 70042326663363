import { customElement } from "lit/decorators.js";
import { html, nothing } from "lit";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { ClientResponseError } from "pocketbase";
import { alertController, toastController } from "@ionic/core";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { Task } from "@lit/task";
import { choose } from "lit/directives/choose.js";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Page, required } from "../components/component";
import { z } from "zod";
import { reportsStore, type ObservableReport } from "../stores/reports.store";
import { UserShareStore } from "../stores/userShare.store";
import { groupStore } from "../stores/groups.store";
import { UpdateReportSchema } from "../repository/report/report";
import { when } from "lit/directives/when.js";
import { ModalShareAdd } from "./modal-share-add";
import { ModalShareEdit } from "./modal-share-edit";
import { map } from "lit/directives/map.js";

const formSchema = UpdateReportSchema.extend({
  group: z.string().optional(),
});
type FormValues = z.infer<typeof formSchema>;

@customElement("modal-edit-report-library")
export class ModalEditReportLibrary extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: authContext }) private auth!: AuthContext;

  @required() report!: ObservableReport;

  userShareStore = new UserShareStore();

  connectedCallback(): void {
    super.connectedCallback();
    this.userShareStore.set(
      this.report.sharedPersonal.map((share) => ({
        user: share.user,
        access: share.access,
        action: "update",
      })),
    );
  }

  groups = new Task(this, {
    task: async () => {
      if (this.report.isSharedFromGroup() && this.report.isOwner(this.auth.user)) {
        return groupStore.loadGroupsByOwner(this.auth.user.id);
      }
      return [];
    },
    args: () => [],
  });

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      try {
        await reportsStore.updateReport(this.report, {
          name: values.name,
        });

        if (this.report.isSharedFromGroup()) {
          if (values.group) await reportsStore.shareWithGroup(this.report, values.group);
        } else {
          await reportsStore.shareWithUsers(this.report, this.userShareStore.users);
        }
      } catch (error) {
        let alert = await alertController.create({
          header: "Erro",
          message: "Ocorreu um erro ao editar a biblioteca. Por favor, tente novamente mais tarde.",
          buttons: ["OK"],
        });

        if (error instanceof ClientResponseError) {
          if (error.status === 403) {
            alert = await alertController.create({
              header: "Erro",
              message: "Não tem permissões suficientes para editar esta biblioteca.",
              buttons: ["OK"],
            });
            await alert.present();
          } else if (error.status === 404) {
            alert = await alertController.create({
              header: "Erro",
              message: "A biblioteca não foi encontrada. Por favor, tente novamente mais tarde.",
              buttons: ["OK"],
            });
            await alert.present();
          } else {
            await alert.present();
          }
        } else {
          await alert.present();
        }
      } finally {
        this.navigator.close();
      }
    },
  });

  async deleteReport() {
    const alert = await alertController.create({
      header: "Apagar biblioteca",
      message: "Tem a certeza que deseja apagar esta biblioteca? A ação não é reversível.",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Apagar",
          role: "destructive",
          handler: async () => {
            await reportsStore.deleteReport(this.report).then(async () => {
              const toast = await toastController.create({
                message: "Biblioteca apagada com sucesso",
                color: "primary",
                icon: "/assets/icons/info.svg",
                duration: 2000,
              });
              await toast.present();
            });
            this.navigator.close();
          },
        },
      ],
    });
    await alert.present();
  }

  render() {
    const { form, submit, register, renderError } = this.#controller;
    const formState = form.getState();

    const currentUser = this.auth.user;

    return html`
      <!-- HEADER -->
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                @click=${this.navigator.goBack}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                Cancelar
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title>${this.report.name}</ion-title>
          ${when(
            !(
              (this.report.isSharedFromGroup() && !this.report.isOwner(currentUser)) ||
              this.report.isShared(currentUser)
            ),
            () => html`
              <ion-buttons slot="end">
                <div class="flex items-center">
                  <ion-button
                    @click=${() => this.deleteReport()}
                    color="danger"
                    style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                    fill="clear"
                    class="font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="mr-1">
                      <path
                        d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    Apagar
                  </ion-button>
                </div>
              </ion-buttons>
            `,
          )}
        </ion-toolbar>
      </ion-header>

      <ion-content fullscreen>
        <form class="mt-4" id="form-library-edit" @submit=${submit}>
          <div>
            <span class="font-semibold font-display">Nome</span>
            <ion-item class="has-input mt-1">
              <ion-input
                autocapitalize="on"
                ?disabled=${!this.report.isOwner(currentUser)}
                type="text"
                placeholder="Nome da Biblioteca"
                ${register("name", { initialValue: this.report.name })}></ion-input>
            </ion-item>
            ${renderError("name")}
          </div>

          ${this.report.isSharedFromGroup() && !this.report.isOwner(currentUser)
            ? html`
                <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                  <div
                    class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                    <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                      <span class="font-medium"> Esta biblioteca é partilhada pelo grupo </span>"${this.report
                        .sharedGroup?.group.name}"
                    </span>
                  </div>
                </ion-item>
              `
            : this.report.isSharedFromGroup() && this.report.isOwner(currentUser)
            ? this.groups.render({
                complete: (groups) => html`
                  <div class="mt-6 space-y-2">
                    <span class="font-semibold font-display">Grupos</span>
                    <ion-list>
                      <ion-radio-group
                        allow-empty-selection
                        ${register("group", {
                          initialValue: this.report.sharedGroup?.group.id,
                        })}
                        value=${this.report.sharedGroup?.group.id}>
                        ${map(groups, (group) => {
                          if (group.isOwner(this.auth.user)) {
                            return html`
                              <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                                <ion-radio value=${group.id} class="no-m-inline my-2">${group.name}</ion-radio>
                              </ion-item>
                            `;
                          }
                          return nothing;
                        })}
                      </ion-radio-group>
                    </ion-list>
                    ${renderError("group")}
                  </div>
                `,
              })
            : html`
                <div class="mt-6 space-y-2">
                  <span class="font-semibold font-display">Profissionais</span>
                  <ion-list class="space-y-2.5 no-p" lines="none">
                    <ion-item class="no-p">
                      <div class="flex items-center space-x-2">
                        <xt-avatar src=${this.report.owner.avatar} name=${this.report.owner.name}></xt-avatar>
                        <div class="col items-start">
                          <p class="font-semibold">${this.report.owner.name}</p>
                          <span class="text-danger">Criador</span>
                        </div>
                      </div>
                    </ion-item>

                    ${this.userShareStore.availableUsers?.map(
                      (share) => html`
                        <ion-item
                          class="no-p"
                          @click=${() => {
                            if (this.report.isOwner(currentUser))
                              this.navigator.push(ModalShareEdit, {
                                share: share,
                                shareStore: this.userShareStore,
                              });
                          }}>
                          <div class="flex items-center space-x-2">
                            <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                            <div class="col items-start">
                              <p class="font-semibold">${share.user.name}</p>
                              ${choose(share.access.role, [
                                ["editor", () => html` <span class="text-warning">Editor</span> `],
                                ["viewer", () => html` <span class="text-accent-2">Visualizador</span> `],
                              ])}
                            </div>
                          </div>
                        </ion-item>
                      `,
                    )}
                  </ion-list>
                </div>
                ${when(
                  this.report.isOwner(currentUser),
                  () => html`
                    <ion-button
                      class="mt-3"
                      size="small"
                      color="secondary"
                      expand="block"
                      shape="round"
                      @click=${() =>
                        this.navigator.push(ModalShareAdd, {
                          shareStore: this.userShareStore,
                        })}
                      >Adicionar Profissional
                      <span class="mt-0.5 ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path
                            d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </span>
                    </ion-button>
                  `,
                )}
              `}
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          ${when(
            this.report.isOwner(currentUser),
            () => html`
              <ion-button
                form="form-library-edit"
                type="submit"
                expand="block"
                shape="round"
                ?disabled=${formState.submitting}>
                Guardar Alterações
              </ion-button>
            `,
          )}
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
