import { html } from "lit";
import { customElement } from "lit/decorators.js";
import {
  FinalFormController,
  zodValidator,
} from "../controllers/final-form.controller";
import { z } from "zod";
import { Page } from "../components/component";

const formSchema = z.object({
  email: z
    .string({
      required_error: "O email é obrigatório",
    })
    .email("Email inválido"),
});

type FormValues = z.infer<typeof formSchema>;

@customElement("page-recover-password")
export class PageRecoverPassword extends Page {
  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async () => {
      try {
        // TODO: When email service is ready, finish this
        //
        // const data = await pb
        //   .collection("users")
        //   .requestPasswordReset(values.email);
        this.#controller.setError("email", "Funcionalidade não implementada");
      } catch (error) {
        // if (error instanceof ClientResponseError) {
        //   if (error.status === 400) {
        //     this.#controller.setError("email", "Email não encontrado");
        //   }
        // }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div>
                <ion-router-link href="/login" router-direction="back">
                  <ion-button
                    style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                    fill="clear"
                    class="font-semibold"
                  >
                    <span class="mt-1 -ml-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M14 16L10 12L14 8"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    voltar
                  </ion-button>
                </ion-router-link>
              </div>
            </ion-buttons>
            <ion-title class="mt-0.5 font-display text-lg"
              >Recuperar Conta</ion-title
            >
          </ion-toolbar>
        </ion-header>
        <div class="mt-4">
          <form
            id="form-recover"
            @submit=${(e: Event) => {
              e.preventDefault();
              form.submit();
            }}
          >
            <ion-list lines="none" class="space-y-4 flex flex-col">
              <div>
                <span class="text-sm font-semibold font-display">Email</span>
                <ion-item class="mt-1">
                  <ion-input
                    id="email"
                    type="email"
                    placeholder="Email"
                    ${register("email")}
                  ></ion-input>
                </ion-item>
                ${renderError("email")}
              </div>
              <p class="text-accent-4 text-sm">
                <strong class="text-black">Nota:</strong> ao inserires o e-mail
                da tua conta perdida irás receber um e-mail na caixa de entrada
                (ou spam), para criar uma nova palavra-passe
              </p>
            </ion-list>
          </form>
        </div>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-recover"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Recuperar Conta</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
