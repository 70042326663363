import { customElement, state } from "lit/decorators.js";
import { Page } from "../components/component";
import { Task } from "@lit/task";
import { html } from "lit";
import { consume } from "@lit/context";
import { RouterContext, routerContext } from "../context/router.context";
import { authContext, type AuthContext } from "../context/auth.context";
import { choose } from "lit/directives/choose.js";
import {
  Attachment,
  NumericBox,
  ResultInteger,
  ResultPercentage,
  Scale,
  TextBox,
  Url,
  YesOrNo,
} from "../components/evaluation";
import {
  EvaluationFieldType,
  type CreateValueAttachment,
  type CreateValueNumericBox,
  type CreateValueResultInteger,
  type CreateValueResultPercentage,
  type CreateValueTextBox,
  type CreateValueUrl,
  type CreateValueYesOrNo,
  type FieldValue,
  type FieldValueAttachment,
  type FieldValueNumericBox,
  type FieldValueResultInteger,
  type FieldValueResultPercentage,
  type FieldValueScale,
  type FieldValueTextBox,
  type FieldValueUrl,
  type FieldValueYesOrNo,
  type UpdateValueAttachment,
  type UpdateValueNumericBox,
  type UpdateValueResultInteger,
  type UpdateValueResultPercentage,
  type UpdateValueTextBox,
  type UpdateValueUrl,
  type UpdateValueYesOrNo,
  type ValueAttachment,
  type ValueNumericBox,
  type ValueResultInteger,
  type ValueResultPercentage,
  type ValueScale,
  type ValueTextBox,
  type ValueUrl,
  type ValueYesOrNo,
} from "../repository/report/report";
import { when } from "lit/directives/when.js";
import { ObservableGroup, groupStore } from "../stores/groups.store";
import { reportRepository } from "../repository/report/report.repository";
import { alertController, isPlatform, toastController } from "@ionic/core";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { ObservablePatientEvaluation, ObservableSession, sessionsStore } from "../stores/sessions.store";
import type { Content } from "../repository/patient/diagnostic";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { IonActionSheet } from "@ionic/core/components/ion-action-sheet";

interface NewContent {
  file: File;
  content: string;
}

@customElement("modal-evaluation")
export class ModalEvaluation extends Page {
  evaluation!: ObservablePatientEvaluation;
  session!: ObservableSession;
  group?: ObservableGroup;
  @state() private _groupId: string = "";
  @state() private _currentFieldId: string | null = null;
  @state() private _fields = new Map<string, FieldValue>();
  @state() private _currentScores = new Map<string, number>();
  @state() private _attachmentContent = new Map<string, NewContent[]>();

  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: authContext }) auth!: AuthContext;

  #contentActionSheet: Ref<IonActionSheet> = createRef();

  connectedCallback() {
    super.connectedCallback();
    try {
      this._groupId = this.router.getParam("groupId");
    } catch {}
  }

  async firstUpdated(_changedProperties: Map<string, any>) {
    super.firstUpdated(_changedProperties);

    const contentInputPhoto = this.renderRoot.querySelector("#content-file-photo") as HTMLInputElement;
    const contentInputVideo = this.renderRoot.querySelector("#content-file-video") as HTMLInputElement;
    const contentInputGeneral = this.renderRoot.querySelector("#content-file-general") as HTMLInputElement;
    [contentInputPhoto, contentInputVideo, contentInputGeneral].forEach((input) => {
      input.addEventListener("change", (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                if (e.target) {
                  const newContent = {
                    file: file,
                    content: e.target.result as string,
                  };
                  if (this._currentFieldId) {
                    const field = this._fields.get(this._currentFieldId);
                    if (field) this.addContent(field as FieldValueAttachment, newContent);
                  }
                }
              };
              reader.readAsDataURL(file);
            }
          }
        }
      });
    });
  }

  #evaluation = new Task(this, {
    task: async () => {
      return reportRepository.loadEvaluationWithValues(this.evaluation.id);
    },
    onComplete: (evaluation) => {
      evaluation.sections.forEach((section) => {
        section.fields.forEach((field) => {
          this._fields.set(field.id, field);
          if (field.collectionName === EvaluationFieldType.Attachment && field.value?.content) {
            this.transformContent(field.value.content).then((content) => {
              this._attachmentContent.set(field.id, content);
              this.requestUpdate();
            });
          } else if (field.collectionName === EvaluationFieldType.ResultInteger) {
            this._currentScores.set(field.id, this.calculateInteger(field));
          } else if (field.collectionName === EvaluationFieldType.ResultPercentage) {
            this._currentScores.set(field.id, this.calculatePercentage(field));
          }
        });
      });
    },
    args: () => [],
  });

  async deleteEvaluation() {
    const alert = await alertController.create({
      header: "Apagar Avaliação",
      message: "Tem certeza que deseja apagar esta avaliação?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Apagar",
          role: "destructive",
          handler: async () => {
            sessionsStore.deletePatientEvaluation(this.session, this.evaluation);
            this.navigator.close();
          },
        },
      ],
    });
    await alert.present();
  }

  async presentToast(message: string) {
    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: message,
      duration: 2000,
    });
    await toast.present();
  }

  async updateNumericBox(e: any, field: FieldValueNumericBox) {
    const newValue = e.target.value;
    const cachedField = this._fields.get(field.id) as FieldValueNumericBox;
    if (!field.value && !cachedField?.value) {
      try {
        const data: CreateValueNumericBox = {
          value: newValue,
          patient_evaluation: this.evaluation.id,
          field: field.id,
        };
        const value = await reportRepository.createValueNumericBox(data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(field);
      try {
        const data: UpdateValueNumericBox = {
          value: newValue,
        };
        const value = await reportRepository.updateValueNumericBox(cachedField!.value!.id, data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async updateResultInteger(e: any, field: FieldValueResultInteger) {
    const newValue = e.target.value;
    const cachedField = this._fields.get(field.id) as FieldValueResultInteger;
    if (!field.value && !cachedField?.value) {
      try {
        const data: CreateValueResultInteger = {
          value: newValue,
          patient_evaluation: this.evaluation.id,
          field: field.id,
        };
        const value = await reportRepository.createValueResultInteger(data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this._currentScores.set(field.id, this.calculateInteger(field, newValue));
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const data: UpdateValueResultInteger = {
          value: newValue,
        };
        const value = await reportRepository.updateValueResultInteger(cachedField!.value!.id, data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this._currentScores.set(field.id, this.calculateInteger(field, newValue));
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async updateResultPercentage(e: any, field: FieldValueResultPercentage) {
    const newValue = e.target.value;
    const cachedField = this._fields.get(field.id) as FieldValueResultPercentage;
    if (!field.value && !cachedField?.value) {
      try {
        const data: CreateValueResultPercentage = {
          value: newValue,
          patient_evaluation: this.evaluation.id,
          field: field.id,
        };
        const value = await reportRepository.createValueResultPercentage(data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this._currentScores.set(field.id, this.calculatePercentage(field, newValue));
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const data: UpdateValueResultPercentage = {
          value: newValue,
        };
        const value = await reportRepository.updateValueResultPercentage(cachedField!.value!.id, data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this._currentScores.set(field.id, this.calculatePercentage(field, newValue));
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async updateTextBox(e: any, field: FieldValueTextBox) {
    const newValue = e.target.value;
    const cachedField = this._fields.get(field.id) as FieldValueTextBox;
    if (!field.value && !cachedField?.value) {
      try {
        const data: CreateValueTextBox = {
          value: newValue,
          patient_evaluation: this.evaluation.id,
          field: field.id,
        };
        const value = await reportRepository.createValueTextBox(data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const data: UpdateValueTextBox = {
          value: newValue,
        };
        const value = await reportRepository.updateValueTextBox(cachedField!.value!.id, data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async updateUrl(e: any, field: FieldValueUrl) {
    const newValue = e.target.value;
    const cachedField = this._fields.get(field.id) as FieldValueUrl;
    if (!field.value && !cachedField?.value) {
      try {
        const data: CreateValueUrl = {
          value: newValue,
          patient_evaluation: this.evaluation.id,
          field: field.id,
        };
        const value = await reportRepository.createValueUrl(data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const data: UpdateValueUrl = {
          value: newValue,
        };
        const value = await reportRepository.updateValueUrl(cachedField!.value!.id, data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async updateYesOrNo(e: any, field: FieldValueYesOrNo) {
    const newValue = e.target.value;
    const cachedField = this._fields.get(field.id) as FieldValueYesOrNo;
    if (!field.value && !cachedField?.value) {
      try {
        const data: CreateValueYesOrNo = {
          option1: newValue === "option1",
          option2: newValue === "option2",
          option3: newValue === "option3",
          option4: newValue === "option4",
          option5: newValue === "option5",
          patient_evaluation: this.evaluation.id,
          field: field.id,
        };
        const value = await reportRepository.createValueYesOrNo(data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const data: UpdateValueYesOrNo = {
          option1: newValue === "option1",
          option2: newValue === "option2",
          option3: newValue === "option3",
          option4: newValue === "option4",
          option5: newValue === "option5",
        };
        const value = await reportRepository.updateValueYesOrNo(cachedField!.value!.id, data);
        this.presentToast("Campo atualizado com sucesso");
        this._fields.set(field.id, { ...field, value: value });
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async transformContent(content: string[]) {
    let contentArray: NewContent[] = [];
    if (content) {
      for (const c of content) {
        const response = await fetch(c);
        const blob = await response.blob();
        const fileName = c.split("/").pop()?.split("?")[0] || "file";
        const file = new File([blob], fileName, { type: blob.type });
        contentArray.push({
          file: file as Content,
          content: URL.createObjectURL(file),
        });
      }
    }
    return contentArray;
  }

  async addContent(field: FieldValueAttachment, content: NewContent) {
    const cachedField = this._fields.get(field.id) as FieldValueAttachment;
    if (!field.value && !cachedField?.value) {
      const data: CreateValueAttachment = {
        content: content.file,
        patient_evaluation: this.evaluation.id,
        field: field.id,
      };
      const value = await reportRepository.createValueAttachment(data);
      this.presentToast("Campo atualizado com sucesso");
      const newContent = await this.transformContent(value.content);
      this._attachmentContent.set(field.id, newContent);
      this._fields.set(field.id, { ...field, value: value });
      this.requestUpdate();
    } else {
      try {
        const data: UpdateValueAttachment = {
          content: content.file,
        };
        const newValue = await reportRepository.updateValueAttachment(cachedField!.value!.id, data);
        this.presentToast("Campo atualizado com sucesso");
        const newContent = await this.transformContent(newValue.content);
        this._attachmentContent.set(field.id, newContent);
        this.requestUpdate();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async deleteContent(field: FieldValueAttachment, content: NewContent) {
    const cachedField = this._fields.get(field.id) as FieldValueAttachment;
    try {
      const data: UpdateValueAttachment = {
        "content-": content.file.name,
      };
      console.log(data);
      await reportRepository.updateValueAttachment(cachedField!.value!.id, data);
      const newContent = this._attachmentContent.get(field.id)?.filter((c) => c !== content);
      if (newContent) this._attachmentContent.set(field.id, newContent);
      this.requestUpdate();
    } catch (error) {
      console.log(error);
    }
  }

  onClickAttachmentAddButton(field: FieldValueAttachment) {
    this._currentFieldId = field.id;
    if (isPlatform("android")) {
      this.#contentActionSheet.value?.present();
    } else {
      const input = this.renderRoot.querySelector("#content-file-general") as HTMLInputElement;
      input.click();
    }
  }

  canEdit() {
    if (this.group) return true;
    const group = groupStore.find(this._groupId);
    if (!group) return false;
    return group.canEdit(this.auth.user);
  }

  calculatePercentage(field: FieldValueResultPercentage, newValue?: number) {
    const min = field.minimum;
    const max = field.maximum;
    const input = newValue ? newValue : field.value?.value || 0;
    const clampedInput = Math.max(min, Math.min(input, max));
    const percentage = ((clampedInput - min) / (max - min)) * 100;

    return Math.round(percentage * 100) / 100;
  }

  calculateInteger(field: FieldValueResultInteger, newValue?: number) {
    const min = field.minimum;
    const max = field.maximum;
    const input = newValue ? newValue : field.value?.value || 0;
    const clampedInput = Math.max(min, Math.min(input, max));
    const result = Math.round(((clampedInput - min) / (max - min)) * (max - min));

    return result + min;
  }

  computeScaleScore(field: FieldValueScale) {
    const cachedField = this._fields.get(field.id) as FieldValueScale;
    if (!field.value && !cachedField?.value) return 0;

    return this.computeExpression(field);
  }

  computeExpression(evaluationField: FieldValueScale): number {
    const { expression } = evaluationField.scale;
    const valuesMap = new Map<number, number>();

    if (!evaluationField.value) return 0;
    evaluationField.value.forEach((val) => {
      valuesMap.set(val.field.position, val.value.value);
    });

    let parsedExpression = expression;
    let missingValue = false;

    // Replace all occurrences of $N with the corresponding values
    parsedExpression = parsedExpression.replace(/\$[0-9]+/g, (match) => {
      const position = parseInt(match.slice(1));
      const value = valuesMap.get(position - 1);
      if (value === undefined) {
        console.warn(`No value found for position ${position}, returning 0 for the whole equation.`);
        missingValue = true;
        return "0";
      }
      return value.toString();
    });

    if (missingValue) {
      return 0;
    }

    try {
      // Evaluate the expression safely
      const result = new Function(`return ${parsedExpression}`)();
      if (typeof result !== "number") {
        throw new Error("Expression did not evaluate to a number");
      }
      return Math.round(result * 100) / 100;
    } catch (error) {
      console.error("Failed to evaluate expression:", error);
      return 0; // Return 0 in case of any evaluation errors
    }
  }

  openScaleModal(field: FieldValueScale, readonly: boolean) {
    this.navigator.push("modal-scale-values", {
      evaluation: this.evaluation,
      fieldValue: this._fields.get(field.id),
      readonly: readonly,
      onChange: (value: ValueScale) => {
        this.updateCachedScale(field.id, value);
      },
      calculateScore: () => this.computeScaleScore(field),
    });
  }

  updateCachedScale(id: string, value: ValueScale) {
    const cachedField = this._fields.get(id) as FieldValueScale;
    const cachedValue = cachedField.value?.find((v) => v.id === value.id);
    if (cachedValue) {
      cachedValue.value = value.value;
      this.requestUpdate();
    } else if (cachedField.value) {
      cachedField.value.push(value);
    } else {
      cachedField.value = [value];
    }
    this.requestUpdate();
  }

  render() {
    return html`
      <ion-content fullscreen style="--padding-bottom: 24px" class="no-px">
        <ion-header class="px-4">
          <ion-toolbar class=${this.#evaluation.value?.sections.length === 0 ? "no-border" : ""}>
            <!-- remove the class above 'no-border' when there are sections -->
            <ion-buttons slot="start">
              <ion-button
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold"
                @click=${() => this.navigator.close()}>
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </ion-buttons>
            <ion-title>${this.#evaluation.value?.name}</ion-title>
            ${when(
              this.canEdit(),
              () => html`
                <ion-buttons slot="end">
                  <div class="flex items-center">
                    <ion-button
                      @click=${() => this.deleteEvaluation()}
                      color="danger"
                      style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                      fill="clear"
                      class="font-semibold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="mr-1">
                        <path
                          d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      Apagar
                    </ion-button>
                  </div>
                </ion-buttons>
              `,
            )}
          </ion-toolbar>
        </ion-header>

        ${this.#evaluation.render({
          pending: () => {},
          error: (error) => {
            console.log(error);
            return html`<span class="text-danger">Erro ao carregar a avaliação</span>`;
          },
          complete: (evaluation) => {
            const sortedSections = evaluation.sections.sort((a, b) => a.position - b.position);
            return html`
              <ion-accordion-group class="mt-2.5 space-y-2.5" multiple>
                ${when(
                  evaluation.sections.length > 0,
                  () => html`
                    ${sortedSections.map(
                      (section) => html`
                        <ion-accordion
                          class="px-4"
                          value="${section.id}"
                          toggle-icon-slot="start"
                          toggle-icon="/assets/icons/chevron-down.svg">
                          <ion-item lines="none" class="no-active-bg no-border no-p-start no-inner-p" slot="header">
                            <div class="flex justify-between items-center w-full">
                              <div class="flex items-center space-x-1 w-full">
                                <h6>${section.label}</h6>
                                <ion-chip style="--background: #EAEAEA; --color: #888888"
                                  >${section.fields.length || 0} Campos</ion-chip
                                >
                              </div>
                            </div>
                          </ion-item>
                          ${when(
                            this.canEdit(),
                            () => html`
                              <div slot="content" class="my-1.5 space-y-3">
                                ${section.fields.map((field) => {
                                  return choose(field.collectionName, [
                                    [
                                      EvaluationFieldType.Attachment,
                                      () =>
                                        Attachment(field as FieldValueAttachment, {
                                          value: field.value as ValueAttachment,
                                          content: this._attachmentContent.get(field.id),
                                          readonly: false,
                                          onAdd: () => this.onClickAttachmentAddButton(field as FieldValueAttachment),
                                          onDelete: (content) =>
                                            this.deleteContent(field as FieldValueAttachment, content),
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.NumericBox,
                                      () =>
                                        NumericBox(field as FieldValueNumericBox, {
                                          onChange: (e) => this.updateNumericBox(e, field as FieldValueNumericBox),
                                          value: field.value as ValueNumericBox,
                                          readonly: false,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.ResultInteger,
                                      () =>
                                        ResultInteger(field as FieldValueResultInteger, {
                                          onChange: (e) =>
                                            this.updateResultInteger(e, field as FieldValueResultInteger),
                                          value: field.value as ValueResultInteger,
                                          score: this._currentScores.get(field.id),
                                          readonly: false,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.ResultPercentage,
                                      () =>
                                        ResultPercentage(field as FieldValueResultPercentage, {
                                          onChange: (e) =>
                                            this.updateResultPercentage(e, field as FieldValueResultPercentage),
                                          value: field.value as ValueResultPercentage,
                                          score: this._currentScores.get(field.id),
                                          readonly: false,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.Scale,
                                      () =>
                                        Scale(field as FieldValueScale, {
                                          readonly: false,
                                          onClick: () => this.openScaleModal(field as FieldValueScale, false),
                                          score: this.computeScaleScore(field as FieldValueScale),
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.TextBox,
                                      () =>
                                        TextBox(field as FieldValueTextBox, {
                                          onChange: (e) => this.updateTextBox(e, field as FieldValueTextBox),
                                          value: field.value as ValueTextBox,
                                          readonly: false,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.Url,
                                      () =>
                                        Url(field as FieldValueUrl, {
                                          onChange: (e) => this.updateUrl(e, field as FieldValueUrl),
                                          value: field.value as ValueUrl,
                                          readonly: false,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.YesOrNo,
                                      () =>
                                        YesOrNo(field as FieldValueYesOrNo, {
                                          onChange: (e) => this.updateYesOrNo(e, field as FieldValueYesOrNo),
                                          value: field.value as ValueYesOrNo,
                                          readonly: false,
                                        }),
                                    ],
                                  ]);
                                })}
                              </div>
                            `,
                            () => html`
                              <div slot="content" class="my-1.5 space-y-3">
                                ${section.fields.map((field) =>
                                  choose(field.collectionName, [
                                    [
                                      EvaluationFieldType.Attachment,
                                      () =>
                                        Attachment(field as FieldValueAttachment, {
                                          value: field.value as ValueAttachment,
                                          content: this._attachmentContent.get(field.id),
                                          readonly: true,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.NumericBox,
                                      () =>
                                        NumericBox(field as FieldValueNumericBox, {
                                          value: field.value as ValueNumericBox,
                                          readonly: true,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.ResultInteger,
                                      () =>
                                        ResultInteger(field as FieldValueResultInteger, {
                                          value: field.value as ValueResultInteger,
                                          score: this._currentScores.get(field.id),
                                          readonly: true,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.ResultPercentage,
                                      () =>
                                        ResultPercentage(field as FieldValueResultPercentage, {
                                          value: field.value as ValueResultPercentage,
                                          score: this._currentScores.get(field.id),
                                          readonly: true,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.Scale,
                                      () =>
                                        Scale(field as FieldValueScale, {
                                          readonly: true,
                                          onClick: () => this.openScaleModal(field as FieldValueScale, true),
                                          score: this.computeScaleScore(field as FieldValueScale),
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.TextBox,
                                      () =>
                                        TextBox(field as FieldValueTextBox, {
                                          value: field.value as ValueTextBox,
                                          readonly: true,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.Url,
                                      () =>
                                        Url(field as FieldValueUrl, {
                                          value: field.value as ValueUrl,
                                          readonly: true,
                                        }),
                                    ],
                                    [
                                      EvaluationFieldType.YesOrNo,
                                      () =>
                                        YesOrNo(field as FieldValueYesOrNo, {
                                          value: field.value as ValueYesOrNo,
                                          readonly: true,
                                        }),
                                    ],
                                  ]),
                                )}
                              </div>
                            `,
                          )}
                        </ion-accordion>
                        <div class="h-px w-full bg-[#e5e5e5] last:hidden"></div>
                      `,
                    )}
                  `,
                  () =>
                    html` <div
                      class="mx-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                          stroke="#111111"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                        Esta avaliação ainda não tem conteúdo
                      </span>
                    </div>`,
                )}
              </ion-accordion-group>
            `;
          },
        })}
        <input id="content-file-photo" class="hidden" accept="image/*" type="file" capture="user" />
        <input id="content-file-video" class="hidden" accept="video/*" type="file" capture="environment" />
        <input id="content-file-general" class="hidden" accept="video/*, image/*" type="file" />

        <ion-action-sheet
          ${ref(this.#contentActionSheet)}
          .buttons=${[
            {
              text: "Fotografia",
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#content-file-photo") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: "Video",
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#content-file-video") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: "Biblioteca de Ficheiros",
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#content-file-general") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: "Cancelar",
              role: "cancel",
            },
          ]}></ion-action-sheet>
      </ion-content>
    `;
  }
}
