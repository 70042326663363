import { observable, makeObservable, action, computed } from "mobx";
import type { User } from "../repository/user/user";
import type { NonRecord } from "../pocketbase";
import type { Access } from "../repository/group/access";

export interface UserShare {
  user: User;
  access: NonRecord<Access>;
}

export type UserShareAction = UserShare & { action: "create" | "update" | "delete" };

export class UserShareStore {
  @observable users: Array<UserShareAction> = [];

  constructor() {
    makeObservable(this);
  }

  @computed get availableUsers() {
    return this.users.filter((s) => s.action !== "delete");
  }

  @action set(users: Array<UserShareAction>) {
    this.users = users;
  }

  @action insert(user: UserShare) {
    const found = this.users.find((s) => s.user.id === user.user.id);
    if (found) {
      found.access = user.access;
      found.action = found.action === "create" ? "create" : "update";
    } else {
      this.users.push({ ...user, action: "create" });
    }
  }

  @action update(user: UserShare) {
    const found = this.users.find((s) => s.user.id === user.user.id);
    if (found) {
      found.access = user.access;
      found.action = found.action === "create" ? "create" : "update";
    }
  }

  @action remove(user: UserShare) {
    const found = this.users.find((s) => s.user.id === user.user.id);
    if (found) {
      if (found.action === "update") {
        found.action = "delete";
      } else {
        this.users = this.users.filter((s) => s.user.id !== user.user.id);
      }
    }
  }

  @action reset() {
    this.users = [];
  }
}
