import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import { sheet } from "../sheet";

import styles from "./xt-error.css?inline";
export const errorStyles = sheet(styles);

@customElement("xt-error")
export class XtError extends LitElement {
  static styles = [errorStyles];
  render() {
    return html`
      <div class="text-danger mt-1.5 items-start text-xs flex">
        <div class="flex mr-1">
          <svg
            class="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="ml-0.5 font-bold"> Erro: </span>
        </div>
        <span>
          <slot />
        </span>
      </div>
    `;
  }
}