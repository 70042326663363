import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { alertController, modalController } from "@ionic/core";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { ClientResponseError } from "pocketbase";
import { Page } from "../components/component";
import { choose } from "lit/directives/choose.js";
import { CreateTemplateExercisePlanSchema } from "../repository/templates/templates";
import { UserShareStore } from "../stores/userShare.store";
import { ModalShareEdit } from "./modal-share-edit";
import { ModalShareAdd } from "./modal-share-add";
import { when } from "lit/directives/when.js";
import { templatesStore } from "../stores/templateExercisePlan.store";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalNav } from "./modal-nav";

const formSchema = CreateTemplateExercisePlanSchema;

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-create-template")
export class ModalCreateTemplate extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  shareStore = new UserShareStore();

  #modalCropPhotoRef: Ref<ModalNav> = createRef();

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (data) => {
      try {
        const template = await templatesStore.createTemplate(data);
        try {
          await templatesStore.shareWithUsers(template, this.shareStore.users);
          this.navigator.close();
        } catch (error) {
          const alert = await alertController.create({
            header: "Erro",
            message: "Ocorreu um erro ao partilhar o template",
            buttons: ["OK"],
          });
          await alert.present();
          console.log(error);
        }
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.status === 403) {
            const alert = await alertController.create({
              header: "Erro",
              message: "Não tem permissões suficientes para criar um template.",
              buttons: ["Ok"],
            });
            await alert.present();
          } else {
            const alert = await alertController.create({
              header: "Erro",
              message: "Ocorreu um erro ao criar o template",
              buttons: ["OK"],
            });
            alert.present();
            console.log(error);
          }
        }
      }
    },
  });

  updateCroppedImage(canvas: HTMLCanvasElement) {
    const image = canvas.toDataURL("image/jpeg");
    const button = document.querySelector("#thumbnail-button") as HTMLButtonElement;
    button.style.backgroundImage = `url(${image})`;
    button.style.backgroundSize = "cover";
    canvas.toBlob((blob) => {
      if (blob) {
        const fileName = `cropped-image-${Date.now()}.jpeg`;
        const file = new File([blob], fileName, { type: "image/jpeg" });
        this.#controller.form.change("thumbnail", file);
      } else {
        console.error("Canvas to Blob conversion failed");
      }
    }, "image/jpeg", 0.8);
  }

  clearThumbnailForm = () => {
    this.#controller.form.change("thumbnail", undefined);
  }

  render() {
    const { form, register, submit, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content class="space-y-4" fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => modalController.dismiss()}">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  voltar
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">Novo Template</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form id="form-template-create" @submit=${submit}>
          <div class="flex flex-col justify-center items-center w-full">
            <button
              id="thumbnail-button"
              type="button"
              class="w-36 h-36 rounded-full bg-accent-8 border border-solid border-accent-7"
              aria-label="thumbnail"
              @click=${() => {
                const thumbnailFile = this.renderRoot.querySelector("#thumbnail-file") as HTMLInputElement;
                thumbnailFile.click();
              }}>
              ${when(
                !formState.values.thumbnail,
                () => html`
                  <div slot="icon-only">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path
                        d="M8.5 12H12.5M12.5 12H16.5M12.5 12V16M12.5 12V8M12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                `,
              )}
            </button>
            ${renderError("thumbnail")}
            <input
              ${register("thumbnail")}
              id="thumbnail-file"
              hidden
              accept="image/*"
              type="file"
              @change=${(event: Event) => {
                const input = event.target as HTMLInputElement;
                if (input.files && input.files[0]) {
                  const file = input.files[0];

                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const imageUrl = e.target?.result as string;
                    this.#modalCropPhotoRef.value?.open({
                      image: imageUrl,
                      updateImage: this.updateCroppedImage.bind(this),
                      onCancel: this.clearThumbnailForm,
                    });
                  };
                  reader.readAsDataURL(file);
                  input.value = "";
                }
              }} />
          </div>

          <div>
            <span class="font-semibold font-display">Nome</span>
            <ion-item class="mt-1">
              <ion-input autocapitalize="on" type="text" placeholder="Nome do template" ${register("name")}>
              </ion-input>
            </ion-item>
            ${renderError("name")}
          </div>
          <div class="mt-6">
            <span class="font-semibold font-display">Profissionais</span>
            <ion-list lines="none" class="space-y-2.5 mt-2 flex flex-col">
              <ion-item
                class="no-p"
                ${register("owner", {
                  initialValue: this.auth.user.id,
                })}>
                <div class="flex items-center space-x-2">
                  <xt-avatar src=${this.auth.user?.avatar} name=${this.auth.user?.name}></xt-avatar>
                  <div class="col items-start">
                    <p class="font-semibold">${this.auth.user?.name}</p>
                    <span class="text-danger">Criador</span>
                  </div>
                </div>
                ${renderError("owner")}
              </ion-item>
              ${this.shareStore.availableUsers.map(
                (share) => html`
                  <ion-item
                    class="no-p"
                    @click=${() =>
                      this.navigator.push(ModalShareEdit, {
                        shareStore: this.shareStore,
                        share: share,
                      })}>
                    <div class="flex items-center space-x-2">
                      <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                      <div class="col items-start">
                        <p class="font-semibold">${share.user.name}</p>
                        ${choose(share.access.role, [
                          ["editor", () => html` <span class="text-warning">Editor</span> `],
                          ["viewer", () => html` <span class="text-accent-2">Visualizador</span> `],
                        ])}
                      </div>
                    </div>
                  </ion-item>
                `,
              )}
            </ion-list>
          </div>
          <div class="mt-3">
            <ion-button
              size="small"
              color="secondary"
              expand="block"
              shape="round"
              @click=${() => this.navigator.push(ModalShareAdd, { shareStore: this.shareStore })}
              >Adicionar Profissional
              <span class="mt-0.5 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path
                    d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
            </ion-button>
          </div>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-template-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Criar Template</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-nav root="modal-crop-photo" ${ref(this.#modalCropPhotoRef)}></modal-nav>
    `;
  }
}
