import { consume } from "@lit/context";
import { customElement } from "lit/decorators.js";
import { authContext, type AuthContext } from "../context/auth.context";
import { html } from "lit";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { ClientResponseError } from "pocketbase";
import { UserUpdateSchema, type UserUpdate } from "../repository/user/user";
import { alertController, modalController } from "@ionic/core";
import type { IonInput } from "@ionic/core/components/ion-input";
import { Maskito } from "@maskito/core";
import { Page } from "../components/component";
import type { UserAuth } from "../stores/auth.store";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "./modal-nav";

@customElement("modal-profile")
export class ModalProfile extends Page {
  @consume({ context: authContext }) auth!: AuthContext;

  user!: UserAuth;

  #modalCropPhotoRef: Ref<ModalNav> = createRef();

  async firstUpdated(_changedProperties: Map<string, any>) {
    super.firstUpdated(_changedProperties);

    const usernameInput = this.renderRoot.querySelector("#input-username") as IonInput;
    const nativeEl = await usernameInput?.getInputElement();

    if (nativeEl) {
      new Maskito(nativeEl, {
        mask: ({ value }) => {
          const chars = Array.from(value).length;
          return ["@", ...new Array(chars || 1).fill(/[A-Za-z0-9._-]/)];
        },
      });
    }

    const avatarInput = this.renderRoot.querySelector("#avatar-file") as HTMLInputElement;
    avatarInput.addEventListener("change", (event) => {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
        const file = input.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target?.result as string;
          this.#modalCropPhotoRef.value?.open({ image: imageUrl, updateImage: this.updateCroppedImage.bind(this) });
        };
        reader.readAsDataURL(file);
      }
    });
  }

  updateCroppedImage(canvas: HTMLCanvasElement) {
    const image = canvas.toDataURL("image/jpeg");
    const imageEl = this.renderRoot.querySelector("#avatar-image") as HTMLImageElement;
    imageEl.src = image;
    canvas.toBlob((blob) => {
      if (blob) {
        const fileName = `cropped-image-${Date.now()}.jpeg`;
        const file = new File([blob], fileName, { type: "image/jpeg" });
        this.#controller.form.change("avatar", file);
      } else {
        console.error("Canvas to Blob conversion failed");
      }
    }, "image/jpeg", 0.8);
  }

  #controller = new FinalFormController<UserUpdate>(this, {
    validate: zodValidator(UserUpdateSchema),
    onSubmit: async (values) => {
      this.#controller.clearErrors();

      try {
        const username = values.username?.startsWith("@") ? values.username.slice(1) : values.username;

        if (!username) {
          this.#controller.setError("username", "Nome de utilizador é obrigatório");
          return;
        }

        const data: UserUpdate = {
          ...values,
          username,
        };

        await this.auth.updateProfile(data); //TODO check this new return
        modalController.dismiss();
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.status === 400) {
            if (error.response.data.username && error.response.data.email.code === "validation_not_unique") {
              this.#controller.setError("username", "Este nome de utilizador já existe");
            }
          } else {
            const alert = await alertController.create({
              header: "Erro",
              message: "Ocorreu um erro ao editar o perfil",
              buttons: ["OK"],
            });
            alert.present();
          }
        }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                @click=${() => modalController.dismiss()}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title>Perfil</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content fullscreen>
        <form
          id="form-update-user"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <div class="w-full flex mt-4 relative">
            <div class="w-[148px] h-[148px] rounded-full bg-accent-7 inset-abs-center -ml-[74px] -mt-[74px]"></div>
            <div
              @click=${() => {
                const avatarFile = this.renderRoot.querySelector("#avatar-file") as HTMLInputElement;
                avatarFile.click();
              }}
              class="mx-auto z-10">
              <xt-avatar
                id="avatar-image"
                style="--size: 144px; --border-radius: 99px;"
                src=${this.user.avatar}
                name=${this.user.name}></xt-avatar>
            </div>
            ${renderError("avatar")}
          </div>

          <div class="mt-4 space-y-4 pb-4">
            <ion-list lines="none" class="space-y-4 flex flex-col">
              <div>
                <span class="font-semibold font-display">Nome Utilizador</span>
                <ion-item class="mt-1">
                  <ion-input
                    id="input-username"
                    type="text"
                    placeholder="Nomde de utilizador"
                    ${register("username", { initialValue: this.user.username })}>
                  </ion-input>
                </ion-item>
                ${renderError("username")}
              </div>
              <div>
                <span class="font-semibold font-display">Nome</span>
                <ion-item class="mt-1">
                  <ion-input
                    type="text"
                    placeholder="Digite o seu nome aqui..."
                    ${register("name", { initialValue: this.user.name })}>
                  </ion-input>
                </ion-item>
                ${renderError("name")}
              </div>
              <div>
                <span class="font-semibold font-display">Especialidade</span>
                <ion-item class="mt-1">
                  <ion-input
                    type="text"
                    placeholder="Fisioterapeuta"
                    ${register("role", { initialValue: this.user.role })}>
                  </ion-input>
                </ion-item>
                ${renderError("role")}
              </div>
            </ion-list>
          </div>
        </form>
        <input
          id="avatar-file"
          class="hidden"
          accept="image/jpeg, image/png, image/webp, image/heic, image/heif"
          type="file"
          ${register("avatar")} />
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            form="form-update-user"
            type="submit"
            ?disabled=${formState.submitting}
            >Guardar Alterações</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-nav root="modal-crop-photo" ${ref(this.#modalCropPhotoRef)}></modal-nav>
    `;
  }
}
