import { customElement } from "lit/decorators.js";
import { html, nothing } from "lit";
import type { ObservableGroup, ObservableSection } from "../stores/groups.store";
import { repeat } from "lit/directives/repeat.js";
import { patientsStore, type ObservablePatient } from "../stores/patients.store";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { Page } from "../components/component";
import { Task } from "@lit/task";
import { alertController } from "@ionic/core";

@customElement("modal-move-patient")
export class ModalMovePatient extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;

  group!: ObservableGroup;
  patient!: ObservablePatient;

  connectedCallback(): void {
    super.connectedCallback();
    if (!this.group) {
      throw new Error("<modal-move-patient> requires group");
    } else if (!this.patient) {
      throw new Error("<modal-move-patient> requires patient");
    }
  }

  movePatientTask = new Task(this, {
    task: async ([patient, section]: [ObservablePatient, ObservableSection]) => {
      await patientsStore.movePatientToSection(patient, section);
    },
    onError: (error: unknown) => {
      console.error(error);
      alertController
        .create({ header: "Erro", message: "Não foi possível mover o paciente", buttons: ["OK"] })
        .then((alert) => alert.present());
    },
  });

  async movePatient(section: ObservableSection) {
    await this.movePatientTask.run([this.patient, section]);
    this.navigator.goBack();
  }

  render() {
    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea;">
            <ion-buttons slot="start">
              <ion-button @click="${() => this.navigator.goBack()}" class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                fechar</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">Escolhe a secção</ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-list>
          ${repeat(
            this.group.sections,
            (section) => section.id,
            (section) =>
              html`
                ${section.id !== this.patient.section
                  ? html`
                      <ion-item @click=${() => this.movePatient(section)} .detail=${false} button class="no-p">
                        <ion-label class="m-0 font-semibold py-4">${section.name}</ion-label>
                      </ion-item>
                    `
                  : nothing}
              `,
          )}
        </ion-list>
      </div>
    `;
  }
}
