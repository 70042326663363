import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { z } from "zod";
import { consume } from "@lit/context";
import { Page } from "../../components/component";
import { FinalFormController, zodValidator } from "../../controllers/final-form.controller";
import { navigatorContext, NavigatorController } from "../../controllers/navigator.controller";
import {
  CreateEvaluationFieldResultIntegerSchema,
  type CreateEvaluationFieldResultInteger,
} from "../../repository/report/report";
import type { ObservableEvaluationSection } from "../../stores/evaluation.store";
import { alertController } from "@ionic/core";
import { reportsStore } from "../../stores/reports.store";

const formSchema = CreateEvaluationFieldResultIntegerSchema.pick({ label: true, minimum: true, maximum: true });

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-result-integer")
export class ModalResultInteger extends Page {
  section!: ObservableEvaluationSection;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  #form = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      try {
        const minimum = Number(values.minimum);
        const maximum = Number(values.maximum);
        if (minimum >= maximum) {
          this.#form.setError("minimum", "O valor mínimo deve ser menor que o valor máximo");
          return;
        }

        const data: CreateEvaluationFieldResultInteger = {
          label: values.label,
          minimum: values.minimum,
          maximum: values.maximum,
          section: this.section.id,
          evaluation: this.section.evaluation,
        };
        reportsStore.insertEvaluationFieldResultInteger(data, this.section);

        this.navigator.close();
      } catch (error) {
        const alert = await alertController.create({
          header: "Erro",
          message: "Não foi possível salvar o campo de resultado por inteiro. Tente novamente.",
          buttons: ["OK"],
        });
        await alert.present();
        console.error(error);
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
              <span class="mt-[3px] -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              voltar</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">Resultado por inteiro</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        <form
          id="form-result-integer"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list class="space-y-4" lines="none">
            <div>
              <span class="font-semibold font-display">Legenda</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder="Legenda"
                  enterkeyhint="next"
                  ${register("label")}>
                </ion-input>
              </ion-item>
              ${renderError("label")}
            </div>
            <div>
              <span class="font-semibold font-display">Valor mínimo</span>
              <ion-item class="mt-1">
                <ion-input type="number" placeholder="00" enterkeyhint="next" ${register("minimum")}> </ion-input>
              </ion-item>
              ${renderError("minimum")}
            </div>
            <div>
              <span class="font-semibold font-display">Valor máximo</span>
              <ion-item class="mt-1">
                <ion-input type="number" placeholder="00" enterkeyhint="send" ${register("maximum")}> </ion-input>
              </ion-item>
              ${renderError("maximum")}
            </div>
          </ion-list>

          <ion-button
            form="form-result-integer"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Adicionar</ion-button
          >
        </form>
      </ion-content>
    `;
  }
}
