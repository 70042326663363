import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { Page, required } from "../components/component";
import { z } from "zod";
import { consume } from "@lit/context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { RouterContext, routerContext } from "../context/router.context";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { libraryRepository } from "../repository/library/library.repository";
import { alertController } from "@ionic/core";
import type { Category } from "../repository/library/exercises";

const formSchema = z.object({
  category: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-create-exercise-category")
export class ModalCreateExerciseCategory extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;
  @consume({ context: routerContext }) router!: RouterContext;

  @required() category!: string;
  @required() onSubmit!: (category: Category) => void;

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      try {
        const libraryId = this.router.getParam("id");
        const category = await libraryRepository.createCategory(values.category, libraryId);
        this.onSubmit(category);
      } catch (error) {
        alertController
          .create({
            header: "Erro",
            message: "Erro ao criar categoria",
            buttons: ["OK"],
          })
          .then((alert) => {
            alert.present();
            console.error(error);
          });
      } finally {
        this.navigator.close();
      }
    },
  });

  render() {
    const { submit, register, renderError } = this.#controller;
    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea" class="!px-0">
            <ion-buttons slot="start">
              <ion-button @click="${this.navigator.close}" class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                fechar</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">Criar Categoria</ion-title>
          </ion-toolbar>
        </ion-header>

        <div class="mt-4">
          <span class="font-semibold font-display">Categoria</span>
          <form id="form-create-category" @submit=${submit}>
            <ion-item class="mt-1.5">
              <ion-input
                autocapitalize="on"
                type="text"
                placeholder="Categoria"
                ${register("category", {
                  initialValue: this.category,
                })}>
              </ion-input>
            </ion-item>
            ${renderError("category")}
          </form>
        </div>

        <ion-button form="form-create-category" type="submit" expand="block" shape="round" class="mt-6"
          >Criar Categoria</ion-button
        >
      </div>
    `;
  }
}
