import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { groupStore, ObservableSection } from "../stores/groups.store";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Page, required } from "../components/component";

const formSchema = z.object({
  name: z
    .string({
      required_error: "O nome da secção é obrigatório",
    })
    .min(3, "O nome da secção deve ter pelo menos 3 caracteres"),
});

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-edit-section")
export class ModalEditSection extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  @required() section!: ObservableSection;

  #form = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      await groupStore.updateSection(this.section, {
        name: values.name,
        position: this.section.position,
      });
      this.navigator.close();
    },
  });

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea">
            <ion-buttons slot="start">
              <ion-button @click=${() => this.navigator.close()} class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                fechar</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">Editar Seção</ion-title>
          </ion-toolbar>
        </ion-header>
        <form
          id="form-section-edit"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none">
            <div>
              <span class="font-semibold font-display">Nome</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder="Secção"
                  ${register("name", { initialValue: this.section.name })}>
                </ion-input>
              </ion-item>
              ${renderError("name")}
            </div>
          </ion-list>

          <ion-button
            form="form-section-edit"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Guardar Alterações</ion-button
          >
        </form>
      </div>
    `;
  }
}
