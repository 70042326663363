import { z } from "zod";
import { withThumb } from "../../pocketbase";
import { file } from "../shared/file";
import { unsafeParse } from "../shared/unsafeParse";
import { record } from "../shared/record";

export const UserRecordSchema = record({
  name: z.string(),
  username: z.string(),
  email: z.string().optional(), // email might be private
  avatar: z.string().optional(),
  isProfileComplete: z.boolean(),
  role: z.string().optional(),
})
export interface UserRecord extends z.infer<typeof UserRecordSchema> {}

export const UserSchema = UserRecordSchema.omit({ expand: true, created: true, updated: true});
export interface User extends z.infer<typeof UserSchema> {}
export function createUser(data: Record<string, unknown>): User {
  return unsafeParse(UserSchema, withThumb(data, "avatar", "128x128"));
}

export const UserUpdateSchema = z.object({
  name: z.string().min(3, "O nome tem de ter pelo menos 3 caracteres").optional(),
  username: z
    .string()
    .min(3, "O nome de utilizador tem de ter pelo menos 3 caracteres")
    .max(32, "Nome de utilizador deve ter no máximo 32 caracteres")
    .regex(/^[A-Za-z0-9._-]+$/, "Nome de utilizador deve conter apenas letras, números e os caracteres . _ -")
    .optional(),
  role: z.string().optional(),
  avatar: file(
    {
      size: 10 * 1024 * 1024,
      allowedFileTypes: ["image/jpeg", "image/jpg", "image/png", "image/webp", "image/heic", "image/heif"],
    },
    "O thumbnail deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif",
  ).optional(),
});
export interface UserUpdate extends z.infer<typeof UserUpdateSchema> {}
