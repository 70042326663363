import { expand, expandAt, pb } from "../../pocketbase";
import { createExercise, createPublicExercise } from "../library/exercises";
import {
  createEvaluationSummary,
  createPatientEvaluation,
  createReportCategory,
  type CreatePatientEvaluation,
  type EvaluationRecord,
  type PatientEvaluation,
  type PatientEvaluationRecord,
} from "../report/report";
import { createUser, type UserRecord } from "../user/user";
import {
  createDiagnostic,
  createSession,
  type CreateDiagnostic,
  type CreateExercisePlan,
  type CreateSession,
  type Diagnostic,
  type DiagnosticRecord,
  type ExercisePlan,
  type ExercisePlanRecord,
  type Session,
  type SessionRecord,
  type UpdateDiagnostic,
  type UpdateExercisePlan,
  type UpdateSession,
  createExercisePlan,
} from "./diagnostic";

export class DiagnosticRepository {
  async list(patientId: string): Promise<Diagnostic[]> {
    const result = await pb.collection<DiagnosticRecord>("diagnostics").getFullList({
      filter: `patient = "${patientId}"`,
      expand: "professionals",
      sort: "-updated",
    });

    const diagnostics = result.map((diagnostic) => {
      const professionals = expandAt<UserRecord>(diagnostic, "professionals").map(createUser);
      return {
        ...diagnostic,
        professionals,
      };
    });

    return diagnostics.map(createDiagnostic);
  }

  async getDiagnostic(id: string): Promise<Diagnostic> {
    const result = await pb.collection<DiagnosticRecord>("diagnostics").getOne(id, {
      expand: expand(
        "professionals",
        "sessions_via_diagnostic",
        "sessions_via_diagnostic.exercises_plan_via_session",
        "sessions_via_diagnostic.exercises_plan_via_session.exercise",
        "sessions_via_diagnostic.exercises_plan_via_session.exercisePublic",
        "sessions_via_diagnostic.patient_evaluations_via_session",
        "sessions_via_diagnostic.patient_evaluations_via_session.evaluation",
        "sessions_via_diagnostic.patient_evaluations_via_session.evaluation.evaluations_category_via_evaluation.category",
      ),
    });

    const sessions = expandAt<SessionRecord>(result, "sessions_via_diagnostic").map((s) => {
      const exercisesPlan = expandAt<ExercisePlanRecord>(s, "exercises_plan_via_session").map((e) => {
        // we need to do this because zod schema will fail if we have categories, difficulties,
        // equipments or muscles as string[] (we don't need this here)
        if (e.expand?.exercisePublic) {
          e.expand.exercisePublic.categories = [];
          e.expand.exercisePublic.difficulties = [];
          e.expand.exercisePublic.equipments = [];
          e.expand.exercisePublic.muscles = [];
        }
        return createExercisePlan({
          ...e,
          exercise: e.expand?.exercise ? createExercise(e.expand?.exercise) : undefined,
          exercisePublic: e.expand?.exercisePublic ? createPublicExercise(e.expand?.exercisePublic) : undefined,
        });
      });
      const evaluations = expandAt<PatientEvaluationRecord>(s, "patient_evaluations_via_session").map((e) => {
        const evaluationArray = expandAt<EvaluationRecord>(e, "evaluation");

        if (!evaluationArray || evaluationArray.length === 0) return [];

        const evaluation = evaluationArray[0];
        return {
          ...e,
          evaluation: createEvaluationSummary({
            ...evaluation,
            categories: expandAt(evaluation, "evaluations_category_via_evaluation").map((c) =>
              createReportCategory(c.expand.category),
            ),
          }),
        };
      });
      return createSession({
        ...s,
        exercisesPlan,
        evaluations,
      });
    });

    const professionals = expandAt<UserRecord>(result, "professionals").map(createUser);

    return createDiagnostic({
      ...result,
      sessions,
      professionals,
    });
  }

  async createDiagnostic(data: CreateDiagnostic): Promise<Diagnostic> {
    const result = await pb.collection<DiagnosticRecord>("diagnostics").create(data, { expand: "professionals" });

    const professionals = expandAt<UserRecord>(result, "professionals").map(createUser);

    return createDiagnostic({
      ...result,
      professionals,
    });
  }

  async updateDiagnostic(diagnosticId: string, data: UpdateDiagnostic): Promise<Diagnostic> {
    const result = await pb
      .collection<DiagnosticRecord>("diagnostics")
      .update(diagnosticId, data, { expand: "professionals" });

    const professionals = expandAt<UserRecord>(result, "professionals").map(createUser);

    return createDiagnostic({
      ...result,
      professionals,
    });
  }

  async deleteDiagnostic(diagnosticId: string): Promise<boolean> {
    return await pb.collection<DiagnosticRecord>("diagnostics").delete(diagnosticId);
  }

  async createSession(data: CreateSession): Promise<Session> {
    const result = await pb.collection<SessionRecord>("sessions").create(data);
    return createSession(result);
  }

  async updateSession(sessionId: string, data: UpdateSession): Promise<Session> {
    const result = await pb.collection<SessionRecord>("sessions").update(sessionId, data);
    return createSession(result);
  }

  async deleteSession(id: string): Promise<boolean> {
    return await pb.collection<SessionRecord>("sessions").delete(id);
  }

  async createExercisePlan(data: CreateExercisePlan): Promise<ExercisePlan> {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (value !== undefined && value !== null) {
        formData.append(key, value.toString());
      }
    });

    const result = await pb.collection<ExercisePlanRecord>("exercises_plan").create(formData, {
      expand: expand("exercise", "exercisePublic"),
    });

    // we need to do this because zod schema will fail if we have categories, difficulties,
    // equipments or muscles as string[] (we don't need this here)
    if (result.expand?.exercisePublic) {
      result.expand.exercisePublic.categories = [];
      result.expand.exercisePublic.difficulties = [];
      result.expand.exercisePublic.equipments = [];
      result.expand.exercisePublic.muscles = [];
    }

    return createExercisePlan({
      ...result,
      exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
      exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
    });
  }

  async updateExercisePlan(id: string, data: UpdateExercisePlan): Promise<ExercisePlan> {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (value !== undefined && value !== null) {
        formData.append(key, value.toString());
      }
    });

    const result = await pb.collection<ExercisePlanRecord>("exercises_plan").update(id, formData, {
      expand: expand("exercise", "exercisePublic"),
    });

    // we need to do this because zod schema will fail if we have categories, difficulties,
    // equipments or muscles as string[] (we don't need this here)
    if (result.expand?.exercisePublic) {
      result.expand.exercisePublic.categories = [];
      result.expand.exercisePublic.difficulties = [];
      result.expand.exercisePublic.equipments = [];
      result.expand.exercisePublic.muscles = [];
    }

    return createExercisePlan({
      ...result,
      exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
      exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
    });
  }

  async injectTemplate(sessionId: string, templateId: string): Promise<ExercisePlan[]> {
    const results = await pb.send<ExercisePlanRecord[]>(`/api/v1/session/${sessionId}`, {
      method: "POST",
      body: JSON.stringify({ template: templateId }),
    });

    return results.map((result) => {
      // we need to do this because zod schema will fail if we have categories, difficulties,
      // equipments or muscles as string[] (we don't need this here)
      if (result.expand?.exercisePublic) {
        result.expand.exercisePublic.categories = [];
        result.expand.exercisePublic.difficulties = [];
        result.expand.exercisePublic.equipments = [];
        result.expand.exercisePublic.muscles = [];
      }

      return createExercisePlan({
        ...result,
        exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
        exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
      });
    });
  }

  async updateExercisePlanPositions(
    sessionId: string,
    exercises: { id: string; position: number }[],
  ): Promise<ExercisePlan> {
    const result = await pb.send<ExercisePlanRecord>(`/api/v1/session/${sessionId}/exercisePlan`, {
      method: "PATCH",
      body: JSON.stringify({ exercises: exercises }),
    });

    // we need to do this because zod schema will fail if we have categories, difficulties,
    // equipments or muscles as string[] (we don't need this here)
    if (result.expand?.exercisePublic) {
      result.expand.exercisePublic.categories = [];
      result.expand.exercisePublic.difficulties = [];
      result.expand.exercisePublic.equipments = [];
      result.expand.exercisePublic.muscles = [];
    }

    return createExercisePlan({
      ...result,
      exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
      exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
    });
  }

  async deleteExercisePlan(id: string): Promise<boolean> {
    return await pb.collection<ExercisePlanRecord>("exercises_plan").delete(id);
  }

  async createPatientEvaluation(data: CreatePatientEvaluation): Promise<PatientEvaluation> {
    const result = await pb.collection<PatientEvaluationRecord>("patient_evaluations").create(data, {
      expand: expand("evaluation", "evaluation.evaluations_category_via_evaluation.category"),
    });

    const evaluation = expandAt<EvaluationRecord>(result, "evaluation")[0];

    return createPatientEvaluation({
      ...result,
      evaluation: createEvaluationSummary({
        ...evaluation,
        categories: expandAt(evaluation, "evaluations_category_via_evaluation").map((c) =>
          createReportCategory(c.expand.category),
        ),
      }),
    });
  }

  async deletePatientEvaluation(id: string): Promise<boolean> {
    return await pb.collection<PatientEvaluationRecord>("patient_evaluations").delete(id);
  }
}

export const diagnosticRepository = new DiagnosticRepository();
