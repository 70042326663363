import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { modalController, alertController } from "@ionic/core";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { UpdateDiagnosticSchema } from "../repository/patient/diagnostic";
import { ObservableDiagnostic, diagnosticsStore } from "../stores/diagnostics.store";
import { ClientResponseError } from "pocketbase";
import { Page } from "../components/component";

type FormValues = z.infer<typeof UpdateDiagnosticSchema>;

@customElement("modal-edit-diagnostic")
export class ModalEditDiagnostic extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  diagnostic!: ObservableDiagnostic;

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(UpdateDiagnosticSchema),
    onSubmit: async (values) => {
      if (!this.auth.user) return;

      this.#controller.clearErrors();

      try {
        await diagnosticsStore.updateDiagnostic(this.diagnostic, values);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.status === 403) {
            const alert = await alertController.create({
              header: "Erro",
              message: "Não tem permissões suficientes para editar este diagnóstico.",
              buttons: ["Ok"],
            });
            await alert.present();
          } else if (error.status === 404) {
            const alert = await alertController.create({
              header: "Erro",
              message: "O diagnóstico em causa não foi encontrado.",
              buttons: ["Ok"],
            });
            await alert.present();
          } else {
            const alert = await alertController.create({
              header: "Erro",
              message: "Ocorreu um erro ao edit o diagnóstico.",
              buttons: ["OK"],
            });
            alert.present();
          }
        }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content class="space-y-4" fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => modalController.dismiss()}">
                  Cancelar
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">Editar Diagnóstico</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form
          id="form-diagnostic-update"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none" class="space-y-4 flex flex-col">
            <div>
              <span class="font-semibold font-display">Nome</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder="Nome do diagnóstico"
                  ${register("name", { initialValue: this.diagnostic.name })}>
                </ion-input>
              </ion-item>
              ${renderError("name")}
            </div>

            <div>
              <span class="font-semibold font-display">Descrição</span>
              <ion-item class="mt-1">
                <ion-textarea
                  auto-grow
                  rows="4"
                  ${register("description", { initialValue: this.diagnostic.description })}
                  placeholder="Descreva a situação clínica...">
                </ion-textarea>
              </ion-item>
              ${renderError("description")}
            </div>
          </ion-list>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-diagnostic-update"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Guardar Alterações</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
