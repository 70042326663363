import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, type NavigatorController } from "../controllers/navigator.controller";
import { Page } from "../components/component";
import type { ObservableEvaluationSection } from "../stores/evaluation.store";

@customElement("modal-choose-evaluation-field")
export class ModalChooseEvaluationField extends Page {
  section!: ObservableEvaluationSection;
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="${() => this.navigator.close()}" class="font-semibold">
              <span class="mt-[3px] -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              fechar</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">Escolhe o campo</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        <ion-list>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-attachment", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">Anexo</ion-label>
          </ion-item>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-text-box", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">Caixa de texto</ion-label>
          </ion-item>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-numeric-box", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">Caixa numérica</ion-label>
          </ion-item>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-scale", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">Escala</ion-label>
          </ion-item>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-yes-no-questionnaire", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">Questionário S/N</ion-label>
          </ion-item>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-result-percentage", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">Resultado %</ion-label>
          </ion-item>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-result-integer", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">Resultado por inteiro</ion-label>
          </ion-item>
          <ion-item
            button
            detailIcon="/assets/icons/chevron-right.svg"
            class="no-p no-inline-p no-inner-p"
            @click=${() => {
              this.navigator.push("modal-url", { section: this.section });
            }}>
            <ion-label class="py-2 font-semibold">URL</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    `;
  }
}
