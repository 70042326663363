import { html, nothing } from "lit";
import type {
  EvaluationFieldAttachment,
  EvaluationFieldNumericBox,
  EvaluationFieldResultInteger,
  EvaluationFieldResultPercentage,
  EvaluationFieldScale,
  EvaluationFieldTextBox,
  EvaluationFieldUrl,
  EvaluationFieldYesOrNo,
  ValueAttachment,
  ValueNumericBox,
  ValueResultInteger,
  ValueResultPercentage,
  ValueTextBox,
  ValueUrl,
  ValueYesOrNo,
} from "../repository/report/report";
import { when } from "lit/directives/when.js";

interface NewContent {
  file: File;
  content: string;
}

export const Attachment = (
  props: EvaluationFieldAttachment,
  options: {
    readonly: boolean;
    value?: ValueAttachment;
    content?: NewContent[];
    register?: (name: string) => void;
    onClick?: () => void;
    onAdd?: (e: any) => void;
    onDelete?: (e: any) => void;
  },
) => {
  return html`
    <div @click=${options.onClick}>
      <span class="font-semibold font-display w-full">${props.label}</span>
      ${options.content?.map((c, index) => {
        if (c.file.type.startsWith("video/")) {
          return html`
            <div class="w-full relative mt-2">
              <video controls class="w-full max-w-lg h-[180px] rounded-lg border border-solid border-accent-7">
                <source src=${c.content} type=${c.file.type} />
              </video>
              ${when(
                !options.readonly,
                () => html`
                  <button
                    @click="${(e: Event) => {
                      e.stopPropagation();
                    }}"
                    type="button"
                    id="${index}-newcontent-popover"
                    class="w-5 h-5 absolute top-2.5 right-2.5 bg-white rounded-full ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M14.1667 10.0001C14.1667 10.4603 14.5398 10.8334 15.0001 10.8334C15.4603 10.8334 15.8334 10.4603 15.8334 10.0001C15.8334 9.53984 15.4603 9.16675 15.0001 9.16675C14.5398 9.16675 14.1667 9.53984 14.1667 10.0001Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M9.16675 10.0001C9.16675 10.4603 9.53984 10.8334 10.0001 10.8334C10.4603 10.8334 10.8334 10.4603 10.8334 10.0001C10.8334 9.53984 10.4603 9.16675 10.0001 9.16675C9.53984 9.16675 9.16675 9.53984 9.16675 10.0001Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M4.16675 10.0001C4.16675 10.4603 4.53984 10.8334 5.00008 10.8334C5.46032 10.8334 5.83341 10.4603 5.83341 10.0001C5.83341 9.53984 5.46032 9.16675 5.00008 9.16675C4.53984 9.16675 4.16675 9.53984 4.16675 10.0001Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </button>
                  <ion-popover
                    arrow
                    alignment="center"
                    reference="trigger"
                    side="bottom"
                    dismiss-on-select="true"
                    style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                    trigger="${index}-newcontent-popover">
                    <div class="p-0">
                      <ion-list>
                        <ion-item
                          @click=${() => options.onDelete!(c)}
                          button
                          .detail=${false}
                          class="no-p popover text-sm">
                          <ion-label class="font-semibold pl-3">Remover</ion-label>
                        </ion-item>
                      </ion-list>
                    </div>
                  </ion-popover>
                `,
              )}
            </div>
          `;
        } else {
          return html`
            <div class="w-full h-[180px] relative mt-2">
              <img
                class="w-full h-[180px] rounded-lg object-cover border border-solid border-accent-7"
                src=${c.content} />
              ${when(
                !options.readonly,
                () => html`
                  <button
                    @click="${(e: Event) => {
                      e.stopPropagation();
                    }}"
                    type="button"
                    id="${index}-newcontent-popover"
                    class="w-5 h-5 absolute top-2.5 right-2.5 bg-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M14.1667 10.0001C14.1667 10.4603 14.5398 10.8334 15.0001 10.8334C15.4603 10.8334 15.8334 10.4603 15.8334 10.0001C15.8334 9.53984 15.4603 9.16675 15.0001 9.16675C14.5398 9.16675 14.1667 9.53984 14.1667 10.0001Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M9.16675 10.0001C9.16675 10.4603 9.53984 10.8334 10.0001 10.8334C10.4603 10.8334 10.8334 10.4603 10.8334 10.0001C10.8334 9.53984 10.4603 9.16675 10.0001 9.16675C9.53984 9.16675 9.16675 9.53984 9.16675 10.0001Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M4.16675 10.0001C4.16675 10.4603 4.53984 10.8334 5.00008 10.8334C5.46032 10.8334 5.83341 10.4603 5.83341 10.0001C5.83341 9.53984 5.46032 9.16675 5.00008 9.16675C4.53984 9.16675 4.16675 9.53984 4.16675 10.0001Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </button>
                  <ion-popover
                    arrow
                    alignment="center"
                    reference="trigger"
                    dismiss-on-select="true"
                    side="bottom"
                    style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                    trigger="${index}-newcontent-popover">
                    <div class="p-0">
                      <ion-list>
                        <ion-item
                          @click=${() => options.onDelete!(c)}
                          button
                          .detail=${false}
                          class="no-p popover text-sm">
                          <ion-label class="font-semibold pl-3">Remover</ion-label>
                        </ion-item>
                      </ion-list>
                    </div>
                  </ion-popover>
                `,
              )}
            </div>
          `;
        }
      })}
      ${when(
        !options.readonly,
        () => html`
          <ion-button
            .disabled=${options.readonly}
            @click=${() => options.onAdd!(props)}
            size="small"
            shape="round"
            expand="block"
            class="h-10 mt-2"
            >Adicionar
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              class="ml-2 mb-0.5">
              <path
                d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </ion-button>
        `,
        () => {
          if (options.content?.length === 0 || !options.content) {
            return html`
              <div
                class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                    stroke="#111111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                  Ainda não existem anexos</span
                >
              </div>
            `;
          }
          return nothing;
        },
      )}
    </div>
  `;
};

export const NumericBox = (
  props: EvaluationFieldNumericBox,
  options: {
    readonly: boolean;
    value?: ValueNumericBox;
    register?: (name: string) => void;
    onClick?: () => void;
    onChange?: (e: any) => void;
  },
) => html`
  <div @click=${options.onClick}>
    <span class="font-semibold font-display">${props.label}</span>
    <ion-item class="mt-1">
      <ion-input
        ?inert=${options.readonly}
        debounce=${2000}
        @ionChange=${options.onChange}
        type="number"
        placeholder="00"
        value=${options.value?.value}
        readonly=${options.readonly}></ion-input>
    </ion-item>
  </div>
`;

export const ResultInteger = (
  props: EvaluationFieldResultInteger,
  options: {
    readonly: boolean;
    value?: ValueResultInteger;
    score?: number;
    register?: (name: string) => void;
    onClick?: () => void;
    onChange?: (e: any) => void;
  },
) => html`
  <div @click=${() => (options.onClick ? options.onClick() : null)}>
    <span class="font-semibold font-display">${props.label}</span>
    <ion-item class="mt-1">
      <ion-input
        ?inert=${options.readonly}
        debounce=${2000}
        @ionChange=${options.onChange}
        type="text"
        placeholder="00"
        value=${options.value?.value}
        readonly=${options.readonly}>
        <div slot="end" class="bg-accent-7 rounded-full h-5 px-1.5 flex items-center">
          <span class="text-xs text-accent-1 font-semibold leading-4">Score: ${options?.score || 0}</span>
        </div>
      </ion-input>
    </ion-item>
  </div>
`;

export const ResultPercentage = (
  props: EvaluationFieldResultPercentage,
  options: {
    readonly: boolean;
    value?: ValueResultPercentage;
    score?: number;
    register?: (name: string) => void;
    onClick?: () => void;
    onChange?: (e: any) => void;
  },
) => html`
  <div @click=${() => (options.onClick ? options.onClick() : null)}>
    <span class="font-semibold font-display">${props.label}</span>
    <ion-item class="mt-1">
      <ion-input
        ?inert=${options.readonly}
        debounce=${2000}
        @ionChange=${options.onChange}
        type="text"
        placeholder="00"
        value=${options.value?.value}
        readonly=${options.readonly}>
        <div slot="end" class="bg-accent-7 rounded-full h-5 px-1.5 flex items-center">
          <span class="text-xs text-accent-1 font-semibold leading-4">Score: ${options?.score || 0}%</span>
        </div>
      </ion-input>
    </ion-item>
  </div>
`;

export const TextBox = (
  props: EvaluationFieldTextBox,
  options: {
    readonly: boolean;
    value?: ValueTextBox;
    register?: (name: string) => void;
    onClick?: () => void;
    onChange?: (e: any) => void;
  },
) => html`
  <div @click=${options.onClick}>
    <span class="font-semibold font-display">${props.label}</span>
    <ion-item class="mt-1">
      <ion-textarea
        auto-grow
        rows="4"
        ?inert=${options.readonly}
        debounce=${2000}
        @ionChange=${options.onChange}
        type="text"
        placeholder="Escreva aqui..."
        value=${options.value?.value}
        readonly=${options.readonly}></ion-textarea>
    </ion-item>
  </div>
`;

export const Url = (
  props: EvaluationFieldUrl,
  options: {
    readonly: boolean;
    value?: ValueUrl;
    register?: (name: string) => void;
    onClick?: () => void;
    onChange?: (e: any) => void;
  },
) => html`
  <div @click=${options.onClick}>
    <span class="font-semibold font-display">${props.label}</span>
    <ion-item class="mt-1">
      <ion-input
        ?inert=${options.readonly}
        debounce=${2000}
        @ionChange=${options.onChange}
        @ionFocus=${() => console.log("focus")}
        type="text"
        placeholder="Escreva aqui o link..."
        value=${options.value?.value}
        readonly=${options.readonly}></ion-input>
    </ion-item>
    <ion-button
      class="mt-3"
      color="primary"
      expand="block"
      shape="round"
      size="small"
      target="_blank"
      href=${options.value?.value}
      >Ir para o link</ion-button
    >
  </div>
`;

export const Scale = (
  props: EvaluationFieldScale,
  options: {
    readonly: boolean;
    score?: number;
    register?: (name: string) => void;
    onClick?: () => void;
    onChange?: (e: any) => void;
  },
) => html`
  <div
    @click=${options.onClick}
    class="bg-accent-8 border border-solid border-accent-7 rounded-lg px-3 py-3.5 flex justify-between items-center mb-4">
    <span class="text-sm font-display font-semibold">${props.scale.name}</span>
    <div class="bg-accent-7 rounded-full h-5 px-1.5 flex items-center">
      <span class="text-xs text-accent-1 font-semibold leading-4">Score: ${options.score || 0}</span>
    </div>
  </div>
`;

export const YesOrNo = (
  props: EvaluationFieldYesOrNo,
  options: {
    readonly: boolean;
    value?: ValueYesOrNo;
    register?: (name: string) => void;
    onClick?: () => void;
    onChange?: (e: any) => void;
  },
) => html`
  <div @click=${options.onClick}>
    <span class="font-semibold font-display">${props.label}</span>
    <ion-list lines="none">
      <ion-radio-group
        @ionChange=${options.onChange}
        value=${options.value
          ? options.value.option1
            ? "option1"
            : options.value.option2
              ? "option2"
              : options.value.option3
                ? "option3"
                : options.value.option4
                  ? "option4"
                  : options.value.option5
                    ? "option5"
                    : ""
          : ""}>
        <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
          <ion-radio ?disabled=${options.readonly} value="option1" class="no-m-inline my-2 opacity-90"
            >${props.option1}</ion-radio
          >
        </ion-item>
        <div class="w-full h-px bg-accent-7"></div>
        <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
          <ion-radio ?disabled=${options.readonly} value="option2" class="no-m-inline my-2 opacity-90"
            >${props.option2}</ion-radio
          >
        </ion-item>
        ${when(
          props.option3,
          () => html`
            <div class="w-full h-px bg-accent-7"></div>
            <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
              <ion-radio ?disabled=${options.readonly} value="option3" class="no-m-inline my-2 opacity-90"
                >${props.option3}</ion-radio
              >
            </ion-item>
          `,
        )}
        ${when(
          props.option4,
          () => html`
            <div class="w-full h-px bg-accent-7"></div>
            <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
              <ion-radio ?disabled=${options.readonly} value="option4" class="no-m-inline my-2 opacity-90"
                >${props.option4}</ion-radio
              >
            </ion-item>
          `,
        )}
        ${when(
          props.option5,
          () => html`
            <div class="w-full h-px bg-accent-7"></div>
            <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
              <ion-radio ?disabled=${options.readonly} value="option5" class="no-m-inline my-2 opacity-90"
                >${props.option5}</ion-radio
              >
            </ion-item>
          `,
        )}
      </ion-radio-group>
    </ion-list>
  </div>
`;
