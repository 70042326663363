import { html, type PropertyValues } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Page } from "../components/component";
import Cropper from "cropperjs";

@customElement("modal-crop-photo")
export class ModalCropPhoto extends Page {
  image!: string;
  updateImage!: (image: HTMLCanvasElement) => void;
  onCancel?: () => void;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @state() private cropper: Cropper | null = null;
  @query("#image-crop") private imageElement!: HTMLImageElement;

  async firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties);
    await this.updateComplete;
    this.waitForImageAndInitializeCropper();
  }

  private async waitForImageAndInitializeCropper() {
    if (this.imageElement.complete) {
      this.initializeCropper();
    } else {
      this.imageElement.onload = () => {
        this.initializeCropper();
      };
    }
  }

  private async initializeCropper() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    setTimeout(() => {
      this.cropper = new Cropper(this.imageElement, {
        dragMode: "move",
        aspectRatio: 1,
        autoCropArea: 0.65,
        restore: false,
        guides: false,
        center: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
      });
    }, 1);
  }

  cropImage() {
    const canvas = this.cropper?.getCroppedCanvas();
    if (canvas) this.updateImage(canvas);
    this.navigator.close();
  }

  cancel() {
    if (this.onCancel) this.onCancel();
    this.navigator.close();
  }

  render() {
    return html`
      <ion-content>
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea">
            <ion-buttons slot="start">
              <ion-button @click=${() => this.cancel()} class="font-semibold"> Cancelar</ion-button>
            </ion-buttons>
            <ion-title class="font-display text-lg">Cortar Foto</ion-title>
          </ion-toolbar>
        </ion-header>

        <div id="img-container" class="w-full h-auto">
          <img id="image-crop" src=${this.image} style="display: block; max-width: 100%; height: auto;" />
        </div>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            @click=${() => this.cropImage()}
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            >Cortar</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
