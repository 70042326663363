import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { alertController, modalController } from "@ionic/core";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { CreateSessionSchema, type CreateSession } from "../repository/patient/diagnostic";
import { ClientResponseError } from "pocketbase";
import { routerContext, RouterContext } from "../context/router.context";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalSheetNav } from "./modal-sheet-nav";
import { Page } from "../components/component";
import { addHours, format, formatISO } from "date-fns";
import { pt } from "date-fns/locale";
import { ObservableDiagnostic } from "../stores/diagnostics.store";
import { sessionsStore } from "../stores/sessions.store";

const formSchema = CreateSessionSchema.pick({
  name: true,
  day: true,
  startTime: true,
  endTime: true,
});

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-create-session")
export class ModalNewSession extends Page {
  @state() private _startTime: string | null = null;
  @state() private _endTime: string | null = null;
  @state() private _day: string | null = null;

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: routerContext }) router!: RouterContext;

  diagnostic!: ObservableDiagnostic;

  #modalTimePickerRef: Ref<ModalSheetNav> = createRef();
  #modalDatePickerRef: Ref<ModalSheetNav> = createRef();

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      const year = Number(format(this._day!, "yyyy"));
      const month = Number(format(this._day!, "MM"));
      const date = Number(format(this._day!, "dd"));
      const startHour = Number(format(this._startTime!, "HH"));
      const startMinutes = Number(format(this._startTime!, "mm"));
      const endHour = Number(format(this._endTime!, "HH"));
      const endMinutes = Number(format(this._endTime!, "mm"));

      if (startHour > endHour) {
        this.#controller.setError("startTime", "A hora de início não pode ser maior que a hora de fim");
        return;
      } else if (startHour === endHour && startMinutes > endMinutes) {
        this.#controller.setError("startTime", "A hora de início não pode ser maior que a hora de fim");
        return;
      } else if (startHour === endHour && startMinutes === endMinutes) {
        this.#controller.setError("startTime", "A hora de início não pode ser igual à hora de fim");
        return;
      }

      // const diagnosticId = this.router.getParam("diagnosticId");

      const isoStart = formatISO(new Date(year, month - 1, date, startHour, startMinutes));
      const isoEnd = formatISO(new Date(year, month - 1, date, endHour, endMinutes));

      try {
        const createSessionData: CreateSession = {
          ...values,
          day: this._day!,
          startTime: isoStart,
          endTime: isoEnd,
          diagnostic: this.diagnostic.id,
          profissional: this.auth.user.id,
        };
        await sessionsStore.createSession(this.diagnostic, createSessionData);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.status === 403) {
            const alert = await alertController.create({
              header: "Erro",
              message: "Não tem permissões suficientes para criar uma sessão.",
              buttons: ["Ok"],
            });
            await alert.present();
          } else {
            const alert = await alertController.create({
              header: "Erro",
              message: "Ocorreu um erro ao criar a sessão",
              buttons: ["OK"],
            });
            alert.present();
          }
        }
      }
    },
  });

  getTime(date: string) {
    if (!date) return;
    const time = format(date, "HH:mm", { locale: pt });
    return time;
  }

  getDay(date: string) {
    if (!date) return;
    const time = format(date, "dd-MM-yyyy", { locale: pt });
    return time;
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content class="space-y-4" fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => modalController.dismiss()}">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  voltar
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">Nova Sessão</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form
          id="form-session-create"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none" class="space-y-4">
            <div>
              <span class="font-semibold font-display">Nome</span>
              <ion-item class="mt-1">
                <ion-input autocapitalize="on" type="text" placeholder="Nome da sessão" ${register("name")}> </ion-input>
              </ion-item>
              ${renderError("name")}
            </div>
            <div>
              <span class="font-semibold font-display">Dia da sessão</span>
              <ion-item class="mt-1">
                <ion-input
                  placeholder="01-01-2000"
                  readonly
                  @click=${() =>
                    this.#modalDatePickerRef.value?.open({
                      date: this._day,
                      setDate: (date: string) => {
                        this._day = date;
                        form.change("day", this.getDay(this._day));
                      },
                    })}
                  ${register("day")}>
                </ion-input>
              </ion-item>
              ${renderError("day")}
            </div>
            <div class="flex items-center space-x-4 w-full">
              <div class="w-full">
                <span class="font-semibold font-display">Início</span>
                <ion-item class="mt-1">
                  <ion-input
                    @click=${() =>
                      this.#modalTimePickerRef.value?.open({
                        date: this._startTime,
                        setDate: (date: string) => {
                          this._startTime = date;
                          form.change("startTime", this.getTime(this._startTime));
                          this._endTime = formatISO(addHours(new Date(this._startTime), 1));
                          form.change("endTime", this.getTime(this._endTime));
                          form.mutators.setFieldData?.("startTime", { error: "" });
                          form.mutators.setFieldData?.("endTime", { error: "" });
                        },
                      })}
                    readonly
                    placeholder="00:00"
                    class="w-full"
                    ${register("startTime")}>
                  </ion-input>
                </ion-item>
                ${renderError("startTime")}
              </div>
              <div class="w-full">
                <span class="font-semibold font-display">Fim</span>
                <ion-item class="mt-1">
                  <ion-input
                    @click=${() =>
                      this.#modalTimePickerRef.value?.open({
                        date: this._endTime,
                        setDate: (date: string) => {
                          this._endTime = date;
                          form.change("endTime", this.getTime(this._endTime));
                          form.mutators.setFieldData?.("endTime", { error: "" });
                        },
                      })}
                    readonly
                    placeholder="00:00"
                    class="w-full"
                    ${register("endTime")}>
                  </ion-input>
                </ion-item>
                ${renderError("endTime")}
              </div>
            </div>
          </ion-list>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-session-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Criar Sessão</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-sheet-nav
        auto-height
        root="modal-time-picker"
        border-radius=${0}
        .handle=${false}
        ${ref(this.#modalTimePickerRef)}></modal-sheet-nav>

      <modal-sheet-nav
        auto-height
        root="modal-date-picker"
        border-radius=${0}
        .handle=${false}
        ${ref(this.#modalDatePickerRef)}></modal-sheet-nav>
    `;
  }
}
