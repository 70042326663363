import type { IonInput } from "@ionic/core/components/ion-input";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import Fuse from "fuse.js";
import { html } from "lit";
import { state } from "lit/decorators.js";
import { live } from "lit/directives/live.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { Page, required } from "../components/component";
import { RouterContext, routerContext } from "../context/router.context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { customElement } from "../element";
import { libraryRepository } from "../repository/library/library.repository";
import type { FilterStore } from "../stores/filter.store";
import type { ModalSheetNav } from "./modal-sheet-nav";
import { alertController } from "@ionic/core";
import type { Equipment } from "../repository/library/exercises";
import type { ObservableLibrary } from "../stores/libraries.store";

const asFilter = (equipment: Equipment) => ({ id: equipment.id, name: equipment.equipment });

@customElement("modal-library-equipments")
export class ModalLibraryEquipments extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;
  @consume({ context: routerContext }) router!: RouterContext;

  #modalCreateEquipmentRef: Ref<ModalSheetNav> = createRef();

  FILTER_KEY = "equipments";

  @required() filterStore!: FilterStore;
  library?: ObservableLibrary;

  @state() private _query = "";
  @state() private _equipments: Equipment[] = [];
  @state() private _filteredEquipments: Equipment[] = [];

  #equipments = new Task(this, {
    task: async () => {
      const libraryId = this.library ? this.library.id : this.router.getParam("id");
      this._equipments = await libraryRepository.getLibraryEquipments(libraryId);
      return this._equipments;
    },
    args: () => [],
  });

  setSearchInputValue(e: InputEvent) {
    const input = e.target as IonInput;
    this._query = input.value?.toString() || "";

    if (this._query.length > 0) {
      const options = {
        keys: ["equipment"],
      };
      if (this.#equipments.value) {
        const fuse = new Fuse(this._equipments, options);
        this._filteredEquipments = fuse.search(this._query).map((result) => result.item);
      }
    } else {
      this._filteredEquipments = [];
    }
  }

  async removeEquipment(equipment: Equipment) {
    const count = equipment.count || 0;
    if (count > 0) {
      alertController
        .create({
          header: "Remover Equipamento",
          message: `O equipamento "${equipment.equipment}" está a ser usado em ${count} exercícios. Deseja continuar?`,
          buttons: [
            {
              text: "Cancelar",
              role: "cancel",
            },
            {
              text: "Remover",
              role: "destructive",
              handler: () => {
                this.filterStore.remove(asFilter(equipment));
                libraryRepository.removeEquipment(equipment.id);
                this._equipments = this._equipments.filter((e) => e.id !== equipment.id);
                this._filteredEquipments = this._filteredEquipments.filter((c) => c.id !== equipment.id);
                this.requestUpdate();
              },
            },
          ],
        })
        .then((alert) => alert.present());
    } else {
      this.filterStore.remove(asFilter(equipment));
      libraryRepository.removeEquipment(equipment.id);
      this._equipments = this._equipments.filter((m) => m.id !== equipment.id);
      this._filteredEquipments = this._filteredEquipments.filter((m) => m.id !== equipment.id);
      this.requestUpdate();
    }
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              @click=${() => {
                this.filterStore.rollback();
                this.navigator.goBack();
              }}
              class="font-semibold">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="-ml-2 mr-1">
                <path
                  d="M14 16L10 12L14 8"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              voltar</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">Equipamentos</ion-title>
          <ion-buttons slot="end">
            <ion-button
              @click="${() => {
                this.filterStore.commit();
                this.navigator.goBack();
              }}"
              class="font-semibold"
              >Confirmar</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4" fullscreen>
        <ion-item class="mt-6">
          <span aria-hidden="true" slot="start" class="flex items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <ion-input
            id="search-equipment"
            clear-on-edit
            type="search"
            clear-input
            placeholder="Pesquisar equipamentos..."
            .value=${live(this._query)}
            @ionInput="${(e: InputEvent) => this.setSearchInputValue(e)}"></ion-input>
        </ion-item>

        ${when(
          this._query.length > 0,
          () => {
            return html`
              <div class="mt-4">
                <div class="mb-2 font-semibold">${this._query}</div>
                <ion-button
                  color="secondary"
                  expand="block"
                  size="small"
                  shape="round"
                  class="font-bold"
                  @click="${() =>
                    this.#modalCreateEquipmentRef.value?.open({
                      equipment: this._query,
                      onSubmit: async (equipment: Equipment) => {
                        this._equipments = [...this._equipments, equipment];
                        this._query = "";
                        this.requestUpdate();
                      },
                    })}">
                  Criar Equipamento
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    class="ml-2">
                    <path
                      d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </ion-button>
              </div>
              <ion-list class="mt-4">
                ${this._filteredEquipments.map((equipment, index) => {
                  const isSelected = this.filterStore.isActive(asFilter(equipment));
                  return html`
                    <ion-item
                      id=${index}
                      button
                      lines=${index < this._filteredEquipments.length - 1 ? "full" : "none"}
                      .detail=${false}
                      detail-icon="/assets/icons/check.svg"
                      class="no-p no-inline-p"
                      @click="${() => {
                        this.filterStore.stage(this.FILTER_KEY, asFilter(equipment));
                      }}"
                      style="--detail-icon-color: var(--ion-color-success); --detail-icon-opacity: 1;">
                      <div class="py-4 w-full flex items-center justify-between">
                        <ion-label class="font-semibold">${equipment.equipment}</ion-label>
                        ${when(!isSelected, () => html` <ion-badge>${equipment.count}</ion-badge> `)}
                      </div>
                    </ion-item>
                  `;
                })}
              </ion-list>
            `;
          },
          () => {
            return html`
              <ion-list>
                ${this.#equipments.render({
                  pending: () =>
                    html` ${Array.from(
                      { length: 5 },
                      () => html`
                        <ion-item class="no-p no-inline-p">
                          <div class="py-4 w-full flex items-center justify-between">
                            <ion-skeleton-text animated style="width: 50%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 10%; height: 20px"></ion-skeleton-text>
                          </div>
                        </ion-item>
                      `,
                    )}`,
                  error: () => {
                    return html` <span class="text-danger">Erro ao carregar os equipamentos</span>`;
                  },
                  complete: () => html`
                    ${repeat(
                      this._equipments,
                      (equipment) => equipment.id,
                      (equipment) => {
                        const isSelected = this.filterStore.isActive(asFilter(equipment));
                        return html`
                          <ion-item-sliding>
                            <ion-item
                              button
                              .detail=${isSelected}
                              detail-icon="/assets/icons/check.svg"
                              class="no-p no-inline-p"
                              @click=${() => {
                                this.filterStore.stage(this.FILTER_KEY, asFilter(equipment));
                              }}
                              style="--detail-icon-color: var(--ion-color-success); --detail-icon-opacity: 1;">
                              <div class="py-4 w-full flex items-center justify-between">
                                <ion-label class="font-semibold">${equipment.equipment}</ion-label>
                                ${when(!isSelected, () => html` <ion-badge>${equipment.count}</ion-badge> `)}
                              </div>
                            </ion-item>

                            <ion-item-options>
                              <ion-item-option>
                                <ion-button
                                  @click=${(e: Event) => {
                                    this.removeEquipment(equipment);
                                    // @ts-ignore
                                    e?.target?.closest("ion-item-sliding")?.close();
                                  }}
                                  size="chip"
                                  color="danger"
                                  shape="round"
                                  class="open-move-patient-modal"
                                  style="--padding-start: 8px; --padding-end: 8px;"
                                  >Remover</ion-button
                                >
                              </ion-item-option>
                            </ion-item-options>
                          </ion-item-sliding>
                        `;
                      },
                    )}
                  `,
                })}
              </ion-list>
            `;
          },
        )}
      </ion-content>

      <modal-sheet-nav
        auto-height
        root="modal-create-exercise-equipment"
        ${ref(this.#modalCreateEquipmentRef)}></modal-sheet-nav>
    `;
  }
}
