import { z } from "zod";
import { UserSchema } from "../user/user";
import { PatientEvaluationSchema } from "../report/report";
import { file } from "../shared/file";
import { unsafeParse } from "../shared/unsafeParse";
import { record } from "../shared/record";
import { ExerciseSchema, PublicExerciseSchema } from "../library/exercises";

/* ****************+ EXERCISE PLANS ******************* */

export const ExercisePlanRecordSchema = record({
  session: z.string(),
  position: z.number(),
  exercise: z.string().optional(),
  exercisePublic: z.string().optional(),
  series: z.number().optional(),
  repetitions: z.string().optional(),
  weight: z.number().optional(),
  weightUnit: z.string().optional(),
  rest: z.number().optional(),
  duration: z.number().optional(),
  description: z.string().optional(),
});
export interface ExercisePlanRecord extends z.infer<typeof ExercisePlanRecordSchema> {}

export const ExercisePlanSchema = ExercisePlanRecordSchema.extend({
  exercise: ExerciseSchema.optional(),
  exercisePublic: PublicExerciseSchema.optional(),
}).omit({
  expand: true,
});
export interface ExercisePlan extends z.infer<typeof ExercisePlanSchema> {}
export function createExercisePlan(data: Record<string, unknown>) {
  return unsafeParse(ExercisePlanSchema, data);
}

export const ContentSchema = file(
  {
    size: 10 * 1024 * 1024,
    allowedFileTypes: [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/heic",
      "image/heif",
      "image/gif",
      "video/mp4",
      "video/webm",
    ],
  },
  "A imagem deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif",
);
export interface Content extends z.infer<typeof ContentSchema> {}

export const CreateExercisePlanSchema = z.object({
  session: z.string(),
  position: z.preprocess(Number, z.number()),
  exercise: z.string().optional(),
  exercisePublic: z.string().optional(),
  series: z.preprocess(Number, z.number()).optional(),
  repetitions: z.string().optional(),
  weight: z.preprocess(Number, z.number()).optional(),
  weightUnit: z.string().optional(),
  rest: z.preprocess(Number, z.number()).optional(),
  duration: z.preprocess(Number, z.number()).optional(),
  description: z.string().optional(),
});
export interface CreateExercisePlan extends z.infer<typeof CreateExercisePlanSchema> {}

export const UpdateExercisePlanSchema = z.object({
  position: z.preprocess(Number, z.number()).optional(),
  exercise: z.string().optional(),
  exercisePublic: z.string().optional(),
  series: z.preprocess(Number, z.number()).optional(),
  repetitions: z.string().optional(),
  weight: z.preprocess(Number, z.number()).optional(),
  weightUnit: z.string().optional(),
  rest: z.preprocess(Number, z.number()).optional(),
  duration: z.preprocess(Number, z.number()).optional(),
  description: z.string().optional(),
});
export interface UpdateExercisePlan extends z.infer<typeof UpdateExercisePlanSchema> {}

/* ****************+ SESSIONS ******************* */

export const SessionRecordSchema = record({
  name: z.string(),
  day: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  treatment: z.string().optional(),
  patientNotes: z.string().optional(),
  notes: z.string().optional(),
  diagnostic: z.string(),
  profissional: z.string(),
});
export interface SessionRecord extends z.infer<typeof SessionRecordSchema> {}

export const SessionSchema = SessionRecordSchema.extend({
  exercisesPlan: z.array(ExercisePlanSchema).default([]),
  evaluations: z.array(PatientEvaluationSchema).default([]),
}).omit({
  expand: true,
});
export interface Session extends z.infer<typeof SessionSchema> {}
export function createSession(data: unknown) {
  return unsafeParse(SessionSchema, data);
}

export const CreateSessionSchema = z.object({
  name: z.string({ required_error: "O nome é obrigatório" }),
  // omit day since it's editable, and it's inserted via trigger
  day: z.string().optional(),
  startTime: z.string({ required_error: "A hora de início é obrigatória" }),
  endTime: z.string({ required_error: "A hora de fim é obrigatória" }),
  diagnostic: z.string(),
  profissional: z.string(),
});
export interface CreateSession extends z.infer<typeof CreateSessionSchema> {}

export const UpdateSessionSchema = z
  .object({
    name: z.string(),
    // omit day since it's editable, and it's inserted via trigger
    day: z.string(),
    startTime: z.string(),
    endTime: z.string(),
    treatment: z.string(),
    notes: z.string(),
    patientNotes: z.string(),
    diagnostic: z.string(),
  })
  .partial();
export interface UpdateSession extends z.infer<typeof UpdateSessionSchema> {}

/* ****************+ DIAGNOSTICS ******************* */

export const DiagnosticRecordSchema = record({
  name: z.string(),
  description: z.string(),
  isCompleted: z.boolean(),
  patient: z.string(),
  professionals: z.array(z.string()).default([]),
});
export interface DiagnosticRecord extends z.infer<typeof DiagnosticRecordSchema> {}

export const DiagnosticSchema = DiagnosticRecordSchema.extend({
  professionals: z.array(UserSchema).default([]),
  sessions: z.array(SessionSchema).default([]),
}).omit({
  expand: true,
});
export interface Diagnostic extends z.infer<typeof DiagnosticSchema> {}
export function createDiagnostic(data: unknown) {
  return unsafeParse(DiagnosticSchema, data);
}

export const CreateDiagnosticSchema = z.object({
  name: z.string(),
  description: z.string(),
  isCompleted: z.boolean(),
  patient: z.string(),
  professionals: z.array(z.string()),
});
export interface CreateDiagnostic extends z.infer<typeof CreateDiagnosticSchema> {}

export const UpdateDiagnosticSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  isCompleted: z.boolean().optional(),
  patient: z.string().optional(),
  professionals: z.array(z.string()).optional(),
});
export interface UpdateDiagnostic extends z.infer<typeof UpdateDiagnosticSchema> {}
