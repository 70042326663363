import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import type { z } from "zod";
import { Page, required } from "../components/component";
import { UpdateTemplateExercisePlanExerciseSchema } from "../repository/templates/templates";
import { classMap } from "lit/directives/class-map.js";
import { ModalChooseLibrary } from "./modal-choose-library";
import { SelectStore } from "../stores/select.store";
import { ObservableExercise } from "../stores/exercises.store";
import { when } from "lit/directives/when.js";
import { RouterContext, routerContext } from "../context/router.context";
import { alertController, pickerController } from "@ionic/core";
import type { IonInput } from "@ionic/core/components/ion-input";
import {
  ObservableTemplateExercise,
  ObservableTemplateExercisePlan,
  templatesStore,
} from "../stores/templateExercisePlan.store";
import { ClientResponseError } from "pocketbase";
import type { PublicExercise } from "../repository/library/exercises";
import { pb } from "../pocketbase";

const formSchema = UpdateTemplateExercisePlanExerciseSchema;

type formValues = z.infer<typeof formSchema>;

const seriesIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M6.66675 11.3334H13.3334M2.66675 10.457V10.3334C2.66675 9.78113 3.11446 9.33341 3.66675 9.33341H3.69377C4.23113 9.33341 4.66689 9.76904 4.66689 10.3064C4.66689 10.5403 4.59088 10.768 4.45053 10.9552L2.66675 13.3335L4.66675 13.3334M6.66675 8.00008H13.3334M6.66675 4.66675H13.3334M2.66675 3.33341L4.00008 2.66675V6.66675"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const repetitionsIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M6.66675 10.6667H3.33341V14M9.33341 5.33333H12.6667V2M3.05542 6.00227C3.42921 5.07711 4.05504 4.27536 4.86178 3.68815C5.66852 3.10094 6.62465 2.75168 7.61992 2.68034C8.61518 2.609 9.6099 2.81827 10.4921 3.28441C11.3744 3.75055 12.1076 4.45503 12.6095 5.31742M12.9451 9.99805C12.5713 10.9232 11.9455 11.725 11.1388 12.3122C10.332 12.8994 9.37686 13.2482 8.38159 13.3195C7.38633 13.3909 6.39079 13.1816 5.50854 12.7155C4.6263 12.2494 3.89263 11.545 3.39071 10.6826"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const durationIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M8.00008 10.6666V8.66659M8.00008 8.66659V6.66659M8.00008 8.66659H6.00008M8.00008 8.66659H10.0001M14.0001 3.99992L12.6667 2.66659M6.66675 1.33325H9.33341M8.00008 13.9999C5.05456 13.9999 2.66675 11.6121 2.66675 8.66659C2.66675 5.72107 5.05456 3.33325 8.00008 3.33325C10.9456 3.33325 13.3334 5.72107 13.3334 8.66659C13.3334 11.6121 10.9456 13.9999 8.00008 13.9999Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const weightIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <g clip-path="url(#clip0_348_6931)">
      <path
        d="M4.33325 4.33325L11.6666 11.6666"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M13.9999 13.9999L13.3333 13.3333"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2 2L2.66667 2.66667"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M12 14.6667L14.6667 12"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.33325 3.99992L3.99992 1.33325"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2 6.66667L6.66667 2"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M9.33325 13.9999L13.9999 9.33325"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_348_6931">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;
const restIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M9.33333 6V10M6.66667 6V10M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const descriptionIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M8 7.33333V10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM8.0332 5.33333V5.4L7.9668 5.40013V5.33333H8.0332Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

@customElement("modal-edit-template-exercise")
export class ModalEditTemplateExercise extends Page {
  @state() private _fields = new Map([
    ["series", { label: "Séries", selected: false, icon: seriesIcon }],
    ["repetitions", { label: "Repetições", selected: false, icon: repetitionsIcon }],
    ["duration", { label: "Duração", selected: false, icon: durationIcon }],
    ["rest", { label: "Repouso", selected: false, icon: restIcon }],
    ["weight", { label: "Peso/Carga", selected: false, icon: weightIcon }],
    ["description", { label: "Descrição", selected: false, icon: descriptionIcon }],
  ]);

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;
  @consume({ context: routerContext }) router!: RouterContext;

  @required() exercise!: ObservableTemplateExercise;
  @required() template!: ObservableTemplateExercisePlan;

  private flag = false;

  exercises = new SelectStore<PublicExercise | ObservableExercise>();

  connectedCallback(): void {
    super.connectedCallback();

    if (this.exercise.exercise) {
      this.exercises.add(this.exercise.exercise);
    }
    if (this.exercise.series) {
      this.#controller.form.change("series", this.exercise.series);
      this._fields.get("series")!.selected = true;
    }
    if (this.exercise.repetitions) {
      this.#controller.form.change("repetitions", this.exercise.repetitions);
      this._fields.get("repetitions")!.selected = true;
    }
    if (this.exercise.weight) {
      this.#controller.form.change("weight", this.exercise.weight);
      this._fields.get("weight")!.selected = true;
    }
    if (this.exercise.weightUnit) {
      this.#controller.form.change("weightUnit", this.exercise.weightUnit);
      this._fields.get("weight")!.selected = true;
      const weightUnitInput = this.querySelector("#weightUnit") as IonInput;
      if (weightUnitInput) {
        if (this.exercise.weightUnit === "kg") {
          weightUnitInput.value = "Kilograma (kg)";
        } else if (this.exercise.weightUnit === "lbs") {
          weightUnitInput.value = "Libra (lbs)";
        } else if (this.exercise.weightUnit === "g") {
          weightUnitInput.value = "Grama (g)";
        } else if (this.exercise.weightUnit === "km/h") {
          weightUnitInput.value = "Kms por hora (km/h)";
        }
      }
    }
    if (this.exercise.rest) {
      this.#controller.form.change("rest", this.exercise.rest);
      this._fields.get("rest")!.selected = true;
      const restInput = this.querySelector("#rest") as IonInput;
      if (restInput) {
        const minutes = Math.floor(this.exercise.rest / 60);
        const seconds = this.exercise.rest % 60;
        restInput.value = `${minutes.toString().padStart(2, "0")}m:${seconds.toString().padStart(2, "0")}s`;
      }
    }
    if (this.exercise.duration) {
      this.#controller.form.change("duration", this.exercise.duration);
      this._fields.get("duration")!.selected = true;
      const durationInput = this.querySelector("#duration") as IonInput;
      if (durationInput) {
        const minutes = Math.floor(this.exercise.duration / 60);
        const seconds = this.exercise.duration % 60;
        durationInput.value = `${minutes.toString().padStart(2, "0")}m:${seconds.toString().padStart(2, "0")}s`;
      }
    }
    if (this.exercise.description) {
      this.#controller.form.change("description", this.exercise.description);
      this._fields.get("description")!.selected = true;
    }
  }

  #controller = new FinalFormController<formValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      try {
        if(!this._fields.get("series")?.selected) {
          values.series = 0;
        }
        if(!this._fields.get("repetitions")?.selected) {
          console.log("repetitions");
          values.repetitions = "";
        }
        if(!this._fields.get("duration")?.selected) {
          values.duration = 0;
        }
        if(!this._fields.get("rest")?.selected) {
          values.rest = 0;
        }
        if(!this._fields.get("weight")?.selected) {
          values.weight = 0;
          values.weightUnit = "";
        }
        if(!this._fields.get("description")?.selected) {
          values.description = "";
        }
        await templatesStore.updateExercise(this.exercise, values);
        this.navigator.goBack();
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.status === 403) {
            const alert = await alertController.create({
              header: "Erro",
              message: "Não tem permissões suficientes para atualizar um exercício.",
              buttons: ["Ok"],
            });
            await alert.present();
          }
        }
        const alert = await alertController.create({
          header: "Erro",
          message: "Ocorreu um erro ao atualizar o exercício",
          buttons: ["OK"],
        });
        await alert.present();
        console.error(error);
      }
    },
  });

  toggleSelected(key: string) {
    const field = this._fields.get(key);
    if (field) {
      field.selected = !field.selected;
      this._fields.set(key, field); // Ensure the map is updated
    }
    this.requestUpdate();
  }

  field(key: string) {
    return this._fields.get(key);
  }

  isSelected(key: string) {
    const field = this.field(key);
    if (!field) {
      throw new Error(`Field ${key} not found`);
    }
    return field.selected;
  }

  deleteExercise() {
    alertController
      .create({
        header: "Apagar Exercício",
        message: "Tem a certeza que pretende apagar este exercício?",
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
          },
          {
            text: "Apagar",
            role: "destructive",
            handler: async () => {
              try {
                await templatesStore.deleteExercise(this.exercise);
                this.requestUpdate();
                this.navigator.goBack();
              } catch (error) {
                if (error instanceof ClientResponseError) {
                  if (error.status === 403) {
                    const alert = await alertController.create({
                      header: "Erro",
                      message: "Não tem permissões suficientes para apagar um exercício.",
                      buttons: ["Ok"],
                    });
                    await alert.present();
                  }
                }
                const alert = await alertController.create({
                  header: "Erro",
                  message: "Ocorreu um erro ao apagar o exercício",
                  buttons: ["OK"],
                });
                await alert.present();
              }
            },
          },
        ],
      })
      .then((alert) => alert.present());
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    const selectedExercise = this.exercises.selected[0];
    if (selectedExercise) {
      if (selectedExercise instanceof ObservableExercise) {
        this.#controller.form.change("exercise", selectedExercise.id);
      } else {
        this.#controller.form.change("exercisePublic", selectedExercise.id);
      }
    }

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m"
                @click="${() => this.navigator.goBack()}">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">Exercício</ion-title>
          ${when(
            this.template.canEdit(this.auth.user),
            () => html`
              <ion-buttons slot="end">
                <div class="flex items-center">
                  <ion-button
                    @click=${() => this.deleteExercise()}
                    color="danger"
                    style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                    fill="clear"
                    class="font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="mr-1">
                      <path
                        d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    Apagar
                  </ion-button>
                </div>
              </ion-buttons>
            `,
          )}
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4 no-px" fullscreen>
        <!-- -------------- FILTERS -------------- -->

        <div class="space-y-3 !mt-0">
          <div class="w-full h-1 bg-accent-7"></div>
          <div class="px-4 pb-1 space-y-2">
            <p class="font-display font-semibold">Escolha os campos</p>
            <div class="flex flex-wrap gap-2 items-center">
              ${Array.from(this._fields.entries()).map(
                ([key, field]) => html`
                  <ion-chip
                    color=${field.selected ? "primary" : "light"}
                    @click=${() => {
                      if (this.template.canEdit(this.auth.user)) this.toggleSelected(key);
                    }}
                    class="px-2.5 py-1.5 text-sm font-medium">
                    ${field.icon} ${field.label}
                  </ion-chip>
                `,
              )}
            </div>
          </div>
          <div class="w-full h-1 bg-accent-7"></div>
        </div>

        <!-- ------------------- CONTENT ----------------- -->

        <form
          id="form-update-template-exercise"
          class="px-4 space-y-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <div class="mt-4">
            <span class="font-semibold font-display">Exercício</span>
            <div ${register("exercise")}>
              ${when(this.exercises.selected.length > 0, () => {
                const exercise = this.exercises.selected[0];
                if (!exercise) {
                  return nothing;
                }
                return html`
                  <ion-item .detail=${false} class="no-p mt-1.5" style="--border-color: transparent;">
                    <div class="flex items-center justify-between w-full">
                      <div class="space-x-2 flex items-center">
                        ${when(
                          exercise instanceof ObservableExercise,
                          () => html`
                            <xt-avatar
                              src=${(exercise as ObservableExercise).thumbnail({ width: 128, height: 128 })}
                              name=${exercise.name}></xt-avatar>
                          `,
                          () => {
                            const thumbnail = pb.files.getUrl(exercise, (exercise as PublicExercise).thumbnail, {
                              thumb: "128x128",
                            });
                            return html` <xt-avatar src=${thumbnail} name=${exercise.name}></xt-avatar> `;
                          },
                        )}
                        <div class="col items-start">
                          <p class="font-semibold line-clamp-1">${exercise?.name}</p>
                        </div>
                      </div>
                      ${when(
                        this.template.canEdit(this.auth.user),
                        () => html`
                          <ion-button
                            slot="end"
                            @click="${() => this.exercises.remove(exercise)}"
                            size="chip"
                            color="dark"
                            shape="round"
                            style="--padding-start: 8px; --padding-end: 8px;"
                            >Remover</ion-button
                          >
                        `,
                      )}
                    </div>
                  </ion-item>
                `;
              })}
            </div>
            ${when(
              this.template.canEdit(this.auth.user),
              () => html`
                <div class="mt-1.5">
                  <ion-button
                    size="small"
                    color="secondary"
                    expand="block"
                    shape="round"
                    @click=${() =>
                      this.navigator.push(ModalChooseLibrary, {
                        exercises: this.exercises,
                      })}
                    >Adicionar Exercício
                    <span class="mt-0.5 ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </span>
                  </ion-button>
                </div>
              `,
            )}
            ${renderError("exercise")}
          </div>

          <div ?hidden=${!this.isSelected("series") && !this.isSelected("repetitions")}>
            <div class="grid grid-cols-2 gap-x-4">
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("series") && this.isSelected("repetitions"),
                  "col-span-2": !this.isSelected("series") || !this.isSelected("repetitions"),
                })}>
                <div ?hidden=${!this.isSelected("series")}>
                  <span class="font-semibold font-display text-sm">Séries</span>
                  <ion-item class="mt-1">
                    <ion-input
                      readonly=${!this.template.canEdit(this.auth.user)}
                      ${register("series")}
                      type="number"
                      placeholder="4"></ion-input>
                  </ion-item>
                  ${renderError("series")}
                </div>
              </div>
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("series") && this.isSelected("repetitions"),
                  "col-span-2": !this.isSelected("series") || !this.isSelected("repetitions"),
                })}>
                <div ?hidden=${!this.isSelected("repetitions")}>
                  <span class="font-semibold font-display text-sm">Repetições</span>
                  <ion-item class="mt-1">
                    <ion-input
                      readonly=${!this.template.canEdit(this.auth.user)}
                      ${register("repetitions")}
                      type="text"
                      placeholder="12"></ion-input>
                  </ion-item>
                  ${renderError("repetitions")}
                </div>
              </div>
            </div>
          </div>

          <div ?hidden=${!this.isSelected("duration") && !this.isSelected("rest")}>
            <div class="grid grid-cols-2 gap-x-4">
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("duration") && this.isSelected("rest"),
                  "col-span-2": !this.isSelected("duration") || !this.isSelected("rest"),
                })}>
                <div ?hidden=${!this.isSelected("duration")}>
                  <span class="font-semibold font-display text-sm">Duração</span>
                  <ion-item class="mt-1">
                    <ion-input
                      readonly
                      type="text"
                      id="duration"
                      placeholder="60"
                      @click=${async (e: Event) => {
                        if (!this.template.canEdit(this.auth.user)) {
                          return;
                        }

                        if (this.flag) {
                          return;
                        }
                        this.flag = true;

                        const durationInput = e.target as IonInput;

                        const currentDuration = formState.values.duration || 0;

                        const minutes = Math.floor(currentDuration / 60);
                        const seconds = currentDuration % 60;

                        pickerController
                          .create({
                            columns: [
                              {
                                name: "minutes",
                                options: Array.from({ length: 60 }, (_, i) => ({
                                  text: String(i).padStart(2, "0"),
                                  value: i,
                                })),
                                selectedIndex: minutes,
                              },
                              {
                                name: "seconds",
                                options: Array.from({ length: 60 }, (_, i) => ({
                                  text: String(i).padStart(2, "0"),
                                  value: i,
                                })),
                                selectedIndex: seconds,
                              },
                            ],
                            buttons: [
                              {
                                text: "Cancelar",
                                role: "cancel",
                              },
                              {
                                text: "Confirmar",
                                handler: (value) => {
                                  const { minutes, seconds } = value;
                                  form.change("duration", minutes.value * 60 + seconds.value);
                                  durationInput.value = `${minutes.text}m:${seconds.text}s`;
                                },
                              },
                            ],
                          })
                          .then((picker) => {
                            picker.present();
                            picker.addEventListener("didDismiss", () => {
                              if (this.flag) {
                                setTimeout(() => {
                                  this.flag = false;
                                }, 0);
                              }
                            });
                          });
                      }}></ion-input>
                  </ion-item>
                  <ion-input hidden ${register("duration")} type="number"></ion-input>
                  ${renderError("duration")}
                </div>
              </div>
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("duration") && this.isSelected("rest"),
                  "col-span-2": !this.isSelected("duration") || !this.isSelected("rest"),
                })}>
                <div ?hidden=${!this.isSelected("rest")}>
                  <span class="font-semibold font-display text-sm">Repouso</span>
                  <ion-item
                    class="mt-1"
                    @click=${(e: Event) => {
                      if (!this.template.canEdit(this.auth.user)) {
                        return;
                      }
                      e.preventDefault();
                      if (this.flag) {
                        return;
                      }
                      this.flag = true;

                      const currentRest = formState.values.rest || 0;

                      const minutes = Math.floor(currentRest / 60);
                      const seconds = currentRest % 60;

                      pickerController
                        .create({
                          columns: [
                            {
                              name: "minutes",
                              options: Array.from({ length: 60 }, (_, i) => ({
                                text: String(i).padStart(2, "0"),
                                value: i,
                              })),
                              selectedIndex: minutes,
                            },
                            {
                              name: "seconds",
                              options: Array.from({ length: 60 }, (_, i) => ({
                                text: String(i).padStart(2, "0"),
                                value: i,
                              })),
                              selectedIndex: seconds,
                            },
                          ],
                          buttons: [
                            {
                              text: "Cancelar",
                              role: "cancel",
                            },
                            {
                              text: "Confirmar",
                              handler: (value) => {
                                const { minutes, seconds } = value;
                                form.change("rest", minutes.value * 60 + seconds.value);
                                const restInput = document.getElementById("rest") as IonInput;
                                restInput.value = `${minutes.text}m:${seconds.text}s`;
                              },
                            },
                          ],
                        })
                        .then((picker) => {
                          picker.present();
                          picker.addEventListener("didDismiss", () => {
                            if (this.flag) {
                              setTimeout(() => {
                                this.flag = false;
                              }, 0);
                            }
                          });
                        });
                    }}>
                    <ion-input readonly id="rest" type="text" placeholder="45"></ion-input>
                  </ion-item>
                  <ion-input hidden ${register("rest")} type="number"></ion-input>
                  ${renderError("rest")}
                </div>
              </div>
            </div>
          </div>

          <div ?hidden=${!this.isSelected("weight")}>
            <div class="grid grid-cols-2 gap-x-4">
              <div class="col-span-1">
                <span class="font-semibold font-display text-sm">Peso/Carga</span>
                <ion-item class="mt-1">
                  <ion-input
                    readonly=${!this.template.canEdit(this.auth.user)}
                    ${register("weight")}
                    type="number"
                    step=".01"
                    placeholder="10"></ion-input>
                </ion-item>
                ${renderError("weight")}
              </div>
              <div class="col-span-1">
                <span class="font-semibold font-display text-sm">Unidade</span>
                <ion-item
                  class="mt-1"
                  @click=${(e: Event) => {
                    if (!this.template.canEdit(this.auth.user)) {
                      return;
                    }
                    e.preventDefault();
                    if (this.flag) {
                      return;
                    }
                    this.flag = true;
                    pickerController
                      .create({
                        columns: [
                          {
                            name: "unit",
                            options: [
                              { text: "Kilograma (kg)", value: "kg" },
                              { text: "Libra (lb)", value: "lb" },
                              { text: "Gramas (g)", value: "g" },
                              { text: "Kms por hora (km/h)", value: "km/h" },
                            ],
                          },
                        ],
                        buttons: [
                          {
                            text: "Cancelar",
                            role: "cancel",
                          },
                          {
                            text: "Confirmar",
                            handler: (value) => {
                              form.change("weightUnit", value.unit.value);
                              const weightUnitInput = document.getElementById("weightUnit") as IonInput;
                              weightUnitInput.value = value.unit.text;
                            },
                          },
                        ],
                      })
                      .then((picker) => {
                        picker.present();
                        picker.addEventListener("didDismiss", () => {
                          if (this.flag) {
                            setTimeout(() => {
                              this.flag = false;
                            }, 0);
                          }
                        });
                      });
                  }}>
                  <ion-input readonly id="weightUnit" type="text" placeholder="Kg"></ion-input>
                </ion-item>
                <ion-input hidden ${register("weightUnit")} type="text"></ion-input>
                ${renderError("weight")}
              </div>
            </div>
          </div>

          <div ?hidden=${!this.isSelected("description")}>
            <span class="font-semibold font-display text-sm">Descrição</span>
            <ion-item class="mt-1">
              <ion-textarea
                readonly=${!this.template.canEdit(this.auth.user)}
                ${register("description")}
                auto-grow
                rows="4"
                placeholder="Escreva aqui os detalhes necessários"></ion-textarea>
            </ion-item>
            ${renderError("description")}
          </div>
        </form>
      </ion-content>

      ${when(
        this.template.canEdit(this.auth.user),
        () => html`
          <ion-footer>
            <ion-toolbar class="px-4">
              <ion-button
                form="form-update-template-exercise"
                type="submit"
                ?disabled=${formState.submitting}
                expand="block"
                shape="round"
                class="font-semibold">
                Guardar Alterações
              </ion-button>
            </ion-toolbar>
          </ion-footer>
        `,
      )}
    `;
  }
}
