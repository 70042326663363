import { customElement, state } from "lit/decorators.js";
import { Page, required } from "../components/component";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { routerContext, RouterContext } from "../context/router.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Task } from "@lit/task";
import { html } from "lit";
import { when } from "lit/directives/when.js";
import type { IonInput } from "@ionic/core/components/ion-input";
import { repeat } from "lit/directives/repeat.js";
import type { CreatePatientEvaluation, EvaluationSummary, ReportSummary } from "../repository/report/report";
import { reportRepository } from "../repository/report/report.repository";
import { ObservableSession, sessionsStore } from "../stores/sessions.store";
import { alertController } from "@ionic/core";
import { FilterStore } from "../stores/filter.store";
import { sleep } from "../pocketbase";
import Fuse from "fuse.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "./modal-nav";

@customElement("modal-choose-evaluation")
export class ModalChooseEvaluation extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  @state() private _isSubmitting: boolean = false;
  @state() private _query: string = "";

  @required() report!: ReportSummary;
  @required() session!: ObservableSession;
  patientId?: string;

  filterStore = new FilterStore();

  #modalFilterReports: Ref<ModalNav> = createRef();

  #loadEvaluations = new Task(this, {
    task: async () => {
      return await reportRepository.getEvaluations(this.report.id);
    },
    args: () => [],
  });

  #filterEvaluations = new Task(this, {
    task: async () => {
      await sleep(100);

      let templates = this.#loadEvaluations.value;
      if (!templates) return [];

      if (this.filterStore.hasFilters) {
        templates = templates.filter((template) => {
          const categories = this.filterStore.filtersByKey("categories");

          const hasCategories =
            categories.length === 0 ||
            template.categories.some((category) => categories.some((c) => c.id === category.id));

          return hasCategories;
        });
      }

      if (this._query.length > 0) {
        const options = {
          keys: ["name"],
        };
        const fuse = new Fuse(templates, options);
        return fuse.search(this._query).map((result) => result.item);
      }

      return templates;
    },
    args: () => [this.filterStore.filters.length, this._query],
  });

  async addEvaluation(evaluation: EvaluationSummary) {
    if (this._isSubmitting) return;
    this._isSubmitting = true;
    try {
      const patientId = this.patientId || this.router.getParam("patientId");
      const data: CreatePatientEvaluation = {
        evaluation: evaluation.id,
        session: this.session.id,
        patient: patientId,
      };
      await sessionsStore.createPatientEvaluation(this.session, data);
      this.navigator.close();
    } catch (error) {
      console.log(error);
      const alert = await alertController.create({
        header: "Erro",
        message: "Não foi possível adicionar a avaliação",
        buttons: ["OK"],
      });
      await alert.present();
    } finally {
      this._isSubmitting = false;
    }
  }

  setSearchInputValue(e: InputEvent) {
    const input = e.target as IonInput;
    this._query = input.value?.toString() || "";
  }

  hasFilters() {
    return this.filterStore.hasFilters || this._query.length > 0;
  }

  render() {
    return html`
      <ion-content fullscreen style="--padding-bottom: 24px">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                @click=${() => this.navigator.goBack()}
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                voltar
              </ion-button>
            </ion-buttons>
            <ion-title>Escolher Avaliações</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- --------- SEARCHBAR ------------ -->
        <ion-item class="mt-2.5">
          <span aria-hidden="true" slot="start" class="flex items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <ion-input
            debounce=${150}
            @ionInput="${(e: InputEvent) => this.setSearchInputValue(e)}"
            clear-on-edit
            type="search"
            placeholder="Pesquisar avaliações"></ion-input>
          <ion-button
            shape="round"
            size="chip"
            slot="end"
            @click=${() => {
              this.#modalFilterReports.value?.open({
                filterStore: this.filterStore,
                report: this.report,
                disabled: ["type"],
              });
            }}>
            <span class="flex items-center mr-0.5">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                  d="M11.6663 3.26672C11.6663 2.94002 11.6661 2.77655 11.6025 2.65177C11.5466 2.54201 11.4577 2.45283 11.348 2.39691C11.2232 2.33333 11.0595 2.33333 10.7328 2.33333H3.26611C2.93942 2.33333 2.77623 2.33333 2.65145 2.39691C2.54169 2.45283 2.45251 2.54201 2.39659 2.65177C2.33301 2.77655 2.33301 2.94002 2.33301 3.26672V3.69679C2.33301 3.83946 2.33301 3.91086 2.34913 3.97799C2.36342 4.03751 2.38704 4.09437 2.41903 4.14656C2.45509 4.20541 2.50562 4.25594 2.60645 4.35677L5.55971 7.31003C5.66059 7.41091 5.71076 7.46108 5.74684 7.51995C5.77882 7.57214 5.80277 7.62919 5.81706 7.68872C5.83301 7.75517 5.83301 7.82571 5.83301 7.9655V10.7397C5.83301 11.2398 5.83301 11.49 5.93832 11.6405C6.03029 11.772 6.17216 11.8597 6.33089 11.8832C6.51267 11.91 6.73642 11.7983 7.18368 11.5747L7.65034 11.3414C7.83763 11.2477 7.93104 11.2007 7.99946 11.1309C8.05996 11.0691 8.10625 10.9949 8.13444 10.9132C8.16632 10.8208 8.16634 10.7158 8.16634 10.5064V7.96984C8.16634 7.82717 8.16634 7.75585 8.18246 7.68872C8.19675 7.62919 8.22038 7.57214 8.25236 7.51995C8.28819 7.46148 8.33823 7.41144 8.43778 7.31189L8.43978 7.31003L11.393 4.35677C11.4939 4.25588 11.5441 4.20543 11.5802 4.14656C11.6122 4.09437 11.6361 4.03751 11.6504 3.97799C11.6663 3.91154 11.6663 3.84092 11.6663 3.70113V3.26672Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Filtrar
          </ion-button>
        </ion-item>

        <!-- FILTERS -->
        ${when(
          this.hasFilters(),
          () => html`
            <div class="flex items-center gap-x-1.5 gap-y-2 flex-wrap mt-2">
              ${repeat(
                this.filterStore.filters,
                (filter) => filter.id,
                (filter) =>
                  when(
                    filter.id !== "all",
                    () => html`
                      <ion-chip
                        color="primary"
                        class="px-2.5 py-1.5 text-sm font-medium"
                        @click=${() => this.filterStore.remove(filter)}>
                        ${filter.name}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          class="ml-1 mb-px">
                          <rect width="12" height="12" rx="6" fill="white" />
                          <path
                            d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </ion-chip>
                    `,
                  ),
              )}
            </div>
          `,
        )}
        <!-- FILTERS -->

        ${when(
          this.hasFilters(),
          () =>
            this.#filterEvaluations.render({
              pending: () => {
                return html`
                  <ion-list class="mt-2.5">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </ion-list>
                `;
              },
              error: (error) => {
                console.log(error);
                return html`<span class="text-danger">Erro ao carregar a avaliação</span>`;
              },
              complete: (templates) => {
                if (templates.length === 0) {
                  return html`
                    <div
                      class="mt-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                          stroke="#111111"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                        Não foram encontrados resultados
                      </span>
                    </div>
                  `;
                }

                return html`
                  <ion-list class="mt-2.5">
                    ${repeat(
                      templates,
                      (template) => template.id,
                      (template) => html`
                        <ion-item
                          button
                          ?disabled=${this._isSubmitting}
                          @click=${() => this.addEvaluation(template)}
                          .detail=${false}
                          style="--min-height: 52px; --max-height: 52px; --inner-padding-top: 10px; --inner-padding-bottom: 10px"
                          class="no-p">
                          <div class="w-full flex flex-col">
                            <div class="flex items-center gap-x-1.5">
                              <p class="font-semibold line-clamp-1">${template.name}</p>
                              <ion-badge color="success" class="font-semibold">Avaliação</ion-badge>
                            </div>
                            <div class="flex items-center space-x-[3px]">
                              ${template.categories.slice(0, 2).map(
                                (category) => html`
                                  <span class="text-sm">${category.category}</span>
                                  <span class="text-accent-6 last:hidden">•</span>
                                `,
                              )}
                            </div>
                          </div>
                        </ion-item>
                      `,
                    )}
                  </ion-list>
                `;
              },
            }),
          () =>
            this.#loadEvaluations.render({
              pending: () => {
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 4 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              },
              error: (error) => {
                console.log(error);
                return html`<span class="text-danger">Erro ao carregar a avaliação</span>`;
              },
              complete: (templates) => {
                return html`
                  ${when(
                    templates.length === 0,
                    () => {
                      return html`
                        <div
                          class="mt-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none">
                            <path
                              d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                              stroke="#111111"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                            Esta biblioteca ainda não tem conteúdo
                          </span>
                        </div>
                      `;
                    },
                    () => {
                      return html`
                        <!-- ------------- CONTENT ------------- -->
                        <ion-list class="mt-2.5">
                          ${repeat(
                            templates,
                            (template) => template.id,
                            (template) => html`
                              <ion-item
                                button
                                ?disabled=${this._isSubmitting}
                                @click=${() => this.addEvaluation(template)}
                                .detail=${false}
                                style="--min-height: 52px; --inner-padding-top: 10px; --inner-padding-bottom: 10px"
                                class="no-p">
                                <div class="w-full flex flex-col">
                                  <div class="flex items-center gap-x-1.5">
                                    <p class="font-semibold line-clamp-1">${template.name}</p>
                                    <ion-badge color="success" class="font-semibold">Avaliação</ion-badge>
                                  </div>
                                  <div class="flex items-center space-x-[3px]">
                                    ${template.categories.slice(0, 2).map(
                                      (category) => html`
                                        <span class="text-sm">${category.category}</span>
                                        <span class="text-accent-6 last:hidden">•</span>
                                      `,
                                    )}
                                  </div>
                                </div>
                              </ion-item>
                            `,
                          )}
                        </ion-list>
                      `;
                    },
                  )}
                `;
              },
            }),
        )}
      </ion-content>

      <modal-nav root="modal-filter-reports" ${ref(this.#modalFilterReports)}></modal-nav>
    `;
  }
}
