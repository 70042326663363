import { toastController } from "@ionic/core";
import type { Page } from "../components/component";
import type { Constructor } from "./constructor";

export const WithInstallPrompt = (Base: Constructor<Page>) => {
  return class extends Base {
    _isInstalledPWA =
      window.matchMedia("(displaymode: window-controls-overlay)") ||
      window.matchMedia("(display-mode: standalone)").matches;

    showToast = async (e: any) => {
      e.preventDefault();
      const toast = await toastController.create({
        message: "Instala a aplicação na tua homepage",
        swipeGesture: "vertical",
        buttons: [
          {
            text: "Instalar",
            handler: () => {
              e.prompt();
            },
          },
        ],
      });
      await toast.present();
    };

    connectedCallback() {
      super.connectedCallback();
      if (!this._isInstalledPWA || !this._isInstalledPWA.matches) {
        window.addEventListener("beforeinstallprompt", this.showToast);
      }
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      window.removeEventListener("beforeinstallprompt", this.showToast);
    }
  };
};
