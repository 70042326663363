import { makeObservable, observable, type IObservableArray, runInAction, action, computed } from "mobx";
import type { User } from "../repository/user/user";
import type {
  CreateTemplateExercisePlan,
  CreateTemplateExercisePlanExercise,
  TemplateExercisePlan,
  TemplateExercisePlanExercise,
  TemplateExercisePlanShare,
  UpdateTemplateExercisePlan,
  UpdateTemplateExercisePlanExercise,
} from "../repository/templates/templates";
import { templatesRepository } from "../repository/templates/templates.repository";
import { ObservableExercise, exercisesStore } from "./exercises.store";
import type { UserAuth } from "./auth.store";
import type { UserShareAction } from "./userShare.store";
import type { PublicExercise } from "../repository/library/exercises";

export class ObservableTemplateExercise {
  id: string;
  template: string;
  @observable private _exercise?: ObservableExercise;
  @observable private _exercisePublic?: PublicExercise;
  @observable series?: number;
  @observable repetitions?: string;
  @observable weight?: number;
  @observable weightUnit?: string;
  @observable rest?: number;
  @observable duration?: number;
  @observable description?: string;

  constructor(data: TemplateExercisePlanExercise) {
    this.id = data.id;
    this.template = data.template;
    if (data.exercise) {
      this._exercise = exercisesStore.subscribe(data.exercise);
    } else if (data.exercisePublic) {
      this._exercisePublic = data.exercisePublic;
    }
    this.series = data.series;
    this.repetitions = data.repetitions;
    this.weight = data.weight;
    this.weightUnit = data.weightUnit;
    this.rest = data.rest;
    this.duration = data.duration;
    this.description = data.description;
    makeObservable(this);
  }

  get exercise() {
    return this._exercise || this._exercisePublic;
  }

  @action merge(data: TemplateExercisePlanExercise) {
    this.template = data.template;
    if (data.exercise) {
      this._exercise = exercisesStore.subscribe(data.exercise);
    } else if (data.exercisePublic) {
      this._exercisePublic = data.exercisePublic;
    }
    this.series = data.series;
    this.repetitions = data.repetitions;
    this.weight = data.weight;
    this.weightUnit = data.weightUnit;
    this.rest = data.rest;
    this.duration = data.duration;
    this.description = data.description;
    return this;
  }
}

export class TemplateExerciseStore {
  @observable exercises = observable.map<string, ObservableTemplateExercise>();

  constructor() {
    makeObservable(this);
  }

  @action save(exercise: TemplateExercisePlanExercise) {
    const observable = new ObservableTemplateExercise(exercise);
    this.exercises.set(exercise.id, observable);
    return observable;
  }

  @action delete(exercise: ObservableTemplateExercise) {
    return this.exercises.delete(exercise.id);
  }

  find(id: string) {
    return this.exercises.get(id);
  }

  exists(exercise: ObservableTemplateExercise) {
    return this.exercises.has(exercise.id);
  }

  @action clearStore() {
    this.exercises.clear();
  }

  @action subscribe(exercise: TemplateExercisePlanExercise) {
    const observable = this.find(exercise.id);
    if (observable) {
      return observable;
    } else {
      return this.save(exercise);
    }
  }
}

export const templateExerciseStore = new TemplateExerciseStore();

export class ObservableTemplateExercisePlan {
  id: string;
  owner: User;
  @observable name: string;
  @observable sharedPersonal: IObservableArray<TemplateExercisePlanShare>;
  @observable exercises: IObservableArray<ObservableTemplateExercise>;
  @observable _totalExercises: number;
  @observable thumbnail?: string;
  @observable isLoaded = false;

  constructor(data: TemplateExercisePlan) {
    this.id = data.id;
    this.name = data.name;
    this.owner = data.owner;
    this.sharedPersonal = observable.array(data.shared);
    this.thumbnail = data.thumbnail;
    this.exercises = observable.array(data.exercises.map((e) => templateExerciseStore.subscribe(e)));

    this._totalExercises = data.totalExercises;
    makeObservable(this);
  }

  get totalExercises() {
    if (!this.isLoaded) {
      return this._totalExercises;
    }
    return this.exercises.length;
  }

  @action merge(data: TemplateExercisePlan) {
    this.name = data.name;
    this.owner = data.owner;
    this.sharedPersonal.replace(data.shared);
    this.thumbnail = data.thumbnail;
    this.exercises.replace(data.exercises.map((e) => templateExerciseStore.subscribe(e)));
    this._totalExercises = data.totalExercises;
    return this;
  }

  isOwner(user: User | UserAuth) {
    return this.owner.id === user.id;
  }

  isShared(user: User | UserAuth) {
    return this.findUser(user) !== undefined && !this.isOwner(user);
  }

  canEdit(user: User | UserAuth) {
    return this.isOwner(user) || this.findUser(user)?.access.role === "editor";
  }

  private findUser(user: User | UserAuth) {
    return this.sharedPersonal.find((s) => s.user.id === user.id);
  }
}

class TemplateExercisePlanStore {
  @observable templates = observable.array<ObservableTemplateExercisePlan>([]);
  @observable isLoaded = false;

  @computed get totalTemplates() {
    return this.templates.length;
  }

  constructor() {
    makeObservable(this);
  }

  find(id: string) {
    return this.templates.find((t) => t.id === id);
  }

  @action delete(template: ObservableTemplateExercisePlan) {
    return this.templates.remove(template);
  }

  clearStore() {
    runInAction(() => {
      this.templates.clear();
    });
  }

  @action async loadTemplates(opts: { cache: boolean } = { cache: true }) {
    if (opts.cache && this.isLoaded) {
      return this.templates;
    }

    const result = await templatesRepository.listTemplates();

    runInAction(() => {
      this.templates.replace(result.map((r) => new ObservableTemplateExercisePlan(r)));
      this.isLoaded = true;
    });

    return this.templates;
  }

  @action async loadTemplate(id: string, opts: { cache: boolean } = { cache: true }) {
    const cachedTemplate = this.find(id);
    if (cachedTemplate?.isLoaded && opts.cache) {
      return cachedTemplate;
    }

    const result = await templatesRepository.getOne(id);

    let template!: ObservableTemplateExercisePlan;

    runInAction(() => {
      template = new ObservableTemplateExercisePlan(result);
      template.isLoaded = true;
      if (cachedTemplate) {
        cachedTemplate.merge(result);
      } else {
        this.templates.push(template);
      }
    });

    if(cachedTemplate) return cachedTemplate;

    return template;
  }

  @action async createTemplate(data: CreateTemplateExercisePlan) {
    const result = await templatesRepository.createTemplate(data);

    let template!: ObservableTemplateExercisePlan;

    runInAction(() => {
      template = new ObservableTemplateExercisePlan(result);
      this.templates.push(template);
    });

    return template;
  }

  @action async updateTemplate(template: ObservableTemplateExercisePlan, data: UpdateTemplateExercisePlan) {
    const result = await templatesRepository.updateTemplate(template.id, data);

    runInAction(() => {
      template.name = result.name;
      template.thumbnail = result.thumbnail;
      template.owner = result.owner;
    });

    return template;
  }

  @action async deleteTemplate(template: ObservableTemplateExercisePlan) {
    const result = await templatesRepository.deleteTemplate(template.id);

    runInAction(() => {
      const cachedTemplate = this.find(template.id);
      // this.templates.remove(template) is not working --> cant figure out why
      if (cachedTemplate) {
        this.templates.remove(cachedTemplate);
      }
    });

    return result;
  }

  @action async shareWithUsers(template: ObservableTemplateExercisePlan, users: UserShareAction[]) {
    await templatesRepository.shareTemplateWithUsers(template.id, users);

    runInAction(() => {
      // Remove deleted items
      const idsToDelete = users.filter((gs) => gs.action === "delete").map((gs) => gs.user.id);
      template.sharedPersonal.replace(template.sharedPersonal.filter((gs) => !idsToDelete.includes(gs.user.id)));

      // Apply updates
      users.forEach((u) => {
        if (u.action === "update") {
          const sp = template.sharedPersonal.find((item) => item.user.id === u.user.id);
          if (sp) {
            sp.access.id = u.access.id;
            sp.access.role = u.access.role;
          }
        }
      });
    });

    return template;
  }

  @action async createExercise(template: ObservableTemplateExercisePlan, data: CreateTemplateExercisePlanExercise) {
    const result = await templatesRepository.createExercise(template.id, data);

    const exercise = templateExerciseStore.save(result);

    runInAction(() => {
      template.exercises.push(exercise);
    });

    return exercise;
  }

  @action async updateExercise(exercise: ObservableTemplateExercise, data: UpdateTemplateExercisePlanExercise) {
    const result = await templatesRepository.updateExercise(exercise.id, data);

    runInAction(() => {
      exercise.merge(result);
    });

    return exercise;
  }

  @action async deleteExercise(exercise: ObservableTemplateExercise) {
    const result = await templatesRepository.deleteExercise(exercise.id);

    runInAction(() => {
      const template = this.find(exercise.template);
      if (template) {
        const cachedExercise = template.exercises.find((e) => e.id === exercise.id);
        if (cachedExercise) {
          template.exercises.remove(cachedExercise);
        }
      }
      templateExerciseStore.delete(exercise);
    });

    return result;
  }
}

export const templatesStore = new TemplateExercisePlanStore();
