import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { alertController, modalController } from "@ionic/core";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { UpdateSessionSchema, type UpdateSession } from "../repository/patient/diagnostic";
import { ClientResponseError } from "pocketbase";
import { Page } from "../components/component";
import { format, formatISO } from "date-fns";
import { pt } from "date-fns/locale";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalSheetNav } from "./modal-sheet-nav";
import { sessionsStore, type ObservableSession } from "../stores/sessions.store";

const formSchema = UpdateSessionSchema.omit({
  diagnostic: true,
});
type FormValues = z.infer<typeof formSchema>;

@customElement("modal-edit-session")
export class ModalEditSession extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  @state() private _day: string = "";
  @state() private _startTime: string = "";
  @state() private _endTime: string = "";

  #modalTimePickerRef: Ref<ModalSheetNav> = createRef();
  #modalDatePickerRef: Ref<ModalSheetNav> = createRef();

  session!: ObservableSession;

  connectedCallback() {
    super.connectedCallback();

    if (!this.session) {
      throw new Error("<modal-edit-session> requires a session prop");
    }

    this._day = formatISO(this.session.day);
    this._startTime = formatISO(this.session.startTime);
    this._endTime = formatISO(this.session.endTime);
  }

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      if (!this.auth.user) return;

      const year = Number(format(this._day!, "yyyy"));
      const month = Number(format(this._day!, "MM"));
      const date = Number(format(this._day!, "dd"));
      const startHour = Number(format(this._startTime!, "HH"));
      const startMinutes = Number(format(this._startTime!, "mm"));
      const endHour = Number(format(this._endTime!, "HH"));
      const endMinutes = Number(format(this._endTime!, "mm"));

      if (startHour > endHour) {
        this.#controller.setError("startTime", "A hora de início não pode ser maior que a hora de fim");
        return;
      } else if (startHour === endHour && startMinutes > endMinutes) {
        this.#controller.setError("startTime", "A hora de início não pode ser maior que a hora de fim");
        return;
      } else if (startHour === endHour && startMinutes === endMinutes) {
        this.#controller.setError("startTime", "A hora de início não pode ser igual à hora de fim");
        return;
      }

      const isoStart = formatISO(new Date(year, month - 1, date, startHour, startMinutes));
      const isoEnd = formatISO(new Date(year, month - 1, date, endHour, endMinutes));

      try {
        const data: UpdateSession = {
          ...values,
          startTime: isoStart,
          endTime: isoEnd,
        };
        await sessionsStore.updateSession(this.session, data);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ClientResponseError) {
          if (error.status === 403) {
            const alert = await alertController.create({
              header: "Erro",
              message: "Não tem permissões suficientes para editar a sessão.",
              buttons: ["Ok"],
            });
            await alert.present();
          } else {
            const alert = await alertController.create({
              header: "Erro",
              message: "Ocorreu um erro ao editar a sessão. Por favor, tente novamente mais tarde.",
              buttons: ["OK"],
            });
            alert.present();
          }
        }
      }
    },
  });

  getTime(date: string) {
    if (!date) return;
    const time = format(date, "HH:mm", { locale: pt });
    return time;
  }

  getDay(date: string) {
    if (!date) return;
    const time = format(date, "dd-MM-yyyy", { locale: pt });
    return time;
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content class="space-y-4" fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => modalController.dismiss()}">
                  Cancelar
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">Editar Sessão</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form
          id="form-session-edit"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none" class="space-y-4">
            <div>
              <span class="font-semibold font-display">Nome</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder="Nome da sessão"
                  ${register("name", { initialValue: this.session.name })}>
                </ion-input>
              </ion-item>
              ${renderError("name")}
            </div>
            <div>
              <span class="font-semibold font-display">Dia da sessão</span>
              <ion-item class="mt-1">
                <ion-input
                  readonly
                  placeholder="01-01-2000"
                  ${register("day", { initialValue: this.getDay(this.session.day) })}
                  @click=${() =>
                    this.#modalDatePickerRef.value?.open({
                      date: this._day,
                      setDate: (date: string) => {
                        this._day = date;
                        form.change("day", this.getDay(this._day));
                      },
                    })}>
                </ion-input>
              </ion-item>
              ${renderError("day")}
            </div>
            <div class="flex items-center space-x-4 w-full">
              <div class="w-full">
                <span class="font-semibold font-display">Início</span>
                <ion-item class="mt-1">
                  <ion-input
                    readonly
                    class="w-full"
                    placeholder="00:00"
                    ${register("startTime", { initialValue: this.getTime(this.session.startTime) })}
                    @click=${() => {
                      this.#modalTimePickerRef.value?.open({
                        date: this._startTime,
                        setDate: (date: string) => {
                          this._startTime = date;
                          form.change("startTime", this.getTime(this._startTime));
                          form.mutators.setFieldData?.("startTime", { error: "" });
                        },
                      });
                    }}>
                  </ion-input>
                </ion-item>
              </div>
              <div class="w-full">
                <span class="font-semibold font-display">Fim</span>
                <ion-item class="mt-1">
                  <ion-input
                    readonly
                    class="w-full"
                    placeholder="00:00"
                    ${register("endTime", { initialValue: this.getTime(this.session.endTime) })}
                    @click=${() =>
                      this.#modalTimePickerRef.value?.open({
                        date: this._endTime,
                        setDate: (date: string) => {
                          this._endTime = date;
                          form.change("endTime", this.getTime(this._endTime));
                          form.mutators.setFieldData?.("endTime", { error: "" });
                        },
                      })}>
                  </ion-input>
                </ion-item>
              </div>
            </div>
            ${renderError("startTime")} ${renderError("endTime")}
          </ion-list>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-session-edit"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Guardar Alterações</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-sheet-nav
        auto-height
        root="modal-time-picker"
        border-radius=${0}
        .handle=${false}
        ${ref(this.#modalTimePickerRef)}></modal-sheet-nav>

      <modal-sheet-nav
        auto-height
        root="modal-date-picker"
        border-radius=${0}
        .handle=${false}
        ${ref(this.#modalDatePickerRef)}></modal-sheet-nav>
    `;
  }
}
