import { consume } from "@lit/context";
import { html } from "lit";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { Page, required } from "../components/component";
import { UserSchema } from "../repository/user/user";
import type { UserShare, UserShareStore } from "../stores/userShare.store";
import { customElement } from "../element";
import { AccessRoleSchema, createAccessFromRole } from "../repository/group/access";

const formSchema = z.object({
  user: UserSchema,
  access: AccessRoleSchema,
});

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-share-edit")
export class ModalShareEdit extends Page {
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  @required({ type: Object }) share!: UserShare;
  @required({ type: Object }) shareStore!: UserShareStore;

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      this.shareStore.update({
        user: values.user,
        access: createAccessFromRole(values.access),
      });
      this.navigator.goBack();
    },
  });

  render() {
    const { form, register, renderError, submit } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content fullscreen>
        <!-- HEADER -->
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  @click=${() => this.navigator.goBack()}
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  voltar
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">Editar Profissional</ion-title>
            <ion-buttons slot="end">
              <ion-button
                @click=${() => {
                  this.shareStore.remove(this.share);
                  this.navigator.goBack();
                }}
                color="danger"
                class="font-semibold text-sm">
                <span class="mr-1 mt-px">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                Remover
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form class="mt-4" id="form-group-add-profissional" @submit=${submit}>
          <div>
            <span class="font-semibold font-display">Utilizador</span>
            <ion-item class="no-p mt-1 no-p no-inline-p no-inner-p" lines="none">
              <div
                class="flex items-center space-x-2"
                ${register("user", {
                  initialValue: this.share.user,
                })}>
                <xt-avatar src=${this.share?.user.avatar} name=${this.share?.user.name}></xt-avatar>
                <div class="col items-start">
                  <p class="font-semibold">${this.share?.user.name}</p>
                  <span class="text-accent-5">@${this.share?.user.username}</span>
                </div>
              </div>
              ${renderError("user")}
            </ion-item>
          </div>

          <div class="space-y-1.5 mt-6">
            <span class="font-semibold font-display">Acesso</span>
            <ion-list>
              <ion-radio-group
                value="editor"
                ${register("access", {
                  initialValue: this.share.access.role,
                })}>
                <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                  <ion-radio value="editor" class="no-m-inline my-2">Editor</ion-radio>
                </ion-item>
                <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                  <ion-radio value="viewer" class="no-m-inline my-2">Visualizador</ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            ${renderError("access")}
          </div>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-group-add-profissional"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >Guardar Alterações</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
