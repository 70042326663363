import { z } from "zod";
import { UserSchema } from "../user/user";
import { PatientSchema } from "../patient/patient";
import { SectionSchema } from "./section";
import { record } from "../shared/record";
import { unsafeParse } from "../shared/unsafeParse";
import { AccessSchema } from "./access";

export const GroupSharedRecordSchema = record({
  id: z.string(),
  user: z.string(),
  group: z.string(),
  access: z.string(),
});
export interface GroupSharedRecord extends z.infer<typeof GroupSharedRecordSchema> {}

export const GroupSharedSchema = GroupSharedRecordSchema.extend({
  user: UserSchema,
  access: AccessSchema,
}).omit({ expand: true });
export interface GroupShared extends z.infer<typeof GroupSharedSchema> {}
export function createGroupShared(data: unknown) {
  return unsafeParse(GroupSharedSchema, data);
}

/*
 * ======================================
 * Group
 * ======================================
 */

export const GroupRecordSchema = record({
  id: z.string(),
  name: z.string(),
  owner: z.string(),
  isArchived: z.boolean(),
});
export interface GroupRecord extends z.infer<typeof GroupRecordSchema> {}

export const GroupSchema = GroupRecordSchema.extend({
  owner: UserSchema,
  shared: z.array(GroupSharedSchema).default([]),
  sections: z.array(SectionSchema).default([]),
}).omit({ expand: true });
export interface Group extends z.infer<typeof GroupSchema> {}
export function createGroup(data: unknown) {
  return unsafeParse(GroupSchema, data);
}

export const GroupSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
  isArchived: z.boolean(),
  owner: UserSchema,
  shared: z.array(GroupSharedSchema).default([]),
  sections: z.array(SectionSchema).default([]),
  popularPatients: z.array(PatientSchema).default([]),
  archivedPatients: z.number(),
  totalPatients: z.number(),
});
export interface GroupSummary extends z.infer<typeof GroupSummarySchema> {}
export function createGroupSummary(data: unknown) {
  return unsafeParse(GroupSummarySchema, data);
}

export const CreateGroupSchema = z.object({
  name: z
    .string({
      required_error: "O nome é obrigatório",
    })
    .min(3, "O nome deve ter pelo menos 3 caracteres")
    .max(50, "O nome deve ter no máximo 50 caracteres"),
  owner: z.string(),
});
export interface CreateGroup extends z.infer<typeof CreateGroupSchema> {}

export const UpdateGroupSchema = z.object({
  name: z.string().min(1).max(255).optional(),
  isArchived: z.boolean().optional(),
});
export interface UpdateGroup extends z.infer<typeof UpdateGroupSchema> {}
