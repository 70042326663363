import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import { consume } from "@lit/context";
import { when } from "lit/directives/when.js";
import { Task } from "@lit/task";
import { authContext, type AuthContext } from "../context/auth.context";
import { groupStore, type ObservableGroup } from "../stores/groups.store";
import { alertController } from "@ionic/core";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { ObservablePatient, patientsStore } from "../stores/patients.store";
import { PaginationStore } from "../stores/pagination.store";
import { sleep } from "../pocketbase";

@customElement("page-groups")
export class PageGroups extends Page {
  @state() private _query: string = "";

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;

  #groups = new Task(this, {
    task: async () => {
      return groupStore.loadGroups();
    },
    args: () => [],
  });

  #modalCreateGroupRef: Ref<ModalNav> = createRef();
  #modalEditGroupRef: Ref<ModalNav> = createRef();

  #ionRefresher: Ref<IonRefresher> = createRef();

  @state() patientsFiltered: ObservablePatient[] = [];
  filterPagination = new PaginationStore(1, 20, 0);

  #filterPatients = new Task(this, {
    task: async ([query]) => {
      const groups = this.#groups.value;
      if (!groups) return null;

      // reset pagination
      this.filterPagination.reset();

      return await patientsStore.loadPatients(groups, this.filterPagination, {
        query,
        type: { id: "active", name: "Ativo" },
      });
    },
    onComplete: (patients) => {
      if (patients) {
        this.patientsFiltered = patients.items;
        this.filterPagination.setTotal(patients.totalItems);
      }
    },
    args: () => [this._query] as const,
  });

  async openEditModal(group: ObservableGroup) {
    await this.#modalEditGroupRef.value?.open({
      group,
    });
  }

  async #arquiveGroup(group: ObservableGroup) {
    const alert = await alertController.create({
      header: "Arquivar Grupo",
      message: "Tem a certeza que deseja arquivar este grupo?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Arquivar",
          role: "destructive",
          handler: async () => {
            return groupStore.updateGroup(group, { isArchived: true });
          },
        },
      ],
    });

    await alert.present();
  }

  async showExitGroupAlert(group: ObservableGroup) {
    const alert = await alertController.create({
      header: "Sair do grupo",
      message: "Tem a certeza que pretende sair deste grupo?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
        },
        {
          text: "Sair",
          role: "destructive",
          handler: async () => {
            return groupStore.leaveGroup(group, this.auth.user);
          },
        },
      ],
    });

    await alert.present();
  }

  showPopoverByRole(group: ObservableGroup) {
    const role = group.sharedPersonal?.find((access) => access.user.id === this.auth.user.id)?.access.role;
    if (!role) {
      return html`
        <ion-list>
          <ion-item @click=${() => this.openEditModal(group)} class="no-p no-detail text-sm" button>
            <ion-label class="font-semibold pl-3">Editar</ion-label>
          </ion-item>
          <ion-item button lines="none" class="no-p no-detail text-sm" @click="${() => this.#arquiveGroup(group)}">
            <ion-label class="font-semibold pl-3">Arquivar</ion-label>
          </ion-item>
        </ion-list>
      `;
    } else {
      return html`
        <ion-list>
          <ion-item @click=${() => this.openEditModal(group)} class="no-p no-detail text-sm" button>
            <ion-label class="font-semibold pl-3">Consultar</ion-label>
          </ion-item>
          <ion-item @click=${() => this.showExitGroupAlert(group)} class="no-p no-detail text-sm" button>
            <ion-label class="font-semibold pl-3">Sair</ion-label>
          </ion-item>
        </ion-list>
      `;
    }
  }

  render() {
    return html`
      <ion-content fullscreen style="--padding-bottom: 24px">
        <!-- REFRESHER -->
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            await groupStore.loadGroups({ cache: false });
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text="Puxe para atualizar"></ion-refresher-content>
        </ion-refresher>

        <!-- HEADER -->
        <div class="w-full flex justify-between mt-4 h-10 items-center">
          <h3>Grupos</h3>
          <div>
            ${this.#groups.render({
              pending: () => html`
                <ion-skeleton-text animated="true" style="width: 112px; height: 32px;"></ion-skeleton-text>
              `,
              complete: () => html`
                <ion-router-link href="/groups/archived" router-direction="forward">
                  <ion-button
                    style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                    color="secondary"
                    shape="round"
                    size="xsmall">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      class="mr-1">
                      <path
                        d="M4.40023 7.5H11.6002M4.40023 7.5C4.02686 7.5 3.83971 7.5 3.69711 7.59083C3.57166 7.67072 3.46975 7.79811 3.40584 7.95492C3.33317 8.13318 3.33317 8.3667 3.33317 8.83341V13.1667C3.33317 14.1002 3.33317 14.567 3.4785 14.9235C3.60633 15.2371 3.81015 15.4921 4.06104 15.6519C4.34597 15.8333 4.71917 15.8333 5.46445 15.8333H10.535C11.2803 15.8333 11.6529 15.8333 11.9379 15.6519C12.1887 15.4921 12.3935 15.237 12.5213 14.9234C12.6665 14.5673 12.6665 14.1014 12.6665 13.1698V8.82585C12.6665 8.36418 12.6665 8.13221 12.5942 7.95492C12.5303 7.79811 12.4279 7.67072 12.3025 7.59083C12.1599 7.5 11.9736 7.5 11.6002 7.5M4.40023 7.5H3.31671C2.75065 7.5 2.46778 7.5 2.30713 7.3763C2.09274 7.21123 1.97118 6.909 1.99268 6.59539C2.0088 6.36019 2.17729 6.07574 2.51481 5.50616C2.61244 5.34142 2.66126 5.25903 2.72102 5.19613C2.80069 5.11225 2.89524 5.05268 2.99658 5.02258C3.07259 5 3.15418 5 3.3182 5H12.6811C12.8451 5 12.9269 5 13.0029 5.02258C13.1043 5.05268 13.1988 5.11225 13.2785 5.19613C13.3382 5.25903 13.3872 5.34104 13.4849 5.50578C13.8224 6.07535 13.9912 6.36015 14.0073 6.59535C14.0288 6.90895 13.9068 7.21123 13.6924 7.3763C13.5317 7.5 13.2482 7.5 12.6821 7.5H11.6002M6.66651 11.6667H9.33317"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>

                    Arquivo (${groupStore.archivedGroups.length})
                  </ion-button>
                </ion-router-link>
              `,
            })}
          </div>
        </div>
        <!-- HEADER -->

        <!-- SEARCH BAR -->
        <div class="mt-2.5">
          ${this.#groups.render({
            pending: () => html`
              <ion-skeleton-text animated="true" style="width: 100%; height: 48px;"></ion-skeleton-text>
            `,
            complete: () => html`
              <ion-item>
                <span aria-hidden="true" slot="start" class="flex items-center mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                <ion-input
                  id="page-groups-search"
                  type="search"
                  placeholder="Pesquisar utentes"
                  debounce="150"
                  @ionInput=${(e: Event) => {
                    const query = (e.target as HTMLInputElement).value;
                    this._query = query;
                  }}
                  clear-input
                  clear-on-edit></ion-input>
              </ion-item>
            `,
          })}
        </div>
        <!-- SEARCH BAR -->

        <!-- CONTENT -->
        ${when(
          this._query.length > 0,
          () =>
            this.#filterPatients.render({
              pending: () => {
                return html`
                  <div class="space-y-2.5 mt-4">
                    ${Array.from({ length: 3 }).map(() => {
                      return html`
                        <div class="flex space-x-2">
                          <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                          <div class="flex-1">
                            <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                            <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                          </div>
                        </div>
                      `;
                    })}
                  </div>
                `;
              },
              error: (err) => {
                console.log(err);
                return html`<span class="text-danger">Erro ao procurar pacientes</span>`;
              },
              complete: () =>
                when(
                  this.patientsFiltered.length === 0,
                  () => html`
                    <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                      <div
                        class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                        <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                          Não foram encontrados resultados
                        </span>
                      </div>
                    </ion-item>
                  `,
                  () => html`
                    <ion-list class="space-y-2 no-border mt-4">
                      ${this.patientsFiltered.map((patient) => {
                        return html`
                          <ion-item
                            button
                            .detail=${false}
                            @click=${() => {
                              if (patient.isArchived) {
                                this.router.push(
                                  `groups/${patient.group}/popular/patient-archived/${patient.id}`,
                                  "forward",
                                );
                              } else {
                                this.router.push(`groups/${patient.group}/popular/patient/${patient.id}`, "forward");
                              }
                            }}
                            class="flex space-x-2 items-center no-px no-border">
                            <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                            <div class="col w-full">
                              <p class="font-semibold">${patient.name}</p>
                              <div class="flex items-center space-x-1.5">
                                ${patient.categories
                                  .slice(0, 3)
                                  .map(
                                    (category) => html`
                                      <ion-chip
                                        class="flex-shrink-0 px-2 w-fit"
                                        style="--background: ${category.color};"
                                        >${category.category}</ion-chip
                                      >
                                    `,
                                  )}
                              </div>
                            </div>
                          </ion-item>
                        `;
                      })}
                    </ion-list>
                    ${when(
                      this.filterPagination.hasNext,
                      () => html`
                        <ion-infinite-scroll
                          threshold="0px"
                          @ionInfinite=${async (e: any) => {
                            const groups = this.#groups.value;
                            if (!groups) return;

                            await sleep(500);
                            this.filterPagination.nextPage();

                            const patients = await patientsStore.loadPatients(groups, this.filterPagination, {
                              query: this._query,
                            });
                            this.patientsFiltered = this.patientsFiltered.concat(patients.items);
                            e.target.complete();
                          }}>
                          <ion-infinite-scroll-content
                            loading-text="A carregar mais pacientes"></ion-infinite-scroll-content>
                        </ion-infinite-scroll>
                      `,
                    )}
                  `,
                ),
            }),
          () => html`
            <div class="mt-4">
              ${this.#groups.render({
                pending: () => html`
                  <ion-list class="space-y-5">
                    <div class="space-y-2">
                      <ion-skeleton-text animated="true" style="width: 168px; height: 28px;"></ion-skeleton-text>
                      <div class="flex">
                        <ion-skeleton-text
                          class="mr-2"
                          animated="true"
                          style="width: 52px; height: 52px;"></ion-skeleton-text>
                        <div class="space-y-1">
                          <ion-skeleton-text animated="true" style="width: 220px; height: 24px;"></ion-skeleton-text>
                          <ion-skeleton-text animated="true" style="width: 64px; height: 24px;"></ion-skeleton-text>
                        </div>
                      </div>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 40px;"></ion-skeleton-text>
                    </div>
                    <div class="space-y-2">
                      <ion-skeleton-text animated="true" style="width: 168px; height: 28px;"></ion-skeleton-text>
                      <div class="flex">
                        <ion-skeleton-text
                          class="mr-2"
                          animated="true"
                          style="width: 52px; height: 52px;"></ion-skeleton-text>
                        <div class="space-y-1">
                          <ion-skeleton-text animated="true" style="width: 220px; height: 24px;"></ion-skeleton-text>
                          <ion-skeleton-text animated="true" style="width: 64px; height: 24px;"></ion-skeleton-text>
                        </div>
                      </div>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 40px;"></ion-skeleton-text>
                    </div>
                  </ion-list>
                `,
                error: (error) => {
                  console.log(error);
                  return html`<span class="text-danger">Erro ao procurar grupos</span>`;
                },
                complete: () => {
                  const groups = groupStore.activeGroups;
                  return when(
                    groups.length === 0,
                    () => html`
                      <ion-button
                        color="secondary"
                        class="font-display font-semibold h-36 w-full whitespace-normal"
                        style="--border-radius: 6px;"
                        @click=${() => this.#modalCreateGroupRef.value?.open()}>
                        <span class="col space-y-1 items-center justify-center">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M6 12H12M12 12H18M12 12V18M12 12V6"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                          </span>
                          <span class="w-3/4">Cria o teu primeiro grupo</span>
                        </span>
                      </ion-button>
                    `,
                    () => html`
                      <div class="space-y-4">
                        ${groups.map(
                          (group, index) => html`
                            <div class="space-y-2">
                              <div class="flex justify-between items-start">
                                <div class="flex items-start space-x-2">
                                  ${group.isShared(this.auth.user)
                                    ? html`
                                        <div class="w-6 h-6 bg-accent-7 rounded-full flex items-center justify-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none">
                                            <g clip-path="url(#clip0_1218_3360)">
                                              <path
                                                d="M5.83301 7.58334C6.08352 7.91825 6.40313 8.19536 6.77016 8.39589C7.13719 8.59641 7.54305 8.71566 7.96022 8.74553C8.37739 8.77541 8.7961 8.71522 9.18796 8.56904C9.57982 8.42287 9.93566 8.19413 10.2313 7.89834L11.9813 6.14834C12.5126 5.59825 12.8066 4.86149 12.8 4.09675C12.7933 3.33201 12.4866 2.60047 11.9458 2.0597C11.405 1.51893 10.6735 1.21218 9.90876 1.20554C9.14402 1.19889 8.40726 1.49288 7.85717 2.02417L6.85384 3.02167"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                              <path
                                                d="M8.1663 6.41667C7.91578 6.08176 7.59617 5.80465 7.22914 5.60412C6.86211 5.4036 6.45625 5.28435 6.03908 5.25448C5.62192 5.2246 5.2032 5.28479 4.81134 5.43096C4.41948 5.57714 4.06365 5.80588 3.76796 6.10167L2.01796 7.85167C1.48667 8.40176 1.19268 9.13852 1.19933 9.90326C1.20597 10.668 1.51272 11.3995 2.05349 11.9403C2.59426 12.4811 3.3258 12.7878 4.09054 12.7945C4.85528 12.8011 5.59204 12.5071 6.14213 11.9758L7.13963 10.9783"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1218_3360">
                                                <rect width="14" height="14" fill="white" />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      `
                                    : nothing}
                                  <h6>${group.name}</h6>
                                  <ion-chip class="shrink-0 mt-0.5" style="--background: #EAEAEA; --color: #888888"
                                    >${group.totalPatients} utentes</ion-chip
                                  >
                                </div>
                                <ion-button id="btn-popover-${index}" fill="clear" size="chip">
                                  <ion-icon slot="icon-only" icon="/assets/icons/dots.svg"></ion-icon>
                                </ion-button>
                                <ion-popover
                                  id="group-popover-${index}"
                                  arrow
                                  alignment="center"
                                  dismiss-on-select
                                  reference="trigger"
                                  side="bottom"
                                  style="--max-width: 140px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                                  trigger="btn-popover-${index}">
                                  <div class="p-0">${this.showPopoverByRole(group)}</div>
                                </ion-popover>
                              </div>
                              <ion-list lines="none" class="space-y-2.5 flex flex-col">
                                ${group.popularPatients?.map((patient) =>
                                  when(
                                    !patient.isArchived,
                                    () => html`
                                      <ion-item
                                        @click=${() =>
                                          this.router.push(
                                            `groups/${group.id}/popular/patient/${patient.id}`,
                                            "forward",
                                          )}
                                        button
                                        .detail=${false}
                                        class="no-p flex space-x-2 items-center">
                                        <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                                        <div class="col">
                                          <p class="font-semibold">${patient.name}</p>
                                          <div class="flex items-center space-x-1.5">
                                            ${patient?.categories?.map(
                                              (category) => html`
                                                <ion-chip class="px-2 w-fit" style="--background: ${category.color};"
                                                  >${category?.category}</ion-chip
                                                >
                                              `,
                                            )}
                                          </div>
                                        </div>
                                      </ion-item>
                                    `,
                                  ),
                                )}
                              </ion-list>
                              <div>
                                <ion-router-link href="/groups/${group.id}" router-direction="forward">
                                  <ion-button color="secondary" expand="block" shape="round" size="small">
                                    Entrar
                                  </ion-button>
                                </ion-router-link>
                              </div>
                            </div>
                            <div class="h-px w-full bg-accent-7 last:hidden"></div>
                          `,
                        )}
                      </div>
                    `,
                  );
                },
              })}

              <!-- GROUPS -->
            </div>

            <!-- FAB -->

            <ion-fab
              slot="fixed"
              vertical="bottom"
              horizontal="end"
              @click=${() => this.#modalCreateGroupRef.value?.open()}>
              <ion-fab-button id="open-modal-create-group" label="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6 12H12M12 12H18M12 12V18M12 12V6"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </ion-fab-button>
            </ion-fab>
            <!-- FAB -->
          `,
        )}
      </ion-content>

      <modal-nav root="modal-create-group" ${ref(this.#modalCreateGroupRef)}></modal-nav>
      <modal-nav root="modal-edit-group" ${ref(this.#modalEditGroupRef)}></modal-nav>
    `;
  }
}
